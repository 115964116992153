import { baseURL } from "./BaseURL";
import axios from "axios"; // Import axios for making HTTP requests
 
// Define config outside functions so it can be used throughout the file
// const config = {
//   headers: {
//     "Content-Type": "application/json",
//   },
// };
 
const API = axios.create({ baseURL: baseURL }); // Use imported baseURL instead of undefined API_URL
 
 
 
 
export const getIncidentPendingRecords = async (CompanyId, page, size, search) => {
    try {
      const tokenData = sessionStorage.getItem("AuthToken");
      const token = tokenData ? JSON.parse(tokenData).token : null;
 
      console.log("API Call Parameters:", { CompanyId, page, size, search }); // Log parameters
 
      if (!token) {
        console.error("No Auth Token found. Please log in.");
        window.location.href = '/login'; // Redirect to login page
        return;
      }
 
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
 
      const response = await API.get(`/IncidentReporting/get-incident-pending-records-level-1/`, {
        ...config,
        params: { CompanyId, page, size, search }, // Pass CompanyId, page, size, and search as query parameters
      });
 
      console.log("Fetched Incident Records:", response.data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error fetching incident records:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
 
 
 
 
 
 
 
 
  export const getSupervisorIncident = async (CompanyId, page, size, search) => {
    try {
      const authTokenData = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authTokenData ? authTokenData.token : null;
      if (!token) {
        throw new Error("No auth token found.");
      }
     
      // If search is active, ignore pagination parameters
      const params = search && search.trim() !== ''
        ? { CompanyId, search }
        : { CompanyId, page, size };
     
      const response = await API.get(
        "/IncidentReporting/get-incident-pending-records-level-1/",
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: params,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error fetching incident records:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
 
  export const createIncident = async (incidentData) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await API.post(
        "/IncidentReporting/create-incident-report-level-1/",
        incidentData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`  // or use `Token ${token}` if that's what your API expects
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error(error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const getLiveDetails = async (dateString) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await API.get("/IncidentReporting/get-live-schedule-details-list/", {
        params: { SchedulingDate: dateString },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error(error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const updateIncident = async (incidentData) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await API.patch(
        "/IncidentReporting/update-incident-report-level-1/",
        incidentData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error updating incident:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
 
  //records
 
  export const getApprovedRecords = async (page, size, caseIdSearch, startDate, endDate) => {
    try {
      const authTokenData = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authTokenData ? authTokenData.token : null;
      if (!token) {
        throw new Error("No auth token found.");
      }
 
      // If search is active, ignore pagination parameters
      const params = caseIdSearch && caseIdSearch.trim() !== ''
        ? { search: caseIdSearch }
        : { page, size };
 
      // Format the URL with the date range parameters
      const formattedStartDate = startDate || '2025-01-01'; // Default if not provided
      const formattedEndDate = endDate || '2025-02-28'; // Default if not provided
     
      const response = await API.get(
        `/IncidentReporting/get-approved-records/${formattedStartDate}/${formattedEndDate}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: params, // Use the dynamic params object
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error(error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const createActionUndertaken = async (actionData) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await API.patch(
        "/IncidentReporting/create-action-undertaken/",
        actionData,
        {
          headers: {
            // Let axios set the correct "Content-Type" with boundary automatically
            Authorization: `Bearer ${token}`
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error creating action undertaken:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const fetchLiveScheduleDetails = async (incidentDate) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await API.get("/IncidentReporting/get-live-schedule-details-list/", {
        params: { SchedulingDate: incidentDate },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error(error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const fetchCompany = async () => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
      const response = await API.get('/auth/company-list/', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data.data; // Return the employee types data
    } catch (error) {
      throw error.response || new Error("Network error"); // Throw an error if the request fails
    }
  };
 
  export const approveIncident = async (IncidentReportingId) => {
    try {
      const authTokenData = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authTokenData ? authTokenData.token : null;
      if (!token) {
        throw new Error("No auth token found.");
      }
     
      const response = await API.patch(
        "/IncidentReporting/approve/",
        { IncidentReportingId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error approving incident:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
 
  export const extendIncidentTime = async (IncidentReportingId) => {
    try {
      const authTokenData = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authTokenData ? authTokenData.token : null;
      if (!token) {
        throw new Error("No auth token found.");
      }
     
      const response = await API.patch(
        "/IncidentReporting/extend-time/",
        { IncidentReportingId },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error extending time for incident:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const getFirstPartyIncidentDetails = async (incidentId) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
     
      const response = await API.get(
        `/IncidentReporting/get-first-party-incident-pending-records/`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { IncidentReportingId: incidentId }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error fetching first party details:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const updateFirstPartyIncidentDetails = async (formData) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      const token = authToken ? authToken.token : null;
      if (!token) {
        throw new Error("Authentication token not found");
      }
     
      // Add console log to see what data we're sending
      console.log("Updating first party details with data:", formData);
     
      // Check the API endpoint - it might need correction
      const response = await API.patch(
        `/IncidentReporting/update-first-party-details/`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        // Clear session and redirect to login
        sessionStorage.removeItem("AuthToken");
        window.location.href = '/login';
        return;
      }
      console.error("Error updating first party details:", error.response?.data || error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
 
  export const getThirdPartyIncidentDetails = async (incidentId) => {
    try {
      const authToken = sessionStorage.getItem("AuthToken");
      const parsedToken = JSON.parse(authToken);
      const token = parsedToken.token;
 
      const response = await axios.get(
        `${baseURL}/IncidentReporting/get-third-party-incident-pending-records/?IncidentReportingId=${incidentId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
 
      return response.data;
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        sessionStorage.clear();
        window.location.href = '/login';
      }
      throw error;
    }
  };
 
  export const updateThirdPartyIncidentDetails = async (formData) => {
    try {
      const authToken = sessionStorage.getItem("AuthToken");
      const parsedToken = JSON.parse(authToken);
      const token = parsedToken.token;
 
      const response = await axios.patch(
        `${baseURL}/IncidentReporting/update-third-party-details/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
 
      return response.data;
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        sessionStorage.clear();
        window.location.href = '/login';
      }
      throw error;
    }
  };
 
  export const createThirdPartyIncidentDetails = async (formData) => {
    try {
      const authToken = sessionStorage.getItem("AuthToken");
      const parsedToken = JSON.parse(authToken);
      const token = parsedToken.token;
 
      const response = await axios.post(
        `${baseURL}/IncidentReporting/create-third-party-details/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
 
      return response.data;
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        sessionStorage.clear();
        window.location.href = '/login';
      }
      throw error;
    }
  };
 
  export const getRootCauseDetails = async (incidentId) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      if (!authToken) {
        throw new Error("Authentication token not found");
      }
 
      const response = await axios.get(
        `${baseURL}/IncidentReporting/get-root-cause-pending-records/?IncidentReportingId=${incidentId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken.token}`,
            'Content-Type': 'application/json',
          },
        }
      );
 
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        sessionStorage.clear();
        window.location.href = '/login';
      }
      throw error;
    }
  };
 
  export const updateRootCauseDetails = async (formData) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      if (!authToken) {
        throw new Error("Authentication token not found");
      }
 
      const response = await axios.patch(
        `${baseURL}/IncidentReporting/update-root-cause/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
 
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        sessionStorage.clear();
        window.location.href = '/login';
      }
      throw error;
    }
  };
 
  export const createRootCauseDetails = async (formData) => {
    try {
      const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
      if (!authToken) {
        throw new Error("Authentication token not found");
      }
 
      const response = await axios.post(
        `${baseURL}/IncidentReporting/create-root-cause/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authToken.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
 
      return response.data;
    } catch (error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        sessionStorage.clear();
        window.location.href = '/login';
      }
      throw error;
    }
  };
 