import React, { useContext, useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    IconButton,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    CircularProgress,
    DialogTitle,
    Select,
    MenuItem,
    Paper,
    Menu,
    Card,
    CardContent,
    FormControl,
    InputLabel
} from '@mui/material';
import { Close as CloseIcon, ExpandMore as ExpandMoreIcon, Visibility as VisibilityIcon, GetApp as GetAppIcon } from '@mui/icons-material';
import { ThemeContext } from '../../../store/ThemeContext';
import { getFirstPartyDetails, getThirdPartyDetails, getRootCauseDetails, createActionUndertaken } from '../../../API/IncidentAPI';
import { toast } from 'react-toastify';
import { CameraAlt, LocationOn } from '@mui/icons-material';
import { baseURL } from '../../../API/BaseURL';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

// Add this near the top of your file, inside the component
const themeColors = {
    dark: {
        background: {
            primary: '#1E1E1E',
            secondary: '#121212',
            tertiary: '#2A2A2A',
            input: '#363636'
        },
        text: {
            primary: 'white',
            secondary: 'rgba(255,255,255,0.7)',
            muted: 'rgba(255,255,255,0.5)'
        },
        border: 'rgba(255,255,255,0.1)',
        button: {
            primary: '#4a6da7',
            primaryHover: '#5d84c3',
            secondary: '#3a3a3a',
            secondaryHover: '#505050'
        },
        header: '#000'
    },
    light: {
        background: {
            primary: '#fff',
            secondary: '#f5f5f5',
            tertiary: '#F5F5F5',
            input: '#f5f7fa'
        },
        text: {
            primary: '#333',
            secondary: '#666',
            muted: 'rgba(0,0,0,0.5)'
        },
        border: 'rgba(0,0,0,0.1)',
        button: {
            primary: '#3b536f',
            primaryHover: '#1565c0',
            secondary: '#e0e0e0',
            secondaryHover: '#d5d5d5'
        },
        header: '#3b536f'
    }
};

// Common styles for section headers
const sectionHeaderStyle = (isDarkMode) => ({
    bgcolor: themeColors[isDarkMode ? 'dark' : 'light'].header,
    color: 'white',
    p: 2,
    borderRadius: 1,
    mb: 2,
    textAlign: 'center'
});

// Common styles for buttons
const buttonStyle = (isDarkMode, variant = 'primary') => ({
    bgcolor: themeColors[isDarkMode ? 'dark' : 'light'].button[variant],
    color: variant === 'secondary' && !isDarkMode ? '#333' : 'white',
    '&:hover': {
        bgcolor: themeColors[isDarkMode ? 'dark' : 'light'].button[`${variant}Hover`]
    }
});

// Common styles for content sections
const contentSectionStyle = (isDarkMode) => ({
    p: 3,
    bgcolor: themeColors[isDarkMode ? 'dark' : 'light'].background.secondary,
    color: themeColors[isDarkMode ? 'dark' : 'light'].text.primary
});

const Level3PendingDialog = ({
    open,
    onClose,
    incidentId,
    isResassignButtonVisible,
    isApproveButtonVisible,
    onApprove,
    showExtendButton,
    showActionTaken,
    isFileViewVisible,
    incidentDetails,
    onExtend,
    onReassign,
    incidentStatus
}) => {
    const { isDarkMode } = useContext(ThemeContext);
    const [loading, setLoading] = useState(true);
    const [firstPartyData, setFirstPartyData] = useState([]);
    const [thirdPartyData, setThirdPartyData] = useState([]);
    const [rootCauseData, setRootCauseData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedAction, setSelectedAction] = useState("");
    const [uploadedFile, setUploadedFile] = useState(null);
    const [mediaDialogOpen, setMediaDialogOpen] = useState(false);
    const [mediaType, setMediaType] = useState('');
    const [mediaUrl, setMediaUrl] = useState('');
    const [mediaTitle, setMediaTitle] = useState('');
    const [fileDialogOpen, setFileDialogOpen] = useState(false);
    const [firLink, setFirLink] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submittedbylevel, setSubmittedbylevel] = useState(incidentDetails?.submittedbylevel || 1);

    // Add a debug log using the incidentId before fetching data:
    const fetchAllData = async () => {
        if (!incidentId) {
            console.log("No incidentId provided:", incidentId);
            setLoading(false);
            return;
        }
        //console.log("Fetching data for incidentId:", incidentId);
        setLoading(true);
        setError(null);
        try {
            const authToken = sessionStorage.getItem("AuthToken");
            if (!authToken) {
                throw new Error("No auth token found");
            }
            const parsedToken = JSON.parse(authToken);
            const token = parsedToken.token;
            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            };
            // Note: Verify that these endpoints and the expected response structure haven't changed.
            const [firstPartyResponse, thirdPartyResponse, rootCauseResponse] = await Promise.all([
                fetch(`${baseURL}/IncidentReporting/get-first-party-incident-pending-records/?IncidentReportingId=${incidentId}`, { headers }),
                fetch(`${baseURL}/IncidentReporting/get-third-party-incident-pending-records/?IncidentReportingId=${incidentId}`, { headers }),
                fetch(`${baseURL}/IncidentReporting/get-root-cause-pending-records/?IncidentReportingId=${incidentId}`, { headers })
            ]);
            if (!firstPartyResponse.ok || !thirdPartyResponse.ok || !rootCauseResponse.ok) {
                throw new Error('One or more requests failed');
            }
            const firstPartyData = await firstPartyResponse.json();
            const thirdPartyData = await thirdPartyResponse.json();
            const rootCauseData = await rootCauseResponse.json();
            // console.log("Fetched firstPartyData:", firstPartyData);
            setFirstPartyData(firstPartyData.data);
            setThirdPartyData(thirdPartyData.data);
            setRootCauseData(rootCauseData.data);
        } catch (err) {
            console.error("Error in fetchAllData:", err);
            setError('Failed to fetch incident details');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            // Only fetch if dialog is open and we have an id
            if (!open || !incidentId) {
                return;
            }

            try {
                await fetchAllData();
            } catch (error) {
                console.error("Error fetching data:", error);
                if (mounted) {
                    setError('Failed to fetch data');
                }
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            mounted = false;
        };
    }, [incidentId, open]); // Only depend on id and open state

    // Reset state when dialog closes
    useEffect(() => {
        if (!open) {
            setFirstPartyData([]);
            setThirdPartyData([]);
            setRootCauseData([]);
            setError(null);
        }
    }, [open]);

    const renderDetailSection = (label, value, description) => {
        return (
            <Box sx={{
                mb: 3,
                pb: 2,
                borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                bgcolor: isDarkMode ? '#2A2A2A' : '#F5F5F5',
                p: 2,
                borderRadius: '8px'
            }}>
                <Typography sx={{
                    color: isDarkMode ? 'white' : '#333',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    mb: 1
                }}>
                    {`${label} ${value ? `(${value})` : ''}`}
                </Typography>
                {description && (
                    <Typography sx={{
                        color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666',
                        fontSize: '14px'
                    }}>
                        {description}
                    </Typography>
                )}
            </Box>
        );
    };

    const renderSubItem = (label, value) => {
        // console.log(label , value)
        if (!value && value !== 0) return null;
        return (
            <Typography sx={{
                color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666',
                fontSize: '16px',
                mb: 1,
                pl: 2,
                bgcolor: isDarkMode ? '#1E1E1E' : '#E0E0E0',
                borderRadius: '4px',
                p: 1
            }}>
                {label}: {value}
            </Typography>
        );
    };

    // Log render cycle
    // console.log("incidentId :" , firstPartyData)
    // console.log("Rendering dialog with state:", {
    //     loading,
    //     firstPartyData,
    //     thirdPartyData,
    //     rootCauseData,
    //     error,
    //     isOpen: open,
    //     incidentId
    // });
    //console.log("level :", incidentDetails)
    const handleClose = (event, reason) => {
        onClose();
        setFirLink('');
    };

    const handleFileUpload = (e) => {
        if (e.target.files && e.target.files[0]) {
            setUploadedFile(e.target.files[0]);
        }
    };

    const handleActionSubmit = async () => {
        if (!selectedAction) {
            alert("Please select an action.");
            return;
        }
        
        // Set loading state to true immediately to disable the button
        setIsSubmitting(true);
        
        const formData = new FormData();
        formData.append("id", incidentId);
        formData.append("actionundertaken", selectedAction);
        if (uploadedFile) {
            formData.append("uploadfile", uploadedFile);
        }
    
        try {
            const result = await createActionUndertaken(formData);
            console.log("Action undertaken created:", result);
            if (onApprove) {
                await onApprove();
            }
            window.location.reload();
            toast.success("Action undertaken submitted successfully");
            onClose();
        } catch (error) {
            console.error("Error submitting action undertaken:", error);
            alert("Failed to submit action undertaken.");
            // Only reset the loading state on error
            setIsSubmitting(false);
        }
    };
    

    const handleViewPhoto = (photoUrl) => {
        setMediaType('image');
        setMediaUrl(photoUrl);
        setMediaTitle('Case Photo');
        setMediaDialogOpen(true);
    };

    const handleViewMap = (location) => {
        setMediaType('map');
        setMediaUrl(`https://www.google.com/maps?q=${encodeURIComponent(location)}&output=embed`);
        setMediaTitle(`Location Map (${location})`);
        setMediaDialogOpen(true);
    };

    const handleReassign = async () => {
        const roleMapping = {
            'supervisor': { is_supervisor: true, is_safetysupervisor: true, is_safetyincharge: true },
            'safetySupervisor': { is_supervisor: false, is_safetysupervisor: true, is_safetyincharge: true },
            'safetyIncharge': { is_supervisor: false, is_safetysupervisor: false, is_safetyincharge: true }
        };

        const roleValues = roleMapping[selectedRole] || { is_supervisor: false, is_safetysupervisor: false, is_safetyincharge: false };

        try {
            const payload = {
                IncidentReportingId: incidentId, 
                // submittedbylevel: submittedbylevel, // Add submittedbylevel to the payload
                ...roleValues
            };

            setIsLoading(true);

            const authToken = sessionStorage.getItem("AuthToken");
            if (!authToken) {
                toast.error("Authentication token not found. Please login again.");
                window.location.href = '/login';
                return;
            }
            console.log("data: ", roleMapping[selectedRole])
            const parsedToken = JSON.parse(authToken);
            const token = parsedToken.token;

            const response = await fetch(`${baseURL}/IncidentReporting/reassign/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Ensure this is defined
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                const errorData = await response.json();
                console.error('Error response:', errorData); // Log the error response for debugging
                throw new Error(errorData.message || 'Failed to reassign role');
            }

            window.location.reload();
            toast.success('Form successfully reassigned'); // Use toast for success message
            onClose();

        } catch (error) {
            console.error('Error reassigning role:', error);
            toast.error(error.message || 'Failed to reassign role. Please try again.'); // Use toast for error message
        } finally {
            setIsLoading(false);
        }
    };

    const renderActionTakenSection = () => {
        if (!isFileViewVisible || !incidentDetails) return null;

        return (
            <>
                <Box sx={{
                    bgcolor: isDarkMode ? '#000' : '#3b536f',
                    color: 'white',
                    p: 1,
                    borderRadius: 1,
                    mb: 2,
                    mt:2,
                    textAlign: 'center'
                }}>
                    <Typography>SafetyIncharge </Typography>
                </Box>
                <Paper elevation={0} sx={{ mt: 2, p: 2, bgcolor: isDarkMode ? '#333' : '#f5f5f5', borderRadius: 2 }}>

                    <Typography variant="h6" sx={{ mb: 2, color: isDarkMode ? '#fff' : '#000' }}>
                        Action Undertaken
                    </Typography>

                    <Typography variant="body1" sx={{ mb: 2, color: isDarkMode ? '#ddd' : '#333' }}>
                        {incidentDetails.actionundertaken || "No action details available"}
                    </Typography>

                    {/* Modified file view button */}
                    {incidentDetails.fileurl && (
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            startIcon={<VisibilityIcon />}
                            onClick={() => {
                                setFirLink(incidentDetails.fileurl);
                                setFileDialogOpen(true);
                            }}
                            sx={{
                                mt: 1,
                                textTransform: 'none',
                                bgcolor: isDarkMode ? '#4a6da7' : '#3b536f',
                                '&:hover': {
                                    bgcolor: isDarkMode ? '#5d84c3' : '#3b5366',
                                }
                            }}
                        >
                            View Uploaded File
                        </Button>
                    )}
                </Paper>
            </>
        );
    };
    // console.log("firstPartyData :", firstPartyData)
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        bgcolor: isDarkMode ? '#1E1E1E' : '#fff',
                        color: isDarkMode ? 'white' : '#333',
                        borderRadius: '12px',
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.2)',
                        display: 'flex',
                        flexDirection: 'column',
                        maxHeight: '90vh',
                        position: 'relative'
                    }
                }}
            >
                {/* Add Close Button */}
                <IconButton
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: isDarkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)',
                        '&:hover': {
                            color: isDarkMode ? 'white' : 'black',
                            bgcolor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
                        },
                        zIndex: 1
                    }}
                >
                    <CloseIcon />
                </IconButton>

                {/* Dialog Header */}
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderBottom: `1px solid ${isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
                        p: 2.5,
                        flexShrink: 0
                    }}
                >
                    <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        Case ID : {incidentDetails?.caseid || "N/A"}
                    </Typography>
                </DialogTitle>

                {/* Single scrollable container for all content */}
                <Box sx={{
                    overflowY: 'auto',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    {/* Show this on every level*/}
                    {incidentDetails && (
                        <Box sx={{
                            p: 3,
                            borderBottom: `1px solid ${isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`,
                            bgcolor: isDarkMode ? '#121212' : '#fff',  // Changed to match next section's background
                            flexShrink: 0
                        }}>
                            {/* With this */}
                            <Box sx={sectionHeaderStyle(isDarkMode)}>
                                <Typography>Supervisor </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                                <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>
                                    Route: {incidentDetails.schedulecode}
                                    <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>
                                        {incidentDetails.route}
                                    </Typography>
                                </Typography>

                                {/* View Buttons */}
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    {incidentDetails?.imagestr && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<CameraAlt />}
                                            onClick={() => handleViewPhoto(incidentDetails.imagestr)}
                                            sx={{
                                                bgcolor: isDarkMode ? '#363636' : '#f5f7fa',
                                                color: isDarkMode ? 'white' : '#333333',
                                                border: '1px solid',
                                                borderColor: isDarkMode ? '#808080' : '#3b536f',
                                                '&:hover': {
                                                    bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(59, 83, 111, 0.1)'
                                                }
                                            }}
                                        >
                                            View Case Photo
                                        </Button>
                                    )}

                                    {incidentDetails?.location && (
                                        <Button
                                            variant="outlined"
                                            startIcon={<LocationOn />}
                                            sx={{
                                                bgcolor: isDarkMode ? '#363636' : '#f5f7fa',
                                                color: isDarkMode ? 'white' : '#333333',
                                                border: '1px solid',
                                                borderColor: isDarkMode ? '#808080' : '#3b536f',
                                                '&:hover': {
                                                    bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(59, 83, 111, 0.1)'
                                                }
                                            }}
                                            onClick={() => handleViewMap(incidentDetails.location)}
                                        >
                                            View Map
                                        </Button>
                                    )}
                                </Box>
                            </Box>

                            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 3 }}>
                                {/* Basic information */}
                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Pilot Name:</Typography>
                                        <Typography>{incidentDetails?.drivername || "N/A"}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Bus No:</Typography>
                                        <Typography>{incidentDetails?.vehiclenumber || "N/A"}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Shift:</Typography>
                                        <Typography>{incidentDetails?.shift || "N/A"}</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 1 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>Date:</Typography>
                                        <Typography>{incidentDetails?.incidentdate || "N/A"}</Typography>
                                    </Box>
                                </Box>

                                {/* Description */}
                                {incidentDetails?.description && (
                                    <Box sx={{
                                        border: isDarkMode ? '1px solid #808080' : '1px solid #3b536f',
                                        borderRadius: 1,
                                        p: 2
                                    }}>
                                        <Typography sx={{ fontWeight: 'bold', paddingBottom: '10px' }}>
                                            Description:
                                        </Typography>
                                        <Typography>{incidentDetails.description}</Typography>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    )}

                    {/* Show this on submittedbylevel > 1 */}
                    {incidentDetails?.submittedbylevel > 1 && (
                        <>
                            <Box sx={{
                                bgcolor: isDarkMode ? '#000' : '#3b536f',
                                color: 'white',
                                p: 2,
                                borderRadius: 1,
                                ml: 2,
                                mr: 2,
                                mb: 2,
                                mt: 2,
                                textAlign: 'center'
                            }}>
                                <Typography>Safety Supervisor</Typography>
                            </Box>
                            <Box sx={{
                                p: 3,
                                bgcolor: isDarkMode ? '#121212' : '#fff',
                                color: isDarkMode ? 'white' : '#333',
                            }}>
                                {/* Rest of the content (First Party Details, etc.) */}
                                {loading ? (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                                        <CircularProgress />
                                    </Box>
                                ) : error ? (
                                    <Box sx={{ textAlign: 'center', p: 2 }}>
                                        <Typography color="error">{error}</Typography>
                                        <Button onClick={fetchAllData} sx={{ mt: 2 }} variant="outlined">
                                            Retry
                                        </Button>
                                    </Box>
                                ) : (
                                    <>
                                        <Typography variant="h6" sx={{ mb: 1, bgcolor: isDarkMode ? 'black' : 'white', color: isDarkMode ? 'white' : 'black', padding: '8px', borderRadius: '5px', }}>
                                            First Party Details
                                        </Typography>

                                        {!loading && !error && firstPartyData.length > 0 && firstPartyData.map((data, index) => (
                                            <Box key={index} sx={{ border: isDarkMode ? '1px solid #808080' : '1px solid #3b536f', borderRadius: 1, p: 1 }}>
                                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                    Company Vehicle Damage ({data.companyvehicledmg ? "Yes" : "No"})
                                                </Typography>
                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                    Damage Level: {data.dmglevel || "N/A"}
                                                </Typography>

                                                <Typography sx={{ mb: 3, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                    Description: {data.dmgdescription || "No description available"}
                                                </Typography>

                                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                    Collision Type: {data.collisiontype || "N/A"}
                                                </Typography>

                                                {data.staffinjury_details && data.staffinjury_details.map((injury, idx) => (
                                                    <Box key={idx} sx={{ mb: 3 }}>
                                                        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                            Staff Injured (Yes)
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Staff {data.staffinjury_details.length > 1 ? idx + 1 : ''} details
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Severity Level: {injury.severitylevel || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Name: {injury.name || "N/A"}
                                                        </Typography>

                                                        <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Description: {injury.description || "No description available"}
                                                        </Typography>
                                                    </Box>
                                                ))}

                                                {data.passengerinjury_details && data.passengerinjury_details.map((injury, idx) => (
                                                    <Box key={idx} sx={{ mb: 3 }}>
                                                        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                            Passenger Injured (Yes)
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Passenger {data.passengerinjury_details.length > 1 ? idx + 1 : ''} details
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Severity Level: {injury.severitylevel || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Name: {injury.name || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Identity Card No: {injury.identityproof || "0000 0000 0000"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Age: {injury.age || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Gender: {injury.gender || "N/A"}
                                                        </Typography>

                                                        <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Description: {injury.description || "No description available"}
                                                        </Typography>
                                                    </Box>
                                                ))}

                                                <Box sx={{ mb: 3 }}>
                                                    <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                        Incident / Accident Level
                                                    </Typography>
                                                    <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Actual Level: {data.actuallevel || "B"}
                                                    </Typography>
                                                    <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Probability Level: {data.probabilitylevel || "A"}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        ))}

                                        {!loading && !error && firstPartyData.length === 0 && (
                                            <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)', mb: 3, ml: 2, mr: 2 }}>
                                                No first party details available
                                            </Typography>
                                        )}

                                        <Typography variant="h6" sx={{ mb: 1, mt: 2, bgcolor: isDarkMode ? 'black' : 'white', color: isDarkMode ? 'white' : 'black', padding: '8px', borderRadius: '5px', }}>
                                            Third Party Details
                                        </Typography>
                                        {!loading && !error && thirdPartyData.length > 0 && thirdPartyData.map((data, index) => (
                                            <Box key={index} sx={{ border: isDarkMode ? '1px solid #808080' : '1px solid #3b536f', borderRadius: 1, p: 1 }}>
                                                {/* Vehicle Damage Section */}
                                                {data.vehicledamage && (
                                                    <Box sx={{ mb: 3 }}>
                                                        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                            Third Vehicle Damage ({data.vehicledamage ? "Yes" : "No"})
                                                        </Typography>
                                                        {data.vehicle_damage_details && data.vehicle_damage_details.map((vehicle, idx) => (
                                                            <Box key={idx} sx={{ mb: 2 }}>
                                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Vehicle Type: {vehicle.vehicletype || "N/A"}
                                                                </Typography>
                                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Vehicle No: {vehicle.vehiclenumber || "N/A"}
                                                                </Typography>
                                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Insurance: {vehicle.insuarance || "N/A"}
                                                                </Typography>
                                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Damage Level: {vehicle.damagelevel || "N/A"}
                                                                </Typography>
                                                                <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Description: {vehicle.description || "No description available"}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}

                                                {/* Individual Injured Section */}
                                                {data.individualinjured && data.individual_injury_details && data.individual_injury_details.map((person, idx) => (
                                                    <Box key={idx} sx={{ mb: 3 }}>
                                                        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                            Third Party Individual Injured ({data.individualinjured ? "Yes" : "No"})
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Person {data.individual_injury_details.length > 1 ? idx + 1 : ''}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Severity Level: {person.severitylevel || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Name: {person.name || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Identity Card No: {person.identityproof || "0000 0000 0000"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Age: {person.age || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Gender: {person.gender || "N/A"}
                                                        </Typography>
                                                        <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Description: {person.description || "No description available"}
                                                        </Typography>
                                                    </Box>
                                                ))}

                                                {/* Property Damage Section */}
                                                {data.propertydamage && (
                                                    <Box sx={{ mb: 3 }}>
                                                        <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                            Property Damage
                                                        </Typography>
                                                        {data.property_damage_details && data.property_damage_details.map((property, idx) => (
                                                            <Box key={idx} sx={{ mb: 2 }}>
                                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Property {data.property_damage_details.length > 1 ? idx + 1 : ''}
                                                                </Typography>
                                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Property Type: {property.propertydmg || "N/A"}
                                                                </Typography>
                                                                <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                                    Description: {property.description || "No description available"}
                                                                </Typography>
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                )}
                                            </Box>
                                        ))}

                                        {!loading && !error && thirdPartyData.length === 0 && (
                                            <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)', mb: 3, ml: 2, mr: 2 }}>
                                                No third party details available
                                            </Typography>
                                        )}

                                        <Typography variant="h6" sx={{ mb: 1, mt: 2, bgcolor: isDarkMode ? 'black' : 'white', color: isDarkMode ? 'white' : 'black', padding: '8px', borderRadius: '5px', }}>
                                            Root Cause Analysis
                                        </Typography>

                                        {!loading && !error && rootCauseData.length > 0 && rootCauseData.map((data, index) => (
                                            <Box key={index} sx={{ border: isDarkMode ? '1px solid #808080' : '1px solid #3b536f', borderRadius: 1, p: 1 }}>
                                                <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                                                    Preventable ({data.preventable ? "Yes" : "No"})
                                                </Typography>

                                                {data.rca_prevention_details && data.rca_prevention_details.map((detail, idx) => (
                                                    <Box key={idx}>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Lack Of Patience {detail.lackofpatience ? "Yes" : "No"}</Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Lack Of Space {detail.lackofspace ? "Yes" : "No"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Speed {detail.speed ? "Yes" : "No"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Road Sharing {detail.roadsharing ? "Yes" : "No"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Lane Discipline {detail.lanediscipline ? "Yes" : "No"}
                                                        </Typography>
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                        Observation {detail.observations ? "Yes" : "No"}
                                                        </Typography>
                                                        
                                                    </Box>
                                                ))}

                                                <Typography sx={{ fontWeight: 'bold', mb: 1, mt: 3 }}>
                                                    Conclusion of Investigation
                                                </Typography>
                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                    Blameworthy: {data.blameworthy ? "Yes" : "No"}
                                                </Typography>
                                                <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                    Conclusion: {data.conclusion || "N/A"}
                                                </Typography>

                                                <Typography sx={{ fontWeight: 'bold', mb: 1, mt: 3 }}>
                                                    Reported to Police ({data.reportedtopolice ? "Yes" : "No"})
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                                                    {data.reportedtopolice ? (
                                                        data.firlink && (
                                                            <Button
                                                                variant="contained"
                                                                size="small"
                                                                onClick={() => {
                                                                    setFirLink(data.firlink);
                                                                    setFileDialogOpen(true);
                                                                }}
                                                                sx={{
                                                                    width: '150px',
                                                                    bgcolor: isDarkMode ? '#4a6da7' : '#3b536f',
                                                                    color: 'white',
                                                                    '&:hover': {
                                                                        bgcolor: isDarkMode ? '#5d84c3' : '#1565c0'
                                                                    }
                                                                }}
                                                            >
                                                                View Document
                                                            </Button>
                                                        )
                                                    ) : (
                                                        <Typography sx={{ mb: 0.5, color: isDarkMode ? 'rgba(255,255,255,0.7)' : '#666' }}>
                                                            Reason: {data.notreportedreason || "No reason provided"}
                                                        </Typography>
                                                    )}
                                                </Box>
                                            </Box>
                                        ))}

                                        {!loading && !error && rootCauseData.length === 0 && (
                                            <Typography sx={{ color: isDarkMode ? 'rgba(255,255,255,0.5)' : 'rgba(0,0,0,0.5)', mb: 3, ml: 2, mr: 2 }}>
                                                No root cause analysis available
                                            </Typography>
                                        )}
                                    </>
                                )}
                                {isFileViewVisible && renderActionTakenSection()}
                            </Box>

                            {/* Action Taken section */}
                            {showActionTaken && (


                                // show this on submittedbylevel 1,2
                                <Box sx={{ p: 3, bgcolor: isDarkMode ? '#121212' : '#fff' }}>
                                    <Box sx={{
                                        bgcolor: isDarkMode ? '#000' : '#3b536f',
                                        color: 'white',
                                        p: 1,
                                        borderRadius: 1,
                                        mb: 2,
                                        textAlign: 'center'
                                    }}>
                                        <Typography>SafetyIncharge </Typography>
                                    </Box>

                                    <Typography variant="h6" sx={{
                                        mb: 3,
                                        color: isDarkMode ? 'white' : '#333',
                                        fontWeight: 600
                                    }}>
                                        Action Taken
                                    </Typography>

                                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                        <Select
                                            value={selectedAction}
                                            onChange={(e) => setSelectedAction(e.target.value)}
                                            displayEmpty
                                            fullWidth
                                            sx={{
                                                mb: 2,
                                                bgcolor: isDarkMode ? '#2A2A2A' : '#f5f5f5',
                                                color: isDarkMode ? 'white' : 'black',
                                                borderRadius: '6px',
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'transparent',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: isDarkMode ? 'rgba(255,255,255,0.2)' : 'rgba(0,0,0,0.2)',
                                                },
                                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: isDarkMode ? '#4a6da7' : '#1976d2',
                                                }
                                            }}
                                        >
                                            <MenuItem value="" sx={{ opacity: 0.7 }}>Select Action</MenuItem>
                                            <MenuItem value="1vvl">1VVL</MenuItem>
                                            <MenuItem value="2vvl">2VVL</MenuItem>
                                            <MenuItem value="3vvl">2VVL</MenuItem>
                                            <MenuItem value="Final Warning">Final Warning</MenuItem>
                                            <MenuItem value="Recomended to SMT">Recomended to SMT</MenuItem>
                                            <MenuItem value="Strongly Advice">Strongly Advice</MenuItem>
                                            <MenuItem value="Terminated">Terminated</MenuItem>

                                        </Select>

                                        <Button
                                            variant="contained"
                                            component="label"
                                            sx={{
                                                mb: uploadedFile ? 1 : 2,
                                                bgcolor: isDarkMode ? '#404040' : '#3b536f',
                                                color: 'white',
                                                '&:hover': { bgcolor: isDarkMode ? '#505050' : '#3b536g' },
                                                width: '26%',
                                            }}

                                        >
                                            Upload Document
                                            <input type="file" hidden onChange={handleFileUpload} />
                                        </Button>

                                        {uploadedFile && (
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mb: 2,
                                                p: 1,
                                                bgcolor: isDarkMode ? 'rgba(255,255,255,0.05)' : 'rgba(0,0,0,0.03)',
                                                borderRadius: '4px'
                                            }}>
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: isDarkMode ? 'rgba(255,255,255,0.8)' : 'rgba(0,0,0,0.7)',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        flexGrow: 1,
                                                    }}
                                                >
                                                    {uploadedFile.name}
                                                </Typography>
                                                <IconButton
                                                    size="small"

                                                    onClick={() => setUploadedFile(null)}
                                                    sx={{ color: isDarkMode ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)' }}
                                                >
                                                    <CloseIcon fontSize="small" />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                </Box>

                {/* Footer - Dialog Actions */}
                <DialogActions sx={{
                    bgcolor: isDarkMode ? '#1a1a1a' : '#f5f5f5',
                    p: 2,
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '12px',
                    width: '100%'
                }}>
                    {/* Role Dropdown positioned on left */}
                    {isResassignButtonVisible && (
                        <FormControl variant="outlined" sx={{ minWidth: 200, margin: 0 }}>
                            <InputLabel
                                id="role-select-label"
                                sx={{
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    color: isDarkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.6)',
                                    top: "-8px"
                                }}
                            >
                                Reassign Role
                            </InputLabel>
                            <Select
                                labelId="role-select-label"
                                value={selectedRole}
                                onChange={(e) => setSelectedRole(e.target.value)}
                                label=""
                                sx={{
                                    bgcolor: isDarkMode ? "#424242" : "white",
                                    color: isDarkMode ? "white" : "black",
                                    "& .MuiSelect-select": {
                                        padding: "8px",
                                        textAlign: "left",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: isDarkMode ? "#666" : "#ccc",
                                    },
                                    "&:hover .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#4CAF50",
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#4CAF50",
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            bgcolor: isDarkMode ? "#333" : "#fff",
                                            color: isDarkMode ? "white" : "black",
                                            "& .MuiMenuItem-root": {
                                                "&:hover": {
                                                    bgcolor: isDarkMode ? "#444" : "#f5f5f5",
                                                },
                                            },
                                            "& .MuiMenuItem-root.Mui-selected": {
                                                backgroundColor: "#3b5bdb !important",
                                                color: "white",
                                                "&:hover": {
                                                    backgroundColor: "#364fc7 !important",
                                                },
                                            },
                                        },
                                    },
                                }}
                            >
                                <MenuItem value="none">None</MenuItem>
                                <MenuItem value="supervisor">Supervisor</MenuItem>
                                <MenuItem value="safetySupervisor">Safety Supervisor</MenuItem>
                                <MenuItem value="safetyIncharge">Safety Incharge</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    {/* Right side buttons container */}
                    <Box sx={{ 
                        display: 'flex', 
                        gap: '12px',
                        marginLeft: 'auto',
                        '& > *:not(:last-child)': {
                            marginRight: '8px'
                        }
                    }}>
                        {/* Cancel Button - Always present */}
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            sx={{
                                bgcolor: isDarkMode ? '#666666' : '#e0e0e0',
                                color: isDarkMode ? 'white' : 'rgba(0, 0, 0, 0.87)',
                                '&:hover': {
                                    bgcolor: isDarkMode ? '#777777' : '#d5d5d5'
                                },
                                height: '44px',
                                minWidth: '100px',
                                borderRadius: '4px',
                                textTransform: 'none',
                                boxShadow: 'none',
                                fontSize: '15px',
                                order: isApproveButtonVisible || showExtendButton || (showActionTaken && incidentDetails?.submittedbylevel !== 1) || (selectedRole && selectedRole !== "none") ? 1 : 2
                            }}
                        >
                            Cancel
                        </Button>

                        {/* Reassign/Approve Button */}
                        {selectedRole && selectedRole !== "none" ? (
                            <Button
                                onClick={handleReassign}
                                variant="contained"
                                disabled={isLoading}
                                sx={{
                                    bgcolor: '#f76707',
                                    color: 'white',
                                    '&:hover': { bgcolor: '#e8590c' },
                                    height: '44px',
                                    minWidth: '100px',
                                    borderRadius: '4px',
                                    textTransform: 'none',
                                    boxShadow: 'none',
                                    fontSize: '15px',
                                    order: 2
                                }}
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : "Reassign"}
                            </Button>
                        ) : (
                            isApproveButtonVisible && (
                                <Button
                                    onClick={onApprove}
                                    variant="contained"
                                    disabled={isLoading}
                                    sx={{
                                        bgcolor: '#4CAF50',
                                        color: 'white',
                                        '&:hover': { bgcolor: '#3d8b40' },
                                        height: '44px',
                                        minWidth: '100px',
                                        borderRadius: '4px',
                                        textTransform: 'none',
                                        boxShadow: 'none',
                                        fontSize: '15px',
                                        order: 2
                                    }}
                                >
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : "Approve"}
                                </Button>
                            )
                        )}

                        {/* Extend Button */}
                        {(showExtendButton || (incidentDetails?.isTimeExpired && [1, 2, 3].includes(incidentDetails?.submittedbylevel))) && (
                            <Button
                                onClick={onExtend}
                                variant="contained"
                                sx={{
                                    bgcolor: isDarkMode ? '#4a6da7' : '#1976d2',
                                    color: 'white',
                                    '&:hover': { bgcolor: isDarkMode ? '#5d84c3' : '#1565c0' },
                                    px: 4,
                                    order: 2
                                }}
                            >
                                Extend for 24hr
                            </Button>
                        )}

                        {/* Submit Button */}
                       {showActionTaken && incidentDetails?.submittedbylevel !== 1 && (
                    <Button
                        onClick={handleActionSubmit}
                        variant="contained"
                        disabled={isSubmitting || !selectedAction} // Disable if submitting or no action selected
                        sx={{
                            bgcolor: isDarkMode ? '#4a6da7' : '#3b536f',
                            color: 'white',
                            '&:hover': { bgcolor: isDarkMode ? '#5d84c3' : '#1565c0' },
                            '&.Mui-disabled': {
                                bgcolor: isDarkMode ? 'rgba(74, 109, 167, 0.5)' : 'rgba(59, 83, 111, 0.7)',
                                color: 'white'
                            },
                            px: 4,
                            order: 2
                        }}
                    >
                        {isSubmitting ? (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ marginRight: '8px' }}>Submitting</span>
                                <CircularProgress size={16} sx={{ color: 'white' }} />
                            </Box>
                        ) : "Submit"}
                    </Button>
                )}
                    </Box>
                </DialogActions>
            </Dialog>

            {/* Media Dialog for both Image and Map */}
            <Dialog
                open={mediaDialogOpen}
                onClose={() => setMediaDialogOpen(false)}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
                        color: isDarkMode ? 'white' : '#333333'
                    }
                }}
            >
                <DialogTitle sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
                }}>
                    <Typography variant="h6" sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                        {mediaTitle}
                    </Typography>
                    <Box>
                        {mediaType === 'image' && (
                            <IconButton
                                onClick={async () => {
                                    try {
                                        // Create a new blob from the image URL using fetch
                                        const response = await fetch(mediaUrl);
                                        const blob = await response.blob();
                                        
                                        // Create a blob URL for the image
                                        const blobUrl = window.URL.createObjectURL(blob);
                                        
                                        // Create a temporary anchor element
                                        const link = document.createElement('a');
                                        link.href = blobUrl;
                                        link.setAttribute('download', `case-image-${incidentDetails?.caseid || 'download'}.jpg`);
                                        
                                        // Append to body, click, and remove
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                        
                                        // Clean up by revoking the blob URL
                                        window.URL.revokeObjectURL(blobUrl);
                                    } catch (error) {
                                        console.error("Error downloading image:", error);
                                        // Show an error message instead of opening in a new tab
                                        alert("Failed to download the image. Please try again later.");
                                    }
                                }}
                                sx={{
                                    color: isDarkMode ? 'white' : '#333333',
                                    '&:hover': {
                                        bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(59, 83, 111, 0.1)'
                                    }
                                }}
                                title="Download image"
                            >
                                <GetAppIcon />
                            </IconButton>
                        )}
                        <IconButton
                            onClick={() => setMediaDialogOpen(false)}
                            sx={{ color: isDarkMode ? 'white' : '#333333' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{
                    bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
                    color: isDarkMode ? 'white' : '#333333',
                    height: mediaType === 'map' ? '400px' : 'auto',
                    p: mediaType === 'map' ? 0 : 2
                }}>
                    {mediaType === 'image' ? (
                        <Zoom>
                            <img src={mediaUrl} alt="Case" style={{ width: '100%', height: 'auto' }} />
                        </Zoom>
                    ) : (
                        <iframe
                            src={mediaUrl}
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            title="Location Map"
                        />
                    )}
                </DialogContent>
            </Dialog>

            {/* New File Dialog */}
            <Dialog
                open={fileDialogOpen}
                onClose={() => setFileDialogOpen(false)}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
                        color: isDarkMode ? 'white' : '#333333'
                    }
                }}
            >
                <DialogTitle sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
                }}>
                    <Typography variant="h6" sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                        Uploaded Document
                    </Typography>
                    <Box>
                        <IconButton
                            onClick={() => window.open(firLink, '_blank')}
                            sx={{
                                color: isDarkMode ? 'white' : '#333333',
                                '&:hover': {
                                    bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(59, 83, 111, 0.1)'
                                }
                            }}
                            title="Open in new tab"
                        >
                            <GetAppIcon />
                        </IconButton>
                        <IconButton
                            onClick={() => setFileDialogOpen(false)}
                            sx={{ color: isDarkMode ? 'white' : '#333333' }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{
                    bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
                    p: 2,
                    height: 'auto'
                }}>
                    <Zoom>
                        {firLink.endsWith('.pdf') ? (
                            <iframe
                                src={firLink}
                                style={{
                                    width: '100%',
                                    height: '400px',
                                    border: 'none'
                                }}
                                title="Document Viewer"
                            />
                        ) : (
                            <img
                                src={firLink}
                                alt="Document"
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '70vh',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                    </Zoom>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default Level3PendingDialog;

