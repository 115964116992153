"use client"

import { useState, useEffect, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { ThemeContext } from "../../../store/ThemeContext"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Dialog,
  DialogContent,
  CircularProgress,
} from "@mui/material"
import {
  Search,
  NavigateBefore,
  NavigateNext,
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material"
import { getSupervisorIncident } from "../../../API/IncidentAPI"
import timeoutImage from "../../../assets/Vector - Time Over.svg"
import BlackTimeOver from "../../../assets/timeoverblack.svg"
import Level3PendingDialog from "../SafetySupervisor/Level3PendingDialog"

/**
 * SafetyInchargeIncident component for managing and displaying safety incidents
 * Provides functionality to view, filter, and manage incident records
 */
const SafetyinchargeIncident = () => {
  // Navigation and theme
  const navigate = useNavigate()
  const { isDarkMode } = useContext(ThemeContext)
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  
  // Records data state
  const [incidents, setIncidents] = useState([])
  const [searchQuery, setSearchQuery] = useState("")
  const [recordType, setRecordType] = useState("pending")
  const [totalCount, setTotalCount] = useState(0)
  
  // Loading and error states
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [shouldFetch, setShouldFetch] = useState(false)
  
  // Dialog and selected incident states
  const [selectedIncident, setSelectedIncident] = useState(null)
  const [selectedIncidentId, setSelectedIncidentId] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false)
  const [showLevel3PendingDialog, setShowLevel3PendingDialog] = useState(false)
  
  // Flags for conditional rendering
  const [isActionButtonVisible, setIsActionButtonVisible] = useState(false)
  const [isFileUploadVisible, setIsFileUploadVisible] = useState(false)
  const [showActionTaken, setShowActionTaken] = useState(false)

  /**
   * Handles record type change (Pending/Completed)
   * @param {object} event - Change event from Select component
   */
  const handleChange = (event) => {
    const value = event.target.value
    setRecordType(value)
  }

  /**
   * Effect hook to navigate to completed records page when recordType changes
   */
  useEffect(() => {
    if (recordType === "completed") {
      navigate("/operations/completedrecords")
    }
  }, [recordType, navigate])

  /**
   * Fetches incidents from the API
   */
  const fetchIncidents = async () => {
    if (currentPage < 1) {
      setCurrentPage(1)
    }

    try {
      setLoading(true)
      const response = await getSupervisorIncident(2, currentPage, pageSize, searchQuery)
      setIncidents(response.data?.results || [])
      setTotalPages(response.data?.total_page_count || 0)
    } catch (err) {
      setError(err.message)
      setIncidents([])
    } finally {
      setLoading(false)
    }
  }

  /**
   * Effect hook to fetch incidents when pagination parameters change
   */
  useEffect(() => {
    fetchIncidents()
  }, [currentPage, pageSize])

  /**
   * Calculates time remaining before an incident expires
   * @param {string} endTime - ISO timestamp for end time
   * @returns {string} - Formatted time remaining string
   */
  const calculateTimeLeft = (endTime) => {
    const end = new Date(endTime)
    const now = new Date()
    const diff = end - now

    if (diff <= 0) return "0.00 hr"

    const hours = Math.floor(diff / (1000 * 60 * 60))
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    return `${hours}.${minutes.toString().padStart(2, "0")} hr`
  }

  /**
   * Handles row click event and shows appropriate dialog
   * @param {object} incident - Selected incident data
   */
  const handleRowClick = (incident) => {
    const remainingTime = incident.endtime ? calculateTimeLeft(incident.endtime) : "N/A"
    const isTimeExpired = remainingTime === "0.00 hr"

    if (isTimeExpired) {
      setShowTimeoutDialog(true)
    } else {
      setSelectedIncidentId(incident.id)
      setShowLevel3PendingDialog(true)
      setIsActionButtonVisible(incident.submittedbylevel == 2)
      setIsFileUploadVisible(incident.submittedbylevel == 3)
      setShowActionTaken(incident.submittedbylevel == 2)
      setSelectedIncident(incident)
    }
  }

  /**
   * Handles dialog close events
   */
  const handleDialogClose = () => {
    setIsDialogOpen(false)
  }

  const handleLevel3DialogClose = () => {
    setShowLevel3PendingDialog(false)
    setSelectedIncidentId(null)
  }

  /**
   * Handles page size change in pagination
   * @param {object} event - Change event from select component
   */
  const handlePageSizeChange = (event) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setCurrentPage(1)
  }

  /**
   * TimeoutDialog component for expired incidents
   */
  const TimeoutDialog = ({ open, onClose }) => (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
          borderRadius: '8px',
        },
      }}
    >
      <DialogContent sx={{
        textAlign: 'center',
        py: 6,
        px: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <IconButton
          onClick={onClose}
          sx={{ 
            position: "absolute", 
            top: 8, 
            right: 8, 
            color: isDarkMode ? "#fff" : "#000",
            '&:hover': {
              backgroundColor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          mb: 3
        }}>
          <img
            src={isDarkMode ? BlackTimeOver : timeoutImage}
            alt="Time Over"
            style={{
              width: '350px',
              height: 'auto',
            }}
          />
        </Box>
        <Typography
          sx={{
            color: isDarkMode ? '#fff' : '#000',
            fontSize: '28px',
            fontWeight: '500',
            mb: 2
          }}
        >
          Time is Over
        </Typography>
        <Typography
          sx={{
            color: isDarkMode ? '#fff' : '#000',
            fontSize: '18px',
            opacity: 0.8,
            mb: 3
          }}
        >
          Oops!.. this case is no longer available to update!
        </Typography>
        <Typography
          sx={{
            color: isDarkMode ? '#fff' : '#000',
            fontSize: '16px',
            opacity: 0.7
          }}
        >
          Kindly contact your Respective Manager to extend time for 24 hr.
        </Typography>
      </DialogContent>
    </Dialog>
  )

  /**
   * Handles back button click
   */
  const handleBack = () => {
    navigate(-1)
  }

  /**
   * Handles page change in pagination
   * @param {number} page - New page number
   */
  const handlePageChange = (page) => {
    if (page < 1) {
      return
    }
    setCurrentPage(page)
    fetchIncidents()
  }

  /**
   * Handles search key press event
   * @param {object} e - Key press event
   */
  const handleSearchKeyPress = (e) => {
    if (e.key === 'Enter') {
      setCurrentPage(1)
      fetchIncidents()
    }
  }

  // Custom scrollbar styles for tables
  const customScrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: isDarkMode ? '#2c2c2c' : '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: isDarkMode ? '#555' : '#ccc',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: isDarkMode ? '#777' : '#aaa',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: isDarkMode ? '#2c2c2c' : '#f5f5f5',
    },
  }

  /**
   * Renders pagination buttons for navigation
   * @returns {Array} - Array of button components
   */
  const renderPaginationButtons = () => {
    const buttons = []
    const maxButtons = 5

    let startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(totalPages, startPage + maxButtons - 1)

    if (endPage - startPage < maxButtons - 1) {
      startPage = Math.max(1, endPage - maxButtons + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={currentPage === i ? "contained" : "outlined"}
          sx={{
            minWidth: "32px",
            height: "32px",
            p: 0,
            mx: 0.5,
            bgcolor: currentPage === i ? "#3b536f" : isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
            color: currentPage === i ? "#fff" : isDarkMode ? "#fff" : "#666",
            "&:hover": {
              bgcolor: currentPage === i ? "#3b536f" : isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)",
            },
            fontWeight: 500,
            fontSize: "0.875rem",
            textTransform: "none",
            borderRadius: "4px",
            boxShadow: currentPage === i ? 2 : "none",
          }}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>,
      )
    }

    return buttons
  }

  // Loading indicator
  if (loading)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: isDarkMode ? "rgba(26, 26, 26, 0.8)" : "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "80px" }}>
          <CircularProgress color={isDarkMode ? "primary" : "light"} size={30} />
          <Typography sx={{ color: isDarkMode ? "white" : "black", marginLeft: 2 }}>Loading...</Typography>
        </Box>
      </Box>
    )

  // Error indicator
  if (error)
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <Typography sx={{ color: isDarkMode ? "white" : "black" }}>Error: {error}</Typography>
      </Box>
    )

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        bgcolor: isDarkMode ? "#1E1E1E" : "#f5f5f5",
        marginLeft: "60px",
        marginTop: "50px",
      }}
    >
      {/* Header section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 4, py: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={handleBack}
            sx={{
              color: isDarkMode ? 'white' : 'black',
              bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              '&:hover': {
                bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
              },
              borderRadius: '18px',
              padding: '8px',
              marginRight: '16px',
              width: '60px',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <h1 className={`text-xl font-semibold relative ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            Incident Reporting
          </h1>
        </Box>
  
        {/* Centered Search Bar */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
          <TextField
            sx={{
              width: '100%',
              maxWidth: '350px',
              marginRight: '26px',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
                WebkitTextFillColor: isDarkMode ? 'white' : 'black',
              },
            }}
            placeholder="Press Enter to search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearchKeyPress}
            InputProps={{
              startAdornment: <Search sx={{ mr: 1, color: isDarkMode ? 'gray' : '#666' }} />,
              sx: {
                bgcolor: isDarkMode ? '#424242' : '#fff',
                borderRadius: '25px',
                height: '40px',
                '& input': {
                  padding: '0',
                  background: 'none',
                  '&::placeholder': {
                    color: isDarkMode ? 'white' : 'black',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: isDarkMode ? 'transparent' : '#e0e0e0',
                }
              }
            }}
          />
        </Box>
  
        {/* Records type dropdown */}
        <FormControl sx={{ minWidth: 250, bgcolor: isDarkMode ? '#424242' : '#fff', borderRadius: 3, ml: 2, height: '40px' }}>
          <Select
            value={recordType}
            onChange={(e) => {
              setRecordType(e.target.value);
              handleChange(e);
            }}
            sx={{
              color: isDarkMode ? 'white' : 'black',
              height: '40px',
              '& .MuiSelect-icon': { color: isDarkMode ? 'white' : 'black' },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0',
              },
            }}
          >
            <MenuItem value="pending">Pendings</MenuItem>
            <MenuItem value="completed">Completed Records</MenuItem>
          </Select>
        </FormControl>
      </Box>
  
      {/* Conditional rendering based on recordType */}
      {recordType === "pending" ? (
        <>
          {/* Incidents table */}
          <TableContainer sx={{
            flex: 1,
            overflow: 'auto',
            paddingLeft: '52px',
            paddingRight: '39px',
            backgroundColor: isDarkMode ? "#1E1E1E" : "#f5f5f5",
            maxHeight: 'calc(100vh - 215px)',
            borderRadius: "10px",
            '& .MuiTable-root': {
              width: '100%',
              border: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
            },
            ...customScrollbarStyles,
          }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {["Sr No", "Case ID", "Bus Number", "Pilot Name ", "Schedule", "Record Date", "Status"].map(
                    (header) => (
                      <TableCell
                        key={header}
                        align="center"
                        sx={{
                          backgroundColor: isDarkMode ? "#37445A" : "#3b536f",
                          color: "#ffffff",
                          border: isDarkMode ? "1px solid #333333" : "1px solid #e0e0e0",
                          fontWeight: 600,
                        }}
                      >
                        {header}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {incidents.map((incident, index) => {
                  const remainingTime = incident.endtime ? calculateTimeLeft(incident.endtime) : "N/A"
                  const isOver = remainingTime === "0.00 hr"
                  const serialNumber = (currentPage - 1) * pageSize + index + 1
  
                  return (
                    <TableRow
                      key={incident.id || index}
                      onClick={() => handleRowClick(incident)}
                      sx={{
                        backgroundColor: isDarkMode ? "#4A4A4A" : "white",
                        "&:hover": {
                          backgroundColor: isDarkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.02)",
                          cursor: "pointer",
                        },
                        "& .MuiTableCell-root": {
                          borderBottom: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                          borderRight: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                          color: isDarkMode ? "#ffffff" : "#333333",
                          fontSize: "0.875rem",
                          padding: "12px 16px",
                          textAlign: "center",
                        },
                      }}
                    >
                      <TableCell
                        sx={{
                          color: isDarkMode ? "white" : "black",
                          textAlign: "center",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        {serialNumber}
                      </TableCell>
                      <TableCell>{incident.caseid}</TableCell>
                      <TableCell>{incident.vehiclenumber}</TableCell>
                      <TableCell>{incident.drivername}</TableCell>
                      <TableCell>{incident.schedulecode}</TableCell>
                      <TableCell>{incident.incidentdate}</TableCell>
                      <TableCell
                        sx={{
                          color: isOver ? "#f56565 !important" : isDarkMode ? "white" : "black",
                          textAlign: "center",
                          borderRight: "1px solid #e0e0e0",
                        }}
                      >
                        {remainingTime === "0.00 hr"
                          ? `Time Over | Level-${incident.submittedbylevel + 1} Pending`
                          : `Time Left ${remainingTime} | Level-${incident.submittedbylevel + 1} Pending`}
                      </TableCell>
                    </TableRow>
                  )
                })}
                {incidents.length === 0 && (
                  <TableRow>
                    <TableCell 
                      colSpan={7} 
                      align="center"
                      sx={{
                        backgroundColor: isDarkMode ? "#4A4A4A" : "white",
                        color: isDarkMode ? "#ffffff" : "#333333",
                        fontSize: "1rem",
                        padding: "20px",
                        border: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                      }}
                    >
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center',
                          gap: 2,
                          py: 4
                        }}
                      >
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                          No pending records found
                        </Typography>
                        
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
  
          {/* Pagination controls */}
          <Box sx={{
            position: "fixed",
            bottom: 10,
            left: "112px",
            right: "45px",
            p: 1,
            py: 1.3,
            display: "flex",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            justifyContent: "center",
            alignItems: "center",
            borderTop: isDarkMode ? "1px solid rgba(255,255,255,0.1)" : "1px solid rgba(0,0,0,0.1)",
            backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
            zIndex: 1000,
          }}>
            <Grid container alignItems="center" sx={{ maxWidth: 815, mx: "auto" }}>
              {/* Page navigation buttons */}
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                <IconButton
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  sx={{
                    color: isDarkMode ? "#fff" : "#666",
                    bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                    "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                    "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" },
                  }}
                >
                  <NavigateBefore />
                </IconButton>
  
                <Box sx={{ display: "flex", gap: 1 }}>{renderPaginationButtons()}</Box>
  
                <IconButton
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  sx={{
                    color: isDarkMode ? "#fff" : "#666",
                    bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                    "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                    "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" },
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </Grid>
  
              {/* Page number input */}
              <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                <Typography sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: 'nowrap' }}>
                  Page No
                </Typography>
                <TextField
                  type="number"
                  value={currentPage}
                  onChange={(e) => {
                    const newPage = Math.max(1, Math.min(totalPages, parseInt(e.target.value, 10) || 1));
                    setCurrentPage(newPage);
                    handlePageChange(newPage);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      const newPage = Math.max(1, Math.min(totalPages, parseInt(e.target.value, 10) || 1));
                      handlePageChange(newPage);
                    }
                  }}
                  sx={{
                    width: '60px',
                    bgcolor: isDarkMode ? '#424242' : '#fff',
                    '& input': {
                      color: isDarkMode ? 'white' : 'black',
                      padding: '6.8px',
                      textAlign: 'center',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'transparent' : '#e0e0e0',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#e0e0e0',
                    },
                  }}
                  inputProps={{
                    min: 1,
                    max: totalPages,
                    style: { textAlign: 'center' }
                  }}
                />
              </Grid>
  
              {/* Page size selection */}
              <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                <Typography sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: 'nowrap' }}>
                  Page Size
                </Typography>
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  size="small"
                  sx={{
                    width: "80px",
                    bgcolor: isDarkMode ? '#424242' : 'transparent',
                    color: isDarkMode ? 'white' : '#666',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'transparent' : 'rgba(0, 0, 0, 0.23)',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
                    },
                    '& .MuiSelect-icon': {
                      color: isDarkMode ? 'white' : '#666',
                    },
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}
  
      {/* Dialogs */}
      <TimeoutDialog open={showTimeoutDialog} onClose={() => setShowTimeoutDialog(false)} />
  
      {showLevel3PendingDialog && (
        <Level3PendingDialog
          open={showLevel3PendingDialog}
          onClose={handleLevel3DialogClose}
          incidentId={selectedIncidentId}
          isFileViewVisible={isFileUploadVisible}
          incidentDetails={selectedIncident}
          showActionTaken={showActionTaken}
          onApprove={handleLevel3DialogClose}
        />
      )}
    </Box>
  )
}

export default SafetyinchargeIncident