import React, { useState, useEffect, useContext } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  Button, 
  Select, 
  MenuItem, 
  IconButton, 
  TextField, 
  Typography, 
  FormControl, 
  InputLabel 
} from "@mui/material";
import { Close, PhotoCamera, LocationOn, Delete } from "@mui/icons-material";
import { Box } from "@mui/system";
import { createIncident, getLiveDetails } from "../../../API/IncidentAPI";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeContext } from '../../../store/ThemeContext';
import MapPicker from '../supervisor/MapPicker';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

/**
 * Helper function to get current time in "HH:MM" format
 * @returns {string} Current time string in HH:MM format
 */
const getCurrentTime = () => {
  const now = new Date();
  return now.getHours().toString().padStart(2, '0') + ":" + now.getMinutes().toString().padStart(2, '0');
};

/**
 * CreateCaseModal component for creating new incident reports
 * 
 * @param {boolean} open - Controls dialog visibility
 * @param {Function} onClose - Handler for closing the dialog
 * @param {Function} onIncidentCreated - Callback when incident is successfully created
 */
export default function CreateCaseModal({ open, onClose, onIncidentCreated }) {
  const { isDarkMode } = useContext(ThemeContext);
  
  // Schedule and vehicle data
  const [pendingSchedules, setPendingSchedules] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState("");
  const [PilotName, setPilotName] = useState("");
  const [BusNumber, setBusNumber] = useState("");
  const [Shift, setShift] = useState("");
  
  // Incident details
  const [IncidentDate, setIncidentDate] = useState(new Date().toISOString().split("T")[0]);
  const [IncidentTime, setIncidentTime] = useState(getCurrentTime());
  const [timePickerValue, setTimePickerValue] = useState(dayjs());
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  
  // UI state
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImageName, setUploadImageName] = useState("");
  const [openMap, setOpenMap] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Default map location (Mumbai coordinates)
  const defaultPosition = [19.07283, 72.88261];

  /**
   * Reset form fields when modal closes
   */
  useEffect(() => {
    if (!open) {
      setIncidentDate(new Date().toISOString().split("T")[0]);
      setIncidentTime(getCurrentTime());
      setTimePickerValue(dayjs());
      setSelectedSchedule("");
      setPilotName("");
      setBusNumber("");
      setShift("");
      setLocation("");
      setDescription("");
      setUploadImage(null);
      setUploadImageName("");
      setType("");
      setPendingSchedules([]); // Clear pendingSchedules array to avoid cached data
      setSearchText(""); // Reset search text as well
      
      // Try to get current location
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation(`${latitude}, ${longitude}`);
        },
        (error) => {
          console.error("Error fetching current location:", error);
        }
      );
    }
  }, [open]);

  /**
   * Fetch pending schedules when date changes or modal opens
   * Only fetch when modal is open and we have a valid date
   */
  useEffect(() => {
    let isMounted = true; // Used to prevent state updates after component unmounts
    
    if (open && IncidentDate) {
      setPendingSchedules([]); // Clear previous data before fetching new data
      
      getLiveDetails(IncidentDate)
        .then(response => {
          if (!isMounted) return; // Don't update state if component is unmounted
          
          if (response.result && response.data) {
            setPendingSchedules(response.data);
          } else {
            console.error("Unexpected API response structure:", response);
            setPendingSchedules([]); // Set empty array on error
          }
        })
        .catch(error => {
          if (!isMounted) return;
          
          console.error("Failed to fetch pending schedules", error);
          setPendingSchedules([]); // Set empty array on error
         
        });
    }
    
    return () => {
      isMounted = false; // Cleanup function to prevent state updates after unmount
    };
  }, [open, IncidentDate]);


  /**
   * Convert location string to coordinate array
   * @returns {Array} Coordinates [latitude, longitude]
   */
  const getInitialPosition = () => {
    if (location && location.includes(',')) {
      const parsed = location.split(',').map(coord => parseFloat(coord.trim()));
      if (!isNaN(parsed[0]) && !isNaN(parsed[1])) {
        return parsed;
      }
    }
    return defaultPosition;
  };

  /**
   * Handle time picker changes
   * @param {Object} newTime - New time value from picker
   */
  const handleTimeChange = (newTime) => {
    if (newTime) {
      setTimePickerValue(newTime);
      const formattedTime = newTime.format('HH:mm');
      setIncidentTime(formattedTime);
    }
  };

  /**
   * Handle schedule selection and autofill pilot and bus info
   * @param {Object} e - Select change event
   */
  const handleScheduleChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedSchedule(selectedValue);
    const schedule = pendingSchedules.find(item => item.Code === selectedValue);
    if (schedule) {
      setPilotName(schedule.PilotName);
      setBusNumber(schedule.BusNumber);
      setShift(schedule.Shift);
    }
  };

  /**
   * Handle image selection from file input
   * @param {Object} e - Input change event
   */
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploadImage(e.target.files[0]);
      setUploadImageName(e.target.files[0].name);
    }
  };

   /**
   * Handle closing the dialog
   * Custom close handler to ensure all states are reset
   */
   const handleClose = () => {
    // Reset all form fields
    setSelectedSchedule("");
    setPilotName("");
    setBusNumber("");
    setShift("");
    setLocation("");
    setDescription("");
    setUploadImage(null);
    setUploadImageName("");
    setType("");
    setPendingSchedules([]); // Clear pendingSchedules array to avoid cached data
    setSearchText(""); // Reset search text as well
    
    // Call the original onClose function passed as prop
    onClose();
  };

  /**
   * Handle form submission to create incident
   * @param {Object} e - Form submit event
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Validate required fields
    const missingFields = [];
    if (!selectedSchedule) missingFields.push("Schedule");
    if (!IncidentDate) missingFields.push("Incident Date");
    if (!IncidentTime) missingFields.push("Incident Time");
    if (!location) missingFields.push("Location");
    if (!description) missingFields.push("Description");
    if (!uploadImage) missingFields.push("Image");

    if (missingFields.length > 0) {
      toast.error(`Missing fields: ${missingFields.join(", ")}`);
      setIsSubmitting(false);
      return;
    }

    // Find selected schedule details
    const schedule = pendingSchedules.find(item => item.Code === selectedSchedule);
    if (!schedule) {
      toast.error("Please select a valid schedule");
      setIsSubmitting(false);
      return;
    }

    // Format time properly
    const formattedIncidentTime = IncidentTime.length === 5 ? `${IncidentTime}:00` : IncidentTime;

    // Prepare form data
    const formData = new FormData();
    formData.append("BusInformationId", String(schedule.BusInformationId));
    formData.append("DriverId", String(schedule.PilotId));
    formData.append("ScheduleId", schedule.ScheduleId);
    formData.append("IncidentDate", IncidentDate || "");
    formData.append("IncidentTime", formattedIncidentTime || "");
    formData.append("Location", location);
    formData.append("Description", description);
    formData.append("Type", type);
    formData.append("UploadImage", uploadImage);

    try {
      const res = await createIncident(formData);
      toast.success("Incident created successfully!");
      window.location.reload();
      if (onIncidentCreated && res.data) {
        onIncidentCreated(res.data);
      }
      onClose();
      // Clear form states before closing
      setPendingSchedules([]);
      handleClose(); // Use our custom close handler
    } catch (error) {
      console.error("Error creating incident", error);
      toast.error("Failed to create incident!");
      setIsSubmitting(false);
    }
  };

  /**
   * Handle description field changes with validation
   * @param {Object} e - Input change event
   */
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    setDescription(value); // Always update the value, toast error handled elsewhere
  };

  /**
   * Remove uploaded image
   */
  const handleImageRemove = () => {
    setUploadImage(null);
    setUploadImageName("");
  };

  /**
   * Filter schedules based on search text
   */
  const filteredSchedules = pendingSchedules.filter(schedule => 
    schedule.Code.toLowerCase().includes(searchText.toLowerCase())
  );

  /**
   * Handle search input change
   * @param {Object} event - Input change event
   */
  
  // const handleSearchChange = (event) => {
  //   event.stopPropagation(); // Prevent select from opening/closing
  //   setSearchText(event.target.value);
  // };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose} // Use the custom close handler
        maxWidth="lg"
        PaperProps={{
          style: {
            backgroundColor: isDarkMode ? '#1a1a1a' : '#f8f9fa',
            color: isDarkMode ? '#ffffff' : '#212529',
            borderRadius: '8px',
            maxHeight: '80vh',
            width: '90%',
            overflow: 'hidden'
          },
        }}
        fullWidth
      >
        {/* Dialog Header */}
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: isDarkMode ? "#FFFFFF" : "#212529",
            fontSize: "26px",
            padding: "16px 24px",
            fontWeight: 700,
            fontFamily: "'Segoe UI', Roboto, 'Helvetica Neue', sans-serif"
          }}
        >
          Create Case
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.6)",
              "&:hover": {
                backgroundColor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.04)",
                color: isDarkMode ? "#ffffff" : "#000000"
              }
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>

        {/* Dialog Content */}
        <DialogContent sx={{ padding: 3 }}>
          <form onSubmit={handleSubmit}>
            <Box sx={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              gap: 3
            }}>
              {/* Left Column - Basic Information */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                {/* Date Picker */}
                <TextField
                  fullWidth
                  type="date"
                  value={IncidentDate}
                  onChange={(e) => setIncidentDate(e.target.value)}
                  sx={{
                    bgcolor: isDarkMode ? "#3A3A3A" : "#ffffff",
                    borderRadius: "4px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": { border: "none" },
                      "& .MuiSvgIcon-root": { color: isDarkMode ? "white" : "white" }
                    },
                    "& input": {
                      color: isDarkMode ? "white" : "#212529",
                      padding: "8px 12px",
                    }
                  }}
                />

                {/* Time Picker */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={timePickerValue}
                    onChange={handleTimeChange}
                    ampm={true}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiOutlinedInput-root': {
                        bgcolor: isDarkMode ? '#3A3A3A' : '#ffffff',
                        color: isDarkMode ? 'white' : '#212529',
                        '& fieldset': {
                          border: 'none'
                        },
                        '&:hover': {
                          bgcolor: isDarkMode ? '#454545' : '#f8f9fa'
                        },
                        '&.Mui-focused': {
                          bgcolor: isDarkMode ? '#454545' : '#f8f9fa',
                          boxShadow: `0 0 0 2px ${isDarkMode ? '#4a6da7' : '#4a6da7'}`
                        }
                      },
                      '& .MuiPickersLayout-root': {
                        bgcolor: isDarkMode ? '#2A2A2A' : '#ffffff',
                        color: isDarkMode ? 'white' : '#212529',
                        border: isDarkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        borderRadius: '8px',
                        overflow: 'hidden'
                      },
                      '& .MuiPickersToolbar-root': {
                        bgcolor: isDarkMode ? '#1E1E1E' : '#4a6da7',
                        color: 'white',
                        padding: '16px',
                        '& .MuiTypography-root': {
                          color: 'white'
                        }
                      },
                      '& .MuiClock-root': {
                        bgcolor: isDarkMode ? '#333333' : '#f8f9fa',
                        border: isDarkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)',
                        margin: '16px',
                        '& .MuiClockNumber-root': {
                          color: isDarkMode ? '#E0E0E0' : '#212529',
                          '&.Mui-selected': {
                            bgcolor: '#4a6da7',
                            color: 'white',
                            fontWeight: 600
                          }
                        },
                        '& .MuiClock-pin': {
                          bgcolor: '#4a6da7'
                        },
                        '& .MuiClockPointer-root': {
                          bgcolor: '#4a6da7',
                          '& .MuiClockPointer-thumb': {
                            border: '2px solid #fff',
                            bgcolor: '#4a6da7',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                          }
                        }
                      },
                      '& .MuiPickersLayout-contentWrapper': {
                        bgcolor: isDarkMode ? '#2A2A2A' : '#ffffff',
                        padding: '8px'
                      },
                      '& .MuiPickersLayout-actionBar': {
                        bgcolor: isDarkMode ? '#2A2A2A' : '#ffffff',
                        padding: '8px 16px',
                        borderTop: isDarkMode ? '1px solid rgba(255,255,255,0.1)' : '1px solid rgba(0,0,0,0.1)'
                      },
                      '& .MuiButton-text': {
                        color: isDarkMode ? '#4a6da7' : '#4a6da7',
                        '&:hover': {
                          bgcolor: isDarkMode ? 'rgba(74, 109, 167, 0.1)' : 'rgba(74, 109, 167, 0.1)'
                        }
                      },
                      '& .MuiTypography-root': {
                        color: isDarkMode ? '#E0E0E0' : '#212529'
                      },
                      '& .MuiPickersToolbarText-root.Mui-selected': {
                        color: 'white'
                      },
                      '& .MuiPickersArrowSwitcher-button': {
                        color: isDarkMode ? '#E0E0E0' : '#212529',
                        '&:hover': {
                          bgcolor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.04)'
                        }
                      },
                      '& .MuiPickersToolbarButton-root': {
                        color: 'white'
                      },
                      '& .MuiDialogActions-root': {
                        padding: '16px'
                      }
                    }}
                  />
                </LocalizationProvider>

                {/* Schedule Selector */}
                <Select
                  fullWidth
                  displayEmpty
                  value={selectedSchedule}
                  onChange={handleScheduleChange}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: isDarkMode ? '#333333' : '#ffffff',
                        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.15)',
                        maxHeight: '265px',
                        width: '20%',
                        '& .MuiList-root': {
                          padding: 1,
                          width: '100%',
                          '& .MuiMenuItem-root': {
                            padding: '10px 16px',
                            color: isDarkMode ? '#ffffff' : '#212529',
                            fontSize: '14px',
                            borderBottom: isDarkMode ? '1px solid rgba(255,255,255,0.05)' : '1px solid rgba(0,0,0,0.05)',
                            '&:last-child': {
                              borderBottom: 'none'
                            },
                            '&:hover': {
                              bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.04)'
                            },
                            '&.Mui-selected': {
                              bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.06)',
                              '&:hover': {
                                bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.08)'
                              }
                            }
                          }
                        }
                      }
                    },
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    }
                  }}
                  sx={{
                    bgcolor: isDarkMode ? "#3A3A3A" : "#ffffff",
                    color: isDarkMode ? "white" : "#212529",
                    height: "44px",
                    borderRadius: "6px",
                    position: 'relative',
                    '& .MuiOutlinedInput-notchedOutline': { 
                      border: 'none' 
                    },
                    '& .MuiSelect-select': { 
                      padding: '8px 16px',
                      display: 'flex',
                      alignItems: 'center'
                    },
                    '& .MuiSelect-icon': { 
                      color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.54)"
                    },
                    '&:hover': {
                      bgcolor: isDarkMode ? "#454545" : "#f8f9fa"
                    },
                    '&.Mui-focused': {
                      bgcolor: isDarkMode ? "#454545" : "#f8f9fa",
                      boxShadow: `0 0 0 2px ${isDarkMode ? '#4a6da7' : '#4a6da7'}`
                    }
                  }}
                >
                  <MenuItem value="" disabled sx={{ display: 'none' }}>
                    Select Schedule
                  </MenuItem>
                  {filteredSchedules.length === 0 ? (
                    <MenuItem 
                      disabled 
                      sx={{
                        color: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)',
                        py: 1.5,
                        justifyContent: 'center',
                        '&.Mui-disabled': {
                          opacity: 1
                        }
                      }}
                    >
                      No buses scheduled on selected date and time
                    </MenuItem>
                  ) : (
                    filteredSchedules.map((item) => (
                      <MenuItem 
                        key={item.Code} 
                        value={item.Code}
                        sx={{
                          color: isDarkMode ? 'white' : '#212529',
                          fontWeight: selectedSchedule === item.Code ? 500 : 400,
                          py: 1.5
                        }}
                      >
                        {item.Code}
                      </MenuItem>
                    ))
                  )}
                </Select>

                {/* Auto-filled information box */}
                <Box sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2.5,
                  mt: 1,
                  p: 1.5,
                  bgcolor: isDarkMode ? "rgba(58, 58, 58, 0.4)" : "rgba(245, 245, 245, 0.8)",
                  borderRadius: "6px",
                  "& > div": {
                    color: isDarkMode ? "#BDBDBD" : "#424242",
                    fontSize: "15px",
                    display: "flex",
                    gap: 2,
                    fontFamily: "'Segoe UI', Roboto, 'Helvetica Neue', sans-serif",
                    alignItems: "center",
                    borderBottom: isDarkMode ? "1px solid rgba(255,255,255,0.05)" : "1px solid rgba(0,0,0,0.05)",
                    pb: 1.2,
                    "&:last-child": {
                      borderBottom: "none"
                    }
                  }
                }}>
                  <div>
                    <span style={{
                      fontWeight: 500,
                      minWidth: "100px",
                      color: isDarkMode ? "#9E9E9E" : "#616161"
                    }}>
                      Pilot Name:
                    </span>
                    <span style={{
                      color: isDarkMode ? "#FFFFFF" : "#000000",
                      fontWeight: 600
                    }}>
                      {PilotName || "Auto Fetch"}
                    </span>
                  </div>
                  <div>
                    <span style={{
                      fontWeight: 500,
                      minWidth: "100px",
                      color: isDarkMode ? "#9E9E9E" : "#616161"
                    }}>
                      Bus No.:
                    </span>
                    <span style={{
                      color: isDarkMode ? "#FFFFFF" : "#000000",
                      fontWeight: 600
                    }}>
                      {BusNumber || "Auto Fetch"}
                    </span>
                  </div>
                  <div>
                    <span style={{
                      fontWeight: 500,
                      minWidth: "100px",
                      color: isDarkMode ? "#9E9E9E" : "#616161"
                    }}>
                      Shift:
                    </span>
                    <span style={{
                      color: isDarkMode ? "#FFFFFF" : "#000000",
                      fontWeight: 600
                    }}>
                      {Shift || "Auto Fetch"}
                    </span>
                  </div>
                </Box>
              </Box>

              {/* Right Column - Incident Details */}
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 1 }}>
                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2, alignItems: "start" }}>
                  {/* Photo upload section */}
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Button
                      variant="contained"
                      component="label"
                      startIcon={<PhotoCamera />}
                      sx={{
                        bgcolor: isDarkMode ? "#4287f5" : "#4287f5",
                        color: "white",
                        textTransform: "none",
                        width: "100%",
                        "&:hover": { bgcolor: isDarkMode ? "#357ae8" : "#4287f5" }
                      }}
                    >
                      Upload Case Photo
                      <input type="file" hidden onChange={handleImageChange} accept="image/*" />
                    </Button>
                    {uploadImageName && (
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 0.5 }}>
                        <Typography
                          variant="body2"
                          noWrap
                          sx={{
                            color: isDarkMode ? "white" : "black",
                            maxWidth: "calc(100% - 30px)",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {uploadImageName}
                        </Typography>
                        <IconButton 
                          onClick={handleImageRemove} 
                          size="small" 
                          sx={{ color: isDarkMode ? "white" : "black" }}
                          aria-label="Remove image"
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    )}
                  </Box>

                  {/* Location field */}
                  <TextField
                    fullWidth
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    sx={{
                      bgcolor: isDarkMode ? "#3A3A3A" : "#ffffff",
                      
                      height: "40px",
                      "& .MuiOutlinedInput-root": { 
                        height: "40px",
                        "& fieldset": { border: "none" } 
                      },
                      "& input": { color: isDarkMode ? "white" : "black" },
                      "& label": { 
                        color: isDarkMode ? "#9E9E9E" : "#212529",
                        transform: "translate(14px, 10px) scale(1)",  // Adjust label position
                        "&.Mui-focused, &.MuiFormLabel-filled": {
                          transform: "translate(14px, -9px) scale(0.75)"  // Adjust focused/filled label position
                        }
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => setOpenMap(true)}
                          sx={{
                            color: isDarkMode ? "white" : "black",
                            "&:hover": {
                              backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.08)" : "rgba(0, 0, 0, 0.04)"
                            }
                          }}
                          aria-label="Open map picker"
                        >
                          <LocationOn />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>

                {/* Incident Type Selector */}
                <FormControl fullWidth margin="dense" variant="outlined" sx={{ 
                  position: 'relative',
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isDarkMode ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.23)'
                  },
                  '& .MuiInputLabel-outlined': {
                    backgroundColor: isDarkMode ? '#3A3A3A' : '#ffffff',
                    paddingLeft: '5px',
                    paddingRight: '5px'
                  }
                }}>
                  <InputLabel sx={{ 
                    color: isDarkMode ? '#aaa' : '#000',
                  }}>Type</InputLabel>
                  <Select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    sx={{
                      bgcolor: isDarkMode ? '#3A3A3A' : '#ffffff',
                      '& .MuiInputBase-root': {
                        backgroundColor: isDarkMode ? '#333' : '#fff',
                        color: isDarkMode ? '#fff' : '#000',
                      }
                    }}
                  >
                    <MenuItem value="Incident">Incident</MenuItem>
                    <MenuItem value="Accident">Accident</MenuItem>
                  </Select>
                </FormControl>

                {/* Description field - full width and taller */}
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  label="Description"
                  placeholder="Describe the incident..."
                  value={description}
                  onChange={handleDescriptionChange}
                  sx={{
                    flex: 1,
                    "& .MuiOutlinedInput-root": {
                      bgcolor: isDarkMode ? "#3A3A3A" : "#ffffff",
                      color: isDarkMode ? "white" : "black",
                      "& fieldset": { border: "none" }
                    },
                    "& .MuiInputLabel-root": {
                      color: isDarkMode ? "#9E9E9E" : "#212529",
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": {
                        color: isDarkMode ? "#9E9E9E" : "#6c757d",
                        opacity: 1
                      }
                    }
                  }}
                />
              </Box>
            </Box>

            {/* Submit Button */}
            <Box sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 2,
              mt: 3,
              "& .MuiButton-root": {
                textTransform: "none",
                padding: "6px 24px",
                borderRadius: "4px"
              }
            }}>
              <Button
                type="submit"
                disabled={isSubmitting}
                sx={{
                  bgcolor: "#4287f5",
                  color: "white",
                  "&:hover": { bgcolor: "#357ae8" },
                  "&.Mui-disabled": {
                    bgcolor: isDarkMode ? "rgba(66, 135, 245, 0.5)" : "rgba(66, 135, 245, 0.7)",
                    color: "white"
                  }
                }}
              >
                {isSubmitting ? (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '8px' }}>Submitting</span>
                    <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div>
                  </Box>
                ) : "Submit"}
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>

      {/* Map Picker Modal */}
      <MapPicker
        open={openMap}
        onClose={() => setOpenMap(false)}
        initialPosition={getInitialPosition()}
        onSelect={(newPosition) => setLocation(`${newPosition[0]}, ${newPosition[1]}`)}
      />
    </>
  );
}