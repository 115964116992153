//Used Material UI for everything

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { styled } from "@mui/system";

import Typography from "@mui/material/Typography";
import { Box, Paper, FormControlLabel, Switch, TextField, CircularProgress, Button, IconButton, Tooltip, Collapse } from "@mui/material";
import "../../styles/line.css";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import buslogo from "../../assets/Bus.svg";
import charging from "../../assets/Charging.svg";
import Routes from "../../assets/Routes.svg";
import {
  fetchDashboardDetails,
  fetchBusList,
  fetchRouteList,
  fetchChargersList,
} from "../../API/DashboardAPI";
import Testmap from "../Maps/Map";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import axios from "axios"; // Import axios for making HTTP requests
import LiveBuseslist from "../Buses/LiveBuseslist";
import LiveChargerlist from "../Chargers/LiveChargerlist";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import ImageLoader from "../../assets/Transvolticon 2 (1).gif";
import { LoginContext } from "../../store/LoginContext";
import BlurredBox from "../../components/Layout/BlurredBox";
import caution from "../../assets/caution.svg";
import { ThemeContext } from "../../store/ThemeContext";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TimelineIcon from "@mui/icons-material/Timeline";
import BatteryChargingFullIcon from "@mui/icons-material/BatteryChargingFull";
import DirectionsBusFilledIcon from '@mui/icons-material/DirectionsBusFilled';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import WarningIcon from '@mui/icons-material/Warning';
import BoltIcon from '@mui/icons-material/Bolt';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DiamondIcon from '@mui/icons-material/Diamond';

const user = JSON.parse(sessionStorage.getItem("user"));

const Item = styled(Paper)(({ theme, isDarkMode }) => ({
  textAlign: "center",
  color: isDarkMode ? "#fff" : "#000",
  backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
  height: "70vh",
  borderRadius: 0,
  border: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
  overflowY: "auto",
}));

const MainContainer = styled("div")(({ isDarkMode }) => ({
  width: "calc(100% - 72px)",
  minHeight: "calc(100vh - 55px)",
  backgroundColor: isDarkMode ? "#1a1a1a" : "#f5f5f5",
  marginLeft: "72px",
  marginTop: "55px",
  padding: "16px",
  transition: "all 0.3s ease",
}));

const ContentWrapper = styled(Box)(({ isDarkMode }) => ({
  backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
  minHeight: "calc(100vh - 55px)", // Subtract header height
  paddingTop: "01px", // Account for fixed header
  transition: "all 0.3s ease",
}));

const DashboardContent = styled(Box)(({ isDarkMode }) => ({
  padding: "16px",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));

const SmallCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fontSize: 15, // Adjust the fontSize to make the checkbox smaller
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const StyledCard = styled(Card)(({ isDarkMode }) => ({
  display: "flex",
  flexDirection: "row",
  width: "50%",
  backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
  borderRadius: "8px",
  justifyContent: "space-between",
  alignItems: "center",
  height: 60,
  marginTop: "10px",
  marginBottom: "10px",
  cursor: "pointer",
  border: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
  transition: "all 0.3s ease",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: isDarkMode 
      ? "0 4px 12px rgba(0, 0, 0, 0.4)"
      : "0 4px 12px rgba(0, 0, 0, 0.1)",
  }
}));

const BusListContainer = styled(Box)(({ isDarkMode }) => ({
  backgroundColor: isDarkMode ? "#262626" : "#ffffff",
  borderRadius: "8px",
  border: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
  height: "calc(100vh - 230px)",
  "& .list-content": {
    backgroundColor: isDarkMode ? "#333" : "#ffffff",
  }
}));

const ListItemWrapper = styled(Box)(({ isDarkMode }) => ({
  padding: "12px 16px",
  borderBottom: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
  backgroundColor: isDarkMode ? "#333" : "#ffffff",
  color: isDarkMode ? "#fff" : "#000",
  cursor: "pointer",
  margin: "0 0 8px 0",
  borderRadius: "4px",
  boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  "&:hover": {
    backgroundColor: isDarkMode ? "#404040" : "#f5f5f5",
    boxShadow: isDarkMode ? "none" : "0 4px 6px rgba(0,0,0,0.1)"
  }
}));

const SearchBox = styled(Box)(({ isDarkMode }) => ({
  display: "flex",
  alignItems: "center",
  padding: "8px 16px",
  borderBottom: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
}));

const StatCard = styled(Box)(({ isDarkMode, color }) => ({
  backgroundColor: color || (isDarkMode ? "#262626" : "#ffffff"),
  borderRadius: "8px",
  padding: "12px 16px",
  height: "80px", // Fixed height for consistency
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "all 0.3s ease",
  border: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  }
}));

const StatsValue = styled(Typography)({
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: 1.2,
  marginTop: "4px",
});

const StatsLabel = styled(Typography)({
  fontSize: "12px",
  opacity: 0.8,
});

const StatsIcon = styled("img")({
  width: "32px",
  height: "32px",
  objectFit: "contain",
});

const FilterHeader = styled(Box)(({ isDarkMode }) => ({
  padding: "12px 16px",
  borderBottom: "1px solid",
  borderColor: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
  backgroundColor: isDarkMode ? "#1C1C1C" : "#ffffff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const FilterButton = styled(Button)(({ isDarkMode, active }) => ({
  backgroundColor: active 
    ? (isDarkMode ? "#333" : "#fff") 
    : (isDarkMode ? "transparent" : "transparent"),
  color: isDarkMode ? "#fff" : "#000",
  padding: "6px 16px",
  minWidth: "80px",
  textTransform: "none",
  borderRadius: "4px",
  border: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
  '&:hover': {
    backgroundColor: isDarkMode ? "#404040" : "#e0e0e0",
  }
}));

const MetricCard = styled(Box)(({ isDarkMode, color }) => ({
  backgroundColor: isDarkMode ? "#1C1C1C" : "#fff",
  borderRadius: "12px",
  padding: "16px 20px",
  border: `1px solid ${color}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "90px",
  boxShadow: isDarkMode 
    ? `0 2px 8px ${color}15`
    : "0 2px 4px rgba(0, 0, 0, 0.05)",
  transition: "all 0.3s ease",
}));

const MetricLabel = styled(Typography)(({ isDarkMode }) => ({
  fontSize: "16px",
  fontWeight: 500,
  marginBottom: "8px",
  color: isDarkMode ? "#999" : "#666",
  display: "flex",
  alignItems: "center",
  gap: "8px",
}));

const MetricValue = styled(Typography)(({ isDarkMode }) => ({
  fontSize: "24px",
  fontWeight: 600,
  color: isDarkMode ? "#fff" : "#333",
}));

const TillDate = styled(Typography)(({ isDarkMode }) => ({
  fontSize: "12px",
  color: isDarkMode ? "#999" : "#666",
  position: "absolute",
  top: "16px",
  right: "20px",
}));

// Add IconWrapper styled component
const IconWrapper = styled(Box)(({ color }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: color + "15", // Adding transparency to the background
  marginRight: "12px",
  "& svg": {
    color: color,
    fontSize: "24px"
  }
}));

const Dashboard = () => {
  const { logoutHandle } = useContext(LoginContext);
  const { isDarkMode } = useContext(ThemeContext);
  const [busList, setBusList] = useState([]); // State for bus list
  const [chargersList, setChargersList] = useState([]); // State for bus list
  const [dashboard, setDashboard] = useState(null); // State for dashboard data
  const [selectedOption, setSelectedOption] = useState("Buses"); // State for selected option
  const [routeList, setRouteList] = useState(null); // State for route list
  const [showRoutes, setShowRoutes] = React.useState(false); // State for showing routes
  const [showStops, setShowStops] = React.useState(false); // State for showing stops
  const [selectedRoutes, setSelectedRoutes] = useState([]); // State for selected routes
  const [selectedBuses, setSelectedBuses] = useState([]); // State for selected buses
  const [unselectedBuses, setUnSelectedBuses] = useState([]); // State for selected buses
  const [selectAllBuses, setSelectAllBuses] = useState(false);
  const [selectAllChargers, setSelectAllChargers] = useState(false);
  const [selectedChargers, setSelectedChargers] = useState([]);
  const [allBuses, setAllBuses] = useState([]);
  const [isOn, setIsOn] = useState(false);
  const [VehicleNumber, setVehicleNumber] = useState("");
  const [chargerName, setChargerName] = useState("");
  const [busloading, setBusloading] = useState(false);
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [page, setPage] = useState(1);
  const [totalBusPage, setTotalBusPage] = useState(5);
  const [hasMore, setHasMore] = useState(true);
  const [pageLoading,setPageLoading] = useState(false)
  const buslistRef = useRef();
  const [activeFilter, setActiveFilter] = useState("Buses");
  const [chargerList, setChargerList] = useState([]);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [chargerSearch, setChargerSearch] = useState("");
  const [isLoadingBuses, setIsLoadingBuses] = useState(false);
  const [isLoadingChargers, setIsLoadingChargers] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(null);
  const [busCoordinates, setBusCoordinates] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [expandedBus, setExpandedBus] = useState(null);
  const [focusedBus, setFocusedBus] = useState(null);

  const navigate = useNavigate();

  // Initialize selectedBuses with all buses when busList is loaded
  useEffect(() => {
    if (busList?.length > 0) {
      const allBusNames = busList.map(bus => bus.VehicleNumber || bus.BusCode);
      setSelectedBuses(allBusNames);
      setSelectAllBuses(true);
    }
  }, [busList]); // Dependency on busList

  const hanleOnSelectAllBusesChanges = (event) => {
    setSelectAllBuses(event.target.checked);
    if (event.target.checked) {
      // Select all buses from the current busList
      const allBusNames = busList.map(bus => bus.VehicleNumber || bus.BusCode);
      setSelectedBuses(allBusNames);
    } else {
      // Deselect all buses
      setSelectedBuses([]);
    }
  };

  const handleBusSelection = (busName) => {
    setSelectedBuses(prev => {
      if (prev.includes(busName)) {
        const newSelection = prev.filter(name => name !== busName);
        setSelectAllBuses(false); // Uncheck "Select All" when deselecting any bus
        return newSelection;
      } else {
        const newSelection = [...prev, busName];
        // Only set selectAllBuses to true if all buses are selected
        setSelectAllBuses(newSelection.length === busList.length);
        return newSelection;
      }
    });
  };


  const handleOpen = () => {
    navigate("/dashboard/DeployedBuses");
  };

  const handleRoutesOpen = () => {
    navigate("/dashboard/Routes");
  };

  const handleChargingOpen = () => {
    navigate("/dashboard/Charging_Stations");
  };

  const handleShowRouteChange = (event) => {
    setShowRoutes(event.target.checked); // Handle show routes change
  };

  const handleStopsChange = (event) => {
    setShowStops(event.target.checked); // Handle show stops change
  };

  const handleRoutesChange = (event) => {
    setSelectedRoutes(event.target.value); // Handle routes change
  };

  const handleBusesChange = (event) => {
    setSelectedBuses(event.target.value); // Handle buses change
    const AselectedBuses = selectedBuses;
    AselectedBuses.push(event.target.value);

    const ab = busList.filter((x) => !AselectedBuses.includes(x.BusCode));
    const abc = ab.map((e) => e.BusCode);

    setUnSelectedBuses(abc);
  };

  // Fetch dashboard details
  useEffect(() => {
    fetchDashboardDetails()
      .then((data) => {
        setDashboard(data);
      })
      .catch((error) => {
        console.error("Failed to fetch dashboard details:", error);

        if (error.status === 401) {
          logoutHandle();
        }
        // navigate("/");
      });
  }, []);

  const fetchBuslistDetails = async (date, vehicleNumber, page) => {
    setIsLoadingBuses(true);
    setBusloading(true);

    try {
      const data = await fetchBusList(date, vehicleNumber);

      if (data?.data?.buses_list && Array.isArray(data.data.buses_list)) {
        setBusList(data.data.buses_list);

        const busNames = data.data.buses_list.map((e) => e.name);
        setSelectedBuses(busNames);
        setAllBuses(busNames);
      } else {
        console.error("Unexpected data structure", data);
      }
    } catch (error) {
      console.error("Failed to fetch bus details:", error);
       if (error.status === 401 || error.status === 403) {
      logoutHandle();
    }
      // Optionally update UI state to reflect the error
    } finally {
      // Ensure loading state is set to false in all scenarios
      setBusloading(false);
      setIsLoadingBuses(false);
    }
  }
  
  useEffect(() => {
    if (selectedOption === "Buses") {
     let timer=null
      if(page===1){ 
       timer = setTimeout(() => {
        fetchBuslistDetails(
          dayjs(new Date()).format("YYYY-MM-DD"),
          VehicleNumber,
          page
        );
      }, 1000);

    }else{
      fetchBuslistDetails(
        dayjs(new Date()).format("YYYY-MM-DD"),
        VehicleNumber,
        page
      );
    }
      return () => clearTimeout(timer);
    }
  }, [selectedOption, VehicleNumber, page]);

  // Fetch route list
  useEffect(() => {
    if (selectedOption === "Routes") {
      fetchRouteList(dayjs(new Date()).format("YYYY-MM-DD"))
        .then((data) => {
          setRouteList(data.data.buses_list);
        })
        .catch((error) => {
          console.error("Failed to fetch Route details:", error);
          if (error.status === 401) {
            logoutHandle();
          }
        });
    }
  }, [selectedOption]);

  const fetchChargersListData = async (chargerName) => {
    try {
      const data = await fetchChargersList(
        dayjs(new Date()).format("YYYY-MM-DD"),
        "Total",
        chargerName
      );
      setChargersList(data.data.Charger_list);
      setSelectedChargers(data.data.Charger_list);
    } catch (error) {
      console.error("Failed to fetch Chargers details:", error);
      if (error.status === 401) {
        logoutHandle();
      }
    }
  };

  // Fetch Chargers list
  useEffect(() => {
    if (selectedOption === "Chargers") {
      fetchChargersListData(chargerName);
    }
  }, [selectedOption, chargerName]);

  const hanleOnSelectAllChargersChanges = () => {
    const newSelectAllBuses = !selectAllChargers;
    setSelectAllChargers(!selectAllChargers);

    const data = selectAllChargers !== true ? chargersList : [];
    setSelectedChargers(data);
  };

  useEffect(() => {
    if (chargersList && selectedChargers) {
      setSelectAllChargers(
        chargersList.length > 0 &&
          chargersList.length === selectedChargers.length
      );
    }
  }, [chargersList, selectedChargers]);

  const handleToggle = () => {
    setIsOn((prevState) => !prevState);
  };

  // Add function to fetch charger list
  const fetchChargerList = async () => {
    setIsLoadingChargers(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/charger/list`);
      setChargerList(response.data.data || []);
    } catch (error) {
      console.error("Error fetching charger list:", error);
      if (error.status === 401) {
        logoutHandle();
      }
    } finally {
      setIsLoadingChargers(false);
    }
  };

  // Handle filter change
  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    if (filter === "Chargers") {
      fetchChargerList();
    }
  };

  // Charger list item component
  const ChargerListItem = ({ charger, isDarkMode }) => (
    <Box
      sx={{
        p: 1.5,
        borderBottom: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
        backgroundColor: isDarkMode ? "#333" : "#fff",
        "&:hover": {
          backgroundColor: isDarkMode ? "#404040" : "#f5f5f5",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ color: isDarkMode ? "#fff" : "#000" }}>
          {charger.name || charger.id}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Tooltip title="Location">
            <IconButton
              size="small"
              sx={{ color: isDarkMode ? "#666" : "#999" }}
            >
              <LocationOnIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Checkbox
            size="small"
            checked={selectedChargers.includes(charger.id)}
            onChange={() => handleChargerSelection(charger.id)}
            sx={{
              color: isDarkMode ? "#666" : "#999",
              "&.Mui-checked": {
                color: "#007FFF",
              },
            }}
          />
        </Box>
      </Box>
      <Typography variant="caption" sx={{ color: isDarkMode ? "#999" : "#666" }}>
        Status: {charger.status || "Unknown"}
      </Typography>
    </Box>
  );

  // Add charger selection handlers
  const handleChargerSelection = (chargerId) => {
    setSelectedChargers(prev => {
      if (prev.includes(chargerId)) {
        return prev.filter(id => id !== chargerId);
      } else {
        return [...prev, chargerId];
      }
    });
  };

  const handleSelectAllChargers = (event) => {
    setSelectAllChargers(event.target.checked);
    if (event.target.checked) {
      const allChargerIds = chargerList.map(charger => charger.id);
      setSelectedChargers(allChargerIds);
    } else {
      setSelectedChargers([]);
    }
  };

  // Update the search handler
  const handleSearch = (value) => {
    if (activeFilter === "Buses") {
      setVehicleNumber(value);
      
      // Clear any existing timeout
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      // Set new timeout to delay API call
      const newTimeout = setTimeout(() => {
        // Call the API with updated search params
        fetchBusList(
          dayjs(new Date()).format("YYYY-MM-DD"),
          value, // vehicle number search term
          10, // or your dataPerPage state
          1 // reset to first page when searching
        ).then((response) => {
          setBusList(response.data || []); // Update your bus list state
        }).catch((error) => {
          console.error("Error searching buses:", error);
          // Handle error appropriately
        });
      }, 500); // 500ms delay

      setSearchTimeout(newTimeout);
    } else {
      setChargerSearch(value);
      // Handle charger search if needed
    }
  };

  return (
    <MainContainer isDarkMode={isDarkMode}>
      {/* Top Stats Row */}
      <Grid container spacing={2} sx={{ marginBottom: 2 }}>
        <Grid item xs={12} md={4}>
          <StatCard isDarkMode={isDarkMode}
          clickable={true}
          onClick={handleOpen}
          sx={{
            '&:hover': {
              backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
               cursor: 'pointer'
            }
          }}>
            <Box>
              <StatsLabel sx={{ color: isDarkMode ? "#999" : "#666" }}>
                Buses Deployed
              </StatsLabel>
              <StatsValue sx={{ color: isDarkMode ? "#fff" : "#000" }}>
                {dashboard?.data.Bus_count}
              </StatsValue>
            </Box>
            <StatsIcon 
      src={buslogo} 
      alt="Bus" 
      sx={{ 
        filter: "invert(39%) sepia(61%) saturate(2882%) hue-rotate(337deg) brightness(100%) contrast(108%)",
        width: "40px",
        height: "40px"
      }} 
    />
          </StatCard>
        </Grid>

        <Grid item xs={12} md={4}>
        <StatCard 
      isDarkMode={isDarkMode}
      clickable={true}
      onClick={handleRoutesOpen}
      sx={{
        '&:hover': {
          backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
          cursor: 'pointer'
        }
      }}
    >
      <Box>
        <StatsLabel sx={{ color: isDarkMode ? "#999" : "#666" }}>
          Routes
        </StatsLabel>
        <StatsValue sx={{ color: isDarkMode ? "#fff" : "#000" }}>
          {dashboard?.data.route_count}
        </StatsValue>
      </Box>
      <StatsIcon 
        src={Routes} 
        alt="Routes" 
        sx={{ 
          filter: "invert(65%) sepia(59%) saturate(4937%) hue-rotate(359deg) brightness(102%) contrast(104%)",
          width: "40px",
          height: "40px"
        }} 
      />
    </StatCard>
        </Grid>

        <Grid item xs={12} md={4}>
        <StatCard 
      isDarkMode={isDarkMode}
      clickable={true}
      onClick={handleChargingOpen}
      sx={{
        '&:hover': {
          backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
          cursor: 'pointer'
        }
      }}
    >
      <Box>
        <StatsLabel sx={{ color: isDarkMode ? "#999" : "#666" }}>
          Charging Stations
        </StatsLabel>
        <StatsValue sx={{ color: isDarkMode ? "#fff" : "#000" }}>
          {dashboard?.data.Chargers}
        </StatsValue>
      </Box>
      <StatsIcon 
        src={charging} 
        alt="Charging" 
        sx={{ 
          filter: "invert(56%) sepia(75%) saturate(1647%) hue-rotate(101deg) brightness(95%) contrast(101%)",
          width: "40px",
          height: "40px"
        }} 
      />
    </StatCard>
        </Grid>
      </Grid>

      {/* Metrics Cards Row */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <MetricCard isDarkMode={isDarkMode} color="#007FFF">
            <Box sx={{ position: "relative", width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconWrapper color="#007FFF">
                  <DirectionsBusFilledIcon />
                </IconWrapper>
                <Box>
                  <MetricLabel isDarkMode={isDarkMode}>Trips</MetricLabel>
                  <MetricValue isDarkMode={isDarkMode}>
                    {dashboard?.data.TotalTrips}
                  </MetricValue>
                </Box>
              </Box>
            </Box>
          </MetricCard>
        </Grid>

        <Grid item xs={12} md={3}>
          <MetricCard isDarkMode={isDarkMode} color="#FF4842">
            <Box sx={{ position: "relative", width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconWrapper color="#FF4842">
                  <AccessTimeFilledIcon />
                </IconWrapper>
                <Box>
                  <MetricLabel isDarkMode={isDarkMode}>Operation Hours</MetricLabel>
                  <MetricValue isDarkMode={isDarkMode}>
                    {dashboard?.data.OperationalHour}
                  </MetricValue>
                </Box>
              </Box>
            </Box>
          </MetricCard>
        </Grid>

        <Grid item xs={12} md={3}>
          <MetricCard isDarkMode={isDarkMode} color="#FFC107">
            <Box sx={{ position: "relative", width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconWrapper color="#FFC107">
                  <TimelineIcon />
                </IconWrapper>
                <Box>
                  <MetricLabel isDarkMode={isDarkMode}>Kilometer Run</MetricLabel>
                  <MetricValue isDarkMode={isDarkMode}>
                    {dashboard?.data.DistanceBytrip_in_Km}
                  </MetricValue>
                </Box>
              </Box>
            </Box>
          </MetricCard>
        </Grid>

        <Grid item xs={12} md={3}>
          <MetricCard isDarkMode={isDarkMode} color="#00AB55">
            <Box sx={{ position: "relative", width: "100%" }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconWrapper color="#00AB55">
                  <BatteryChargingFullIcon />
                </IconWrapper>
                <Box>
                  <MetricLabel isDarkMode={isDarkMode}>Charging Hours</MetricLabel>
                  <MetricValue isDarkMode={isDarkMode}>
                    {dashboard?.data.charging_hours} KWH
                  </MetricValue>
                </Box>
              </Box>
            </Box>
          </MetricCard>
        </Grid>
      </Grid>

      {/* Bus List and Map Section */}
      <Grid container spacing={2} mt={0.3}>
        <Grid item xs={10} md={3}>
          <BusListContainer isDarkMode={isDarkMode}>
            <FilterHeader isDarkMode={isDarkMode}>
              <Box sx={{ display: "flex", gap: 1 }}>
                <FilterButton
                  isDarkMode={isDarkMode}
                  active={activeFilter === "Buses"}
                  onClick={() => handleFilterChange("Buses")}
                >
                  Buses
                </FilterButton>
                <FilterButton
                  isDarkMode={isDarkMode}
                  active={activeFilter === "Chargers"}
                  onClick={() => handleFilterChange("Chargers")}
                >
                  Chargers
                </FilterButton>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={selectAllBuses}
                      onChange={hanleOnSelectAllBusesChanges}
                      size="small"
                      sx={{ 
                        color: isDarkMode ? "#666" : "#999",
                        "&.Mui-checked": {
                          color: "#007FFF",
                        },
                      }}
                    />
                  }
                  label="All"
                  sx={{ 
                    margin: 0,
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px",
                      color: isDarkMode ? "#fff" : "#000",
                    },
                  }}
                />
              </Box>
            </FilterHeader>

            <Box sx={{ p: 2 }}>
              <TextField
                fullWidth
                size="small"
                placeholder={`Search ${activeFilter}`}
                variant="outlined"
                value={activeFilter === "Buses" ? VehicleNumber : chargerSearch}
                onChange={(e) => handleSearch(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: isDarkMode ? "#333" : "#fff",
                    color: isDarkMode ? "#fff" : "#000",
                    "& fieldset": {
                      borderColor: isDarkMode ? "rgba(255, 255, 255, 0.23)" : "rgba(0, 0, 0, 0.23)",
                    },
                    "&:hover fieldset": {
                      borderColor: isDarkMode ? "rgba(255, 255, 255, 0.4)" : "rgba(0, 0, 0, 0.4)",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#007FFF",
                    },
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: isDarkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)",
                    opacity: 1,
                  },
                }}
                InputProps={{
                  sx: {
                    color: isDarkMode ? "#fff" : "#000",
                  }
                }}
              />
            </Box>

            <Box sx={{ 
              height: "calc(100% - 120px)",
              overflowY: "auto",
            }}>
              {(activeFilter === "Buses" && isLoadingBuses) || 
               (activeFilter === "Chargers" && isLoadingChargers) ? (
                <Box sx={{ 
                  display: "flex", 
                  justifyContent: "center", 
                  alignItems: "center",
                  height: "100%",
                  p: 3 
                }}>
                  <CircularProgress size={24} />
                </Box>
              ) : (
                activeFilter === "Buses" ? (
                  busList?.length > 0 ? (
                    busList.map((bus, index) => (
                      <ListItemWrapper 
                        isDarkMode={isDarkMode} 
                        key={index}
                        onClick={() => {
                          // Toggle expanded state when clicked
                          if (expandedBus === (bus.VehicleNumber || bus.BusCode)) {
                            setExpandedBus(null);
                          } else {
                            setExpandedBus(bus.VehicleNumber || bus.BusCode);
                          }
                          
                          // Set the focused bus for the map
                          const busId = bus.VehicleNumber || bus.BusCode;
                          setFocusedBus(busId);
                          
                          // Ensure the bus is selected in the list
                          if (!selectedBuses.includes(busId)) {
                            handleBusSelection(busId);
                          }
                        }}
                        sx={{
                          borderLeft: bus.Status === "Not Scheduled" ? '4px solid #f56565' : 
                                     (bus.Status === "Active" || bus.Status === "On Route") ? '4px solid #10B981' : 
                                     '4px solid #FCD34D',
                          position: 'relative',
                          overflow: 'hidden',
                          transition: 'all 0.2s ease',
                          transform: 'translateZ(0)',
                          backgroundColor: isDarkMode ? "#333" : 
                                          (bus.Status === "Not Scheduled" ? 'rgba(255, 235, 235, 0.5)' : "#ffffff"),
                          '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: isDarkMode ? "none" : "0 4px 6px rgba(0,0,0,0.1)"
                          }
                        }}
                      >
                        <Box sx={{ 
                          display: "flex", 
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: 'relative',
                          zIndex: 1
                        }}>
                          <Box>
                            <Typography sx={{ 
                              color: isDarkMode ? "#fff" : "#000",
                              fontWeight: 500 
                            }}>
                              {bus.VehicleNumber || bus.BusCode}
                            </Typography>
                            <Typography variant="caption" sx={{ 
                              color: bus.Status === "Not Scheduled" ? (isDarkMode ? '#ff8a8a' : '#f56565') : (isDarkMode ? "#999" : "#666"),
                              display: "block"
                            }}>
                              Status: {bus.Status || "Unknown"}
                            </Typography>
                            {bus.LastConnected && (
                              <Typography variant="caption" sx={{ 
                                color: isDarkMode ? "#999" : "#666",
                                display: "block"
                              }}>
                                Last Connected: {bus.LastConnected}
                              </Typography>
                            )}
                          </Box>
                          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                            <Box
                              sx={{
                                position: 'relative',
                                width: 35,
                                height: 35,
                              }}
                            >
                              {/* Get percentage from bus data or default to 57 */}
                              {(() => {
                                const stateOfCharge = bus.stateOfCharge || 57; // Replace with actual percentage from your data
                                const isLowPercentage = stateOfCharge <= 20;
                                const progressColor = isLowPercentage ? '#f56565' : '#10B981';
                                
                                return (
                                  <>
                                    <CircularProgress
                                      variant="determinate"
                                      value={stateOfCharge}
                                      size={35}
                                      sx={{
                                        color: progressColor,
                                        opacity: 0.3,
                                      }}
                                    />
                                    <CircularProgress
                                      variant="determinate"
                                      value={stateOfCharge}
                                      size={35}
                                      sx={{
                                        color: progressColor,
                                        position: 'absolute',
                                        left: 0,
                                        opacity: 0.8,
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        bottom: 0,
                                        right: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <Typography
                                        variant="caption"
                                        component="div"
                                        sx={{ 
                                          color: progressColor,
                                          fontWeight: 'bold',
                                          fontSize: '11px'
                                        }}
                                      >
                                        {stateOfCharge}%
                                      </Typography>
                                    </Box>
                                  </>
                                );
                              })()}
                            </Box>
                            <Checkbox
                              size="small"
                              checked={selectedBuses.includes(bus.VehicleNumber || bus.BusCode)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleBusSelection(bus.VehicleNumber || bus.BusCode);
                              }}
                              onClick={(e) => e.stopPropagation()}
                              sx={{
                                color: isDarkMode ? "#666" : "#999",
                                "&.Mui-checked": {
                                  color: "#007FFF",
                                },
                              }}
                            />
                          </Box>
                        </Box>
                        
                        {/* Expandable details section */}
                        <Collapse in={expandedBus === (bus.VehicleNumber || bus.BusCode)}>
                          <Box 
                            sx={{ 
                              mt: 2, 
                              pt: 2, 
                              borderTop: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
                              display: 'grid',
                              gridTemplateColumns: 'repeat(2, 1fr)',
                              gap: 2
                            }}
                          >
                            <DetailItem 
                              label="Morning Schedules" 
                              value={bus.MorningScheduleCodes || ""} 
                              isDarkMode={isDarkMode} 
                            />
                            <DetailItem 
                              label="Evening Schedules" 
                              value={bus.EveningScheduleCodes || ""} 
                              isDarkMode={isDarkMode} 
                            />
                            {/* {bus.TotalKmRunDay && ( */}
                              <DetailItem 
                                label="Total Km Run Today" 
                                value={bus.TotalKmRunDay} 
                                isDarkMode={isDarkMode} 
                              />
                            {/* )} */}
                            {/* {bus.Charging_cycle && ( */}
                              <DetailItem 
                                label="Charging Cycle" 
                                value={bus.Charging_cycle} 
                                isDarkMode={isDarkMode} 
                              />
                            {/* )} */}
                            {/* {bus.totalEnergyDay_KwH && ( */}
                              <DetailItem 
                                label="Total Energy Consumed Today (KwH)" 
                                value={bus.totalEnergyDay_KwH} 
                                isDarkMode={isDarkMode} 
                              />
                            {/* )} */}
                            {/* {bus.TotalEnergyConsumed_kwH && ( */}
                              <DetailItem 
                                label="Total Energy Consumed (KwH)" 
                                value={bus.TotalEnergyConsumed_kwH} 
                                isDarkMode={isDarkMode} 
                              />
                            {/* )} */}
                            {/* {bus.TotalKm && ( */}
                              <DetailItem 
                                label="Total Km Run" 
                                value={bus.TotalKm} 
                                isDarkMode={isDarkMode} 
                              />
                            {/* )} */}
              
                          </Box>
                        </Collapse>
                      </ListItemWrapper>
                    ))
                  ) : (
                    <Box sx={{ 
                      display: "flex", 
                      justifyContent: "center", 
                      alignItems: "center",
                      height: "100%",
                      color: isDarkMode ? "#fff" : "#000"
                    }}>
                      No buses found
                    </Box>
                  )
                ) : (
                  chargerList?.length > 0 ? (
                    chargerList.map((charger, index) => (
                      <ListItemWrapper isDarkMode={isDarkMode} key={index}>
                        <Box sx={{ 
                          display: "flex", 
                          justifyContent: "space-between",
                          alignItems: "center" 
                        }}>
                          <Typography sx={{ 
                            color: isDarkMode ? "#fff" : "#000",
                            fontWeight: 500
                          }}>
                            {charger.name || charger.id}
                          </Typography>
                          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                            <IconButton
                              size="small"
                              sx={{ 
                                color: isDarkMode ? "#666" : "#999",
                                "&:hover": {
                                  backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"
                                }
                              }}
                            >
                              <LocationOnIcon fontSize="small" />
                            </IconButton>
                            <Checkbox
                              size="small"
                              checked={selectedChargers.includes(charger.id)}
                              onChange={() => handleChargerSelection(charger.id)}
                              sx={{
                                color: isDarkMode ? "#666" : "#999",
                                "&.Mui-checked": {
                                  color: "#007FFF",
                                },
                              }}
                            />
                          </Box>
                        </Box>
                        <Typography variant="caption" sx={{ 
                          color: isDarkMode ? "#999" : "#666",
                          display: "block",
                          marginTop: "4px"
                        }}>
                          Status: {charger.status || "Unknown"}
                        </Typography>
                      </ListItemWrapper>
                    ))
                  ) : (
                    <Box sx={{ 
                      display: "flex", 
                      justifyContent: "center", 
                      alignItems: "center",
                      height: "100%",
                      color: isDarkMode ? "#fff" : "#000"
                    }}>
                      No chargers found
                    </Box>
                  )
                )
              )}
            </Box>
          </BusListContainer>
        </Grid>
        
        <Grid item xs={12} md={9}>
          <Box sx={{ 
            borderRadius: "8px",
            overflow: "hidden",
            border: `1px solid ${isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"}`,
          }}>
            <FilterHeader isDarkMode={isDarkMode}>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2,
                color: isDarkMode ? "#fff" : "#333",
              }}>
                {/* Map loading state will be received from Testmap component */}
                <Box sx={{ 
                  display: 'flex', 
                  alignItems: 'center', 
                  gap: 1,
                }}>
                  {activeFilter === "Buses" && (
                    <Typography sx={{ 
                      fontSize: '0.875rem',
                      color: isDarkMode ? '#fff' : '#333' 
                    }}>
                      {`${selectedBuses.length} Buses Selected`}
                    </Typography>
                  )}
                </Box>
              </Box>
              
              {/* Legends Section */}
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2,
                fontSize: '0.875rem'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box sx={{ 
                    width: 12, 
                    height: 12, 
                    backgroundColor: '#FFD700', 
                    borderRadius: '2px' 
                  }}/>
                  <span style={{ color: isDarkMode ? '#fff' : '#333' }}>Depot</span>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box sx={{ 
                    width: 12, 
                    height: 3, 
                    backgroundColor: 'red',
                  }}/>
                  <span style={{ color: isDarkMode ? '#fff' : '#333' }}>Bus</span>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <WarningIcon sx={{ 
                    color: '#FFD700', 
                    fontSize: '1rem' 
                  }}/>
                  <span style={{ color: isDarkMode ? '#fff' : '#333' }}>GPS Status</span>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <BoltIcon sx={{ 
                    color: '#4CAF50', 
                    fontSize: '1rem' 
                  }}/>
                  <span style={{ color: isDarkMode ? '#fff' : '#333' }}>Charger</span>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <RadioButtonUncheckedIcon sx={{ 
                    color: isDarkMode ? '#fff' : '#333', 
                    fontSize: '1rem' 
                  }}/>
                  <span style={{ color: isDarkMode ? '#fff' : '#333' }}>Stop</span>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <DiamondIcon sx={{ 
                    color: '#FF69B4', 
                    fontSize: '1rem' 
                  }}/>
                  <span style={{ color: isDarkMode ? '#fff' : '#333' }}>Start/End</span>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                  <Box sx={{ 
                    width: 12, 
                    height: 3, 
                    backgroundColor: '#2196F3',
                  }}/>
                  <span style={{ color: isDarkMode ? '#fff' : '#333' }}>Route</span>
                </Box>
              </Box>
            </FilterHeader>
            
            <Box sx={{ height: "calc(100vh - 280px)" }}>
              <Testmap
                selectedBuses={selectedBuses}
                selectedChargers={selectedChargers}
                vehicleNumber={VehicleNumber}
                activeFilter={activeFilter}
                focusedBus={focusedBus}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </MainContainer>
  );
};

// Add this component for detail items
const DetailItem = ({ label, value, isDarkMode }) => (
  <Box>
    <Typography 
      variant="caption" 
      sx={{ 
        color: isDarkMode ? "#999" : "#666",
        display: "block",
        fontWeight: 500
      }}
    >
      {label}:
    </Typography>
    <Typography 
      variant="body2" 
      sx={{ 
        color: isDarkMode ? "#fff" : "#000",
        fontWeight: 400
      }}
    >
      {value}
    </Typography>
  </Box>
);

export default Dashboard;
