import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, IconButton, Typography, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Close, PhotoCamera, Delete, Visibility } from '@mui/icons-material';
import { getSupervisorIncident, updateIncident, fetchCompany, fetchLiveScheduleDetails } from '../../../API/IncidentAPI';
import { ThemeContext } from '../../../store/ThemeContext';
import { toast } from 'react-toastify';
import { Box } from "@mui/system";

const UpdateIncident = ({ open, onClose, incident, onIncidentUpdated }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [uploadImageName, setUploadImageName] = useState("");
  const [companies, setCompanies] = useState([]); // Initialize as an empty array
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [liveScheduleDetails, setLiveScheduleDetails] = useState([]); // Initialize as an empty array
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [initialFormData, setInitialFormData] = useState({});
// Add a new state for tracking submission status
const [isSubmitting, setIsSubmitting] = useState(false);
  // Define a simple menu props object that will style all dropdowns
  const selectMenuProps = {
    PaperProps: {
      style: {
        backgroundColor: isDarkMode ? '#333' : '#fff',
        borderRadius: '4px',
        boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        padding: '4px 0',
        maxHeight: '300px'
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (open && incident) {
        setFormData({});
        setLoading(true);
        setError(null);
        try {
          const response = await getSupervisorIncident(incident.companyid, 1, 1, incident.schedulecode);
          if (
            response &&
            response.data.results &&
            Array.isArray(response.data.results) &&
            response.data.results.length > 0
          ) {
            // In the fetchData function, after setting formData
const matched = response.data.results.find((item) => item.id === incident.id);
const fetchedData = matched || response.data.results[0];
setFormData(fetchedData);
setInitialFormData(fetchedData); // Store initial data
            setSelectedCompanyId(matched ? matched.companyid : response.data.results[0].companyid);
          } else {
            setFormData({});
          }
        } catch (error) {
          console.error("Error fetching incident details:", error);
          setError("Failed to load incident details. Please try again.");
        } finally {
          setLoading(false);
        }

        try {
          const comps = await fetchCompany();
          setCompanies(comps); // Ensure the correct data is set
          // Set the selected company ID after companies are fetched
          if (comps.data && comps.data.length > 0) {
            const matchedCompany = comps.data.find(company => company.companyid === incident.companyid);
            setSelectedCompanyId(matchedCompany ? matchedCompany.companyid : '');
          }
        } catch (error) {
          console.error("Error fetching companies:", error);
        }

        try {
          const liveDetails = await fetchLiveScheduleDetails(incident.incidentdate); // Pass the incident date
          setLiveScheduleDetails(liveDetails.data || []); // Ensure the correct data is set
        } catch (error) {
          console.error("Error fetching live schedule details:", error);
        }
      }
    };

   

    fetchData();
  }, [open, incident]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setFormData(initialFormData); // Reset form data to initial state
    setUploadImage(null);
    setUploadImageName("");
    onClose();
  };

  const handleCompanyChange = (e) => {
    setSelectedCompanyId(e.target.value);
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploadImage(e.target.files[0]);
      setUploadImageName(e.target.files[0].name);
    }
  };

  const handleImageRemove = () => {
    setUploadImage(null);
    setUploadImageName("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Disable the button immediately
    setIsSubmitting(true);
    try {
      const updateData = {
        Id: formData.id,
        ScheduleId: formData.scheduleid,
        BusInformationId: formData.businformationid,
        DriverId: formData.driverid,
        CompanyId: selectedCompanyId,
        IncidentDate: formData.incidentdate,
        IncidentTime: formData.incidenttime,
        Location: formData.location,
        Description: formData.description,
        Type: formData.type,
        ImageStr: uploadImage ? URL.createObjectURL(uploadImage) : formData.imagestr,
      };
      const res = await updateIncident(updateData);
      toast.success("Incident updated successfully!");
  
      if (onIncidentUpdated) {
        const updatedIncidentData = res.data || { ...formData, ...updateData };
        onIncidentUpdated(updatedIncidentData);
      }
      onClose();
    } catch (error) {
      toast.error("Failed to update incident!");
      console.error("Error updating incident:", error);
      // Re-enable the button if the update fails
      setIsSubmitting(false);
    }
  }

  return (
    <>
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: isDarkMode ? "#1a1a1a" : "#f8f9fa",
          color: isDarkMode ? "#ffffff" : "#212529",
          borderRadius: "24px", // Increased borderRadius for a curvier look
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: isDarkMode ? "#1a1a1a" : "#f8f9fa",
          color: isDarkMode ? "#ffffff" : "#212529",
          borderTopLeftRadius: "24px", // Ensure top left corner is rounded
          borderTopRightRadius: "24px", // Ensure top right corner is rounded
        }}
      >
        <div className="flex justify-between items-start">
          <div className="space-y-1">
            <h2 className="text-2xl font-semibold">Update Incident</h2>
          </div>
          <IconButton
            onClick={handleCancel}
            size="small"
            sx={{
              color: isDarkMode ? "gray" : "gray-700",
              "&:hover": {
                backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.04)" : "rgba(0, 0, 0, 0.04)"
              }
            }}
          >
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="p-4" sx={{ maxHeight: '70vh', overflowY: 'auto' }}> {/* Reduced padding and added maxHeight */}
        {loading ? (
          <div className="flex items-center justify-center py-4"> {/* Reduced padding */}
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-current"></div>
          </div>
        ) : error ? (
          <div className="text-center py-2 text-red-400">{error}</div> 
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}> {/* Reduced spacing */}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="dense" 
                  label="Case ID"
                  name="caseid"
                  value={formData.caseid || ''}
                  onChange={handleChange}
                  InputProps={{ readOnly: true }}
                  sx={{
                    '& .MuiInputBase-root ': {
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                    },
                    '& .MuiFormLabel-root': {
                      color: isDarkMode ? '#aaa' : '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel sx={{ color: isDarkMode ? '#aaa' : '#000' }}>Schedule Code</InputLabel>
                  <Select
                    value={formData.scheduleid || ''}
                    onChange={(e) => setFormData({ ...formData, scheduleid: e.target.value })}
                    label="Schedule Code"
                    MenuProps={selectMenuProps}
                    sx={{
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'transparent' : '#c0c0c0',

                      },
                      '& .MuiSelect-icon': { 
                        color: isDarkMode ? '#fff' : '#000',
                      },
                      '::-ms-value' : {
                        color: isDarkMode ? '#fff' : '#000',
                      },
                    }}
                  >
                    {liveScheduleDetails.map((detail) => (
                      <MenuItem
                        key={detail.ScheduleId}
                        value={detail.ScheduleId}
                        style={{
                          padding: '10px 16px',
                          fontSize: '14px',
                          backgroundColor: 'transparent',
                          color: isDarkMode ? '#fff' : '#000',
                        }}
                      >
                        {detail.Code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel sx={{ color: isDarkMode ? '#aaa' : '#000' }}>Bus Number</InputLabel>
                  <Select
                    value={formData.businformationid || ''}
                    onChange={(e) => setFormData({ ...formData, businformationid: e.target.value })}
                    label="Bus Number"
                    MenuProps={selectMenuProps}
                    sx={{
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                      },
                      '& .MuiSelect-icon': { 
                        color: isDarkMode ? '#fff' : '#000',
                      },
                    }}
                  >
                    {liveScheduleDetails.map((detail) => (
                      <MenuItem
                        key={detail.BusInformationId}
                        value={detail.BusInformationId}
                        style={{
                          padding: '10px 16px',
                          fontSize: '14px',
                          backgroundColor: 'transparent',
                          color: isDarkMode ? '#fff' : '#000',
                        }}
                      >
                        {detail.BusNumber}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel sx={{ color: isDarkMode ? '#aaa' : '#000' }}>Pilot Code</InputLabel>
                  <Select
                    value={formData.driverid || ''}
                    onChange={(e) => setFormData({ ...formData, driverid: e.target.value })}
                    label="Pilot Code"
                    MenuProps={selectMenuProps}
                    sx={{
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                      },
                      '& .MuiSelect-icon': { 
                        color: isDarkMode ? '#fff' : '#000',
                      },
                    }}
                  >
                    {liveScheduleDetails.map((detail) => (
                      <MenuItem
                        key={detail.PilotId}
                        value={detail.PilotId}
                        style={{
                          padding: '10px 16px',
                          fontSize: '14px',
                          backgroundColor: 'transparent',
                          color: isDarkMode ? '#fff' : '#000',
                        }}
                      >
                        {detail.PilotCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel sx={{ color: isDarkMode ? '#aaa' : '#000' }}>Company ID</InputLabel>
                  <Select
                    value={formData.companyid || ''}
                    onChange={(e) => setFormData({ ...formData, companyid: e.target.value })}
                    label="Company ID"
                    MenuProps={selectMenuProps}
                    sx={{
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                      },
                      '& .MuiSelect-icon': { 
                        color: isDarkMode ? '#fff' : '#000',
                      },
                    }}
                  >
                    {companies.map((detail) => (
                      <MenuItem
                        key={detail.companyid}
                        value={detail.companyid}
                        style={{
                          padding: '10px 16px',
                          fontSize: '14px',
                          backgroundColor: 'transparent',
                          color: isDarkMode ? '#fff' : '#000',
                        }}
                      >
                        {detail.companyname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Incident Date"
                  name="incidentdate"
                  value={formData.incidentdate || ''}
                  onChange={handleChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                    },
                    '& .MuiFormLabel-root': {
                      color: isDarkMode ? '#aaa' : '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="dense" 
                  label="Incident Time"
                  name="incidenttime"
                  value={formData.incidenttime || ''}
                  onChange={handleChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                    },
                    '& .MuiFormLabel-root': {
                      color: isDarkMode ? '#aaa' : '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Location"
                  name="location"
                  value={formData.location || ''}
                  onChange={handleChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                    },
                    '& .MuiFormLabel-root': {
                      color: isDarkMode ? '#aaa' : '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  label="Description"
                  name="description"
                  value={formData.description || ''}
                  onChange={handleChange}
                  sx={{
                    '& .MuiInputBase-root': {
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                    },
                    '& .MuiFormLabel-root': {
                      color: isDarkMode ? '#aaa' : '#000',
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="dense" variant="outlined">
                  <InputLabel sx={{ color: isDarkMode ? '#aaa' : '#000' }}>Type</InputLabel>
                  <Select
                    value={formData.type || ''}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    label="Type"
                    MenuProps={selectMenuProps}
                    sx={{
                      backgroundColor: isDarkMode ? '#424242' : '#fff',
                      color: isDarkMode ? '#fff' : '#000',
                      borderRadius: '4px',
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: isDarkMode ? 'transparent' : '#c0c0c0',
                      },
                      '& .MuiSelect-icon': { 
                        color: isDarkMode ? '#fff' : '#000',
                      },
                    }}
                  >
                    <MenuItem 
                      value="Incident"
                      style={{
                        padding: '10px 16px',
                        fontSize: '14px',
                        backgroundColor: 'transparent',
                        color: isDarkMode ? '#fff' : '#000',
                      }}

                    >
                      Incident
                    </MenuItem>
                    <MenuItem 
                      value="Accident"
                      style={{
                        padding: '10px 16px',
                        fontSize: '14px',
                        backgroundColor: 'transparent',
                        color: isDarkMode ? '#fff' : '#000',
                      }}
                    >
                      Accident
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', padding: '8px 12px', marginTop: '8px' }}>
                <Button
                  variant="contained"
                  component="label"
                  startIcon={<PhotoCamera />}
                  sx={{
                    bgcolor: isDarkMode ? "#4287f5" : "#3b536f",
                    color: "white",
                    textTransform: "none",
                    padding: '8px 16px',
                    borderRadius: '4px',
                    width: "auto",
                    "&:hover": { bgcolor: isDarkMode ? "#357ae8" : "#2a3f5a" }
                  }}
                >
                  Upload Case Photo
                  <input type="file" hidden onChange={handleImageChange} />
                </Button>
                {uploadImageName && (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 2 }}>
                    <Typography
                      variant="body2"
                      noWrap
                      sx={{
                        color: isDarkMode ? "white" : "black",
                        maxWidth: "calc(100% - 30px)",
                        overflow: "hidden",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {uploadImageName}
                    </Typography>
                    <IconButton onClick={handleImageRemove} size="small" sx={{ color: isDarkMode ? "white" : "black" }}>
                      <Delete />
                    </IconButton>
                  </Box>
                )}
                {formData.imagestr && (
                  <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: isDarkMode ? "white" : "black",
                        fontWeight: "bold"
                      }}
                    >
                      Uploaded File:
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#4287f5",
                        marginLeft: 1,
                        cursor: "pointer",
                        textDecoration: "underline",
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() => setOpenImagePreview(true)}
                    >
                      {formData.imagestr.split('/').pop()}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={handleCancel} variant="outlined" sx={{ 
          borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.3)' : '#3b536f',
          color: isDarkMode ? '#fff' : '#3b536f',
          textTransform: 'none',
          minWidth: '100px',
          '&:hover': {
            borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#2a3f5a',
            backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(59, 83, 111, 0.04)',
          }
        }}>
          Cancel
        </Button>
        <Button 
  onClick={handleSubmit} 
  disabled={isSubmitting}
  variant="contained" 
  sx={{ 
    backgroundColor: isDarkMode ? '#4287f5' : '#3b536f',
    color: 'white',
    textTransform: 'none',
    minWidth: '100px',
    marginLeft: '16px',
    '&:hover': {
      backgroundColor: isDarkMode ? '#357ae8' : '#2a3f5a',
    },
    '&.Mui-disabled': {
      bgcolor: isDarkMode ? 'rgba(66, 135, 245, 0.5)' : 'rgba(59, 83, 111, 0.7)',
      color: 'white'
    }
  }}
>
  {isSubmitting ? (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <span style={{ marginRight: '8px' }}>Updating</span>
      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white"></div>
    </Box>
  ) : "Update"}
</Button>
      </DialogActions>
    </Dialog>
    {openImagePreview && formData?.imagestr && (
        <Dialog
          open={openImagePreview}
          onClose={() => setOpenImagePreview(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: isDarkMode ? "#1a1a1a" : "#f8f9fa",
              color: isDarkMode ? "#ffffff" : "#212529",
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle>Case Photo</DialogTitle>
          <DialogContent>
            <img
              src={formData.fileurl}
              alt="Case Photo"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          </DialogContent>
          <div className={`flex justify-end px-6 py-4 border-t ${isDarkMode ? "border-gray-700" : "border-gray-200"}`}>
            <Button
              variant="contained"
              onClick={() => setOpenImagePreview(false)}
              className={`${isDarkMode ? "bg-blue-600 hover:bg-blue-700" : "bg-blue-500 hover:bg-blue-600"} text-white transition-colors px-8 py-2 text-base font-medium`}
            >
              CLOSE
            </Button>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default UpdateIncident;