import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  styled,
  Collapse,
  Switch as MuiSwitch,
  Button,
  Select,
  MenuItem,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  FormControl,
  InputLabel,
} from '@mui/material';
import {
  Close as CloseIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { ThemeContext } from '../../../store/ThemeContext';
import { toast } from 'react-toastify';
import { baseURL } from '../../../API/BaseURL';
import { getFirstPartyIncidentDetails, updateFirstPartyIncidentDetails } from '../../../API/IncidentAPI';

/**
 * Styled Components for consistent styling
 */
const SectionHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#000',
  color: 'white',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  textAlign: 'center'
}));

const EntryContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#363636',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#0D6EFD',
    '&:hover': { backgroundColor: 'rgba(13, 110, 253, 0.08)' },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: '#0D6EFD' },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: '#2C2C2C',
  borderRadius: theme.shape.borderRadius,
  '& .MuiInputBase-input': {
    color: '#808080',
    padding: theme.spacing(1),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': { borderColor: 'transparent' },
    '&:hover fieldset': { borderColor: 'transparent' },
    '&.Mui-focused fieldset': { borderColor: 'transparent' },
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: '#2C2C2C',
  color: '#808080',
  borderRadius: theme.shape.borderRadius,
  '& .MuiSelect-select': {
    padding: theme.spacing(1),
  },
  '& fieldset': {
    borderColor: '#000000',
    borderWidth: '1px'
  },
  '&:hover fieldset': {
    borderColor: '#000000'
  },
  '&.Mui-focused fieldset': {
    borderColor: '#0D6EFD'
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: isDarkMode => isDarkMode ? '#363636' : '#ffffff',
  marginRight: '15px',
  borderRadius: theme.shape.borderRadius,
  border: isDarkMode => isDarkMode ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
  '& .MuiToggleButton-root': {
    color: isDarkMode => isDarkMode ? '#808080' : '#666666',
    border: '1px solid #e0e0e0',
    padding: '6px 16px',
    minWidth: '40px',
    height: '32px',
    fontSize: '0.875rem',
    '&.Mui-selected': {
      backgroundColor: '#0d6efd',
      color: 'white',
      '&:hover': {
        backgroundColor: '#0d6efd',
        opacity: 0.9
      }
    },
    '&:hover': {
      backgroundColor: isDarkMode =>
        isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(59, 83, 111, 0.08)'
    },
    '&:not(:first-of-type)': {
      borderLeft: isDarkMode => isDarkMode ? 'none' : '1px solid #e0e0e0'
    }
  }
}));

/**
 * Common styling for input fields
 */
const inputSx = {
  bgcolor: '#2C2C2C',
  '& .MuiOutlinedInput-root': {
    color: '#808080',
    '& fieldset': {
      borderColor: '#000000',
      borderWidth: '1px'
    },
    '&:hover fieldset': {
      borderColor: '#000000'
    },
    '&.Mui-focused fieldset': {
      borderColor: '#0D6EFD'
    },
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: '#808080',
    WebkitTextFillColor: '#808080'
  },
  '& .MuiInputLabel-root': {
    color: '#808080'
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#808080'
  },
  '& .MuiInputLabel-root.Mui-disabled': {
    color: '#808080'
  }
};

/**
 * Staff Entry Component
 * Handles display and input for each staff member's information
 * 
 * @param {Object} entry - The staff entry data
 * @param {Function} onChange - Handler for entry changes
 * @param {Function} onRemove - Handler to remove this entry
 * @param {boolean} disabled - Whether inputs are disabled
 * @param {boolean} showRemoveButton - Whether to show remove button
 * @param {number} staffNumber - Index number for this staff entry
 */
const StaffEntry = ({ entry, onChange, onRemove, disabled, showRemoveButton = true, staffNumber, error, helperText }) => {
  const { isDarkMode } = useContext(ThemeContext);

  const handleInputChange = (e) => {
    onChange({ ...entry, [e.target.name]: e.target.value });
  };

  return (
    <EntryContainer sx={{
      bgcolor: isDarkMode ? '#363636' : '#ffffff',
      position: 'relative'
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3,
        borderRadius: 1,
        position: 'relative'
      }}>
        <Typography sx={{ color: isDarkMode ? '#00ffff' : '#3b536f' }}>
          Staff {staffNumber}
        </Typography>

        {showRemoveButton && entry.id !== 1 && (
          <IconButton
            onClick={onRemove}
            disabled={disabled}
            sx={{
              position: 'absolute',
              right: -19,
              top: -19,
              backgroundColor: isDarkMode ? '#363636' : '#ffffff',
              color: isDarkMode ? '#808080' : '#666666',
              border: isDarkMode ? 'none' : '1px solid #e0e0e0',
              '&:hover': {
                backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                color: 'red'
              },
              padding: '3px'
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: isDarkMode ? 'white' : '#333333', mr: 1 }}>
            Severity Level
          </Typography>
          <StyledToggleButtonGroup
            value={entry.severityLevel || null}
            exclusive
            onChange={(e, value) => {
              if (value !== null) {
                onChange({ ...entry, severityLevel: value });
              }
            }}
            disabled={disabled}
            size="medium"
          >
            {['A', 'B', 'C', 'D', 'Fatal'].map((level) => (
              <ToggleButton
                key={level}
                value={level}
                sx={{
                  minWidth: '48px',
                  fontSize: '0.9rem',
                  color: isDarkMode ? 'white' : '#333333',
                  py: 1,
                  px: 2
                }}
              >
                {level}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
      </Box>

      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, mb: 2 }}>
        <TextField
          label="Name"
          name="name"
          value={entry.name}
          onChange={handleInputChange}
          disabled={disabled}
          error={!!error}
          helperText={helperText}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
              color: isDarkMode ? 'white' : '#666666',
              '& fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
              },
              '&:hover fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-disabled': {
                '& input, & textarea': {
                  color: isDarkMode ? 'white !important' : '#666666 !important',
                  '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                }
              },
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
              }
            },
            '& .MuiInputLabel-root': {
              color: isDarkMode ? 'white' : '#666666'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isDarkMode ? 'white' : '#3b536f'
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: isDarkMode ? 'white !important' : '#666666 !important'
            }
          }}
        />
        <TextField
          label="Description"
          name="description"
          value={entry.description}
          onChange={handleInputChange}
          disabled={disabled}
          error={!!error}
          helperText={helperText}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
              color: isDarkMode ? 'white' : '#666666',
              '& fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
              },
              '&:hover fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-disabled': {
                '& input, & textarea': {
                  color: isDarkMode ? 'white !important' : '#666666 !important',
                  '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                }
              },
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
              }
            },
            '& .MuiInputLabel-root': {
              color: isDarkMode ? 'white' : '#666666'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isDarkMode ? 'white' : '#3b536f'
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: isDarkMode ? 'white !important' : '#666666 !important'
            }
          }}
        />
      </Box>
    </EntryContainer>
  );
};

/**
 * Passenger Entry Component
 * Handles display and input for each passenger's information
 * 
 * @param {Object} entry - The passenger entry data
 * @param {Function} onChange - Handler for entry changes
 * @param {Function} onRemove - Handler to remove this entry
 * @param {boolean} disabled - Whether inputs are disabled
 * @param {boolean} showRemoveButton - Whether to show remove button
 * @param {number} passengerNumber - Index number for this passenger entry
 */
const PassengerEntry = ({ entry, onChange, onRemove, disabled, showRemoveButton = true, passengerNumber, error, helperText }) => {
  const { isDarkMode } = useContext(ThemeContext);

  const handleInputChange = (e) => {
    onChange({ ...entry, [e.target.name]: e.target.value });
  };

  return (
    <EntryContainer sx={{
      bgcolor: isDarkMode ? '#363636' : '#ffffff',
      position: 'relative'
    }}>
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 3,
        borderRadius: 1,
        position: 'relative'
      }}>
        <Typography sx={{ color: isDarkMode ? '#00ffff' : '#3b536f' }}>
          Passenger {passengerNumber}
        </Typography>

        {showRemoveButton && entry.id !== 1 && (
          <IconButton
            onClick={onRemove}
            disabled={disabled}
            sx={{
              position: 'absolute',
              right: -19,
              top: -19,
              backgroundColor: isDarkMode ? '#363636' : '#ffffff',
              color: isDarkMode ? '#808080' : '#666666',
              border: isDarkMode ? 'none' : '1px solid #e0e0e0',
              '&:hover': {
                backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                color: 'red'
              },
              padding: '3px'
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: isDarkMode ? 'white' : '#333333', mr: 1 }}>
            Severity Level
          </Typography>
          <StyledToggleButtonGroup
            value={entry.severityLevel}
            exclusive
            onChange={(e, value) => value && onChange({ ...entry, severityLevel: value })}
            disabled={disabled}
            size="medium"
          >
            {['A', 'B', 'C', 'D', 'Fatal'].map((level) => (
              <ToggleButton
                key={level}
                value={level}
                sx={{
                  minWidth: '48px',
                  fontSize: '0.9rem',
                  color: isDarkMode ? 'white' : '#333333',
                  py: 1,
                  px: 2
                }}
              >
                {level}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
      </Box>

      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: 2, mb: 2 }}>
        <TextField
          label="Name"
          name="name"
          value={entry.name}
          onChange={handleInputChange}
          disabled={disabled}
          error={!!error}
          helperText={helperText}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
              color: isDarkMode ? 'white' : '#666666',
              '& fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
              },
              '&:hover fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-disabled': {
                '& input, & textarea': {
                  color: isDarkMode ? 'white !important' : '#666666 !important',
                  '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                }
              },
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
              }
            },
            '& .MuiInputLabel-root': {
              color: isDarkMode ? 'white' : '#666666'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isDarkMode ? 'white' : '#3b536f'
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: isDarkMode ? 'white !important' : '#666666 !important'
            }
          }}
        />

        <TextField
          label="Identity Card No"
          name="identityproof"
          value={entry.identityproof || ''}
          onChange={handleInputChange}
          disabled={disabled}
          error={!!error}
          helperText={helperText}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
              color: isDarkMode ? 'white' : '#666666',
              '& fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
              },
              '&:hover fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-disabled': {
                '& input, & textarea': {
                  color: isDarkMode ? 'white !important' : '#666666 !important',
                  '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                }
              },
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
              }
            },
            '& .MuiInputLabel-root': {
              color: isDarkMode ? 'white' : '#666666'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isDarkMode ? 'white' : '#3b536f'
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: isDarkMode ? 'white !important' : '#666666 !important'
            }
          }}
        />
        <TextField
          label="Age"
          name="age"
          value={entry.age}
          onChange={(e) => {
            const value = e.target.value;
            
            if (
              value === '' ||
              (/^\d+$/.test(value) &&
                value.length <= 2 &&
                parseInt(value, 10) > 0)
            ) {
              handleInputChange(e);
            }
          }}
          disabled={disabled}
          type="number"
          inputProps={{
            min: 1,
            max: 99,
            step: 1,
            onKeyDown: (e) => {
              // Prevent users from entering 'e' or '-'
              if (e.key === 'e' || e.key === '-' || e.key === '+') {
                e.preventDefault();
              }
            },
            onInput: (e) => {
              // Remove any non-numeric characters
              e.target.value = e.target.value.replace(/[e\-+]/g, '');

              // Trim to 3 digits
              if (e.target.value.length > 3) {
                e.target.value = e.target.value.slice(0, 3);
              }
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
              color: isDarkMode ? 'white' : '#666666',
              '& fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
              },
              '&:hover fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-disabled': {
                '& input, & textarea': {
                  color: isDarkMode ? 'white !important' : '#666666 !important',
                  '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                }
              },
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
              }
            },
            '& .MuiInputLabel-root': {
              color: isDarkMode ? 'white' : '#666666'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isDarkMode ? 'white' : '#3b536f'
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: isDarkMode ? 'white !important' : '#666666 !important'
            }
          }}
        />
        <FormControl fullWidth
          sx={{
            '& .MuiOutlinedInput-root': {
              bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
              color: isDarkMode ? 'white' : '#666666',
              '& fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
              },
              '&:hover fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
              },
              '&.Mui-disabled': {
                '& .MuiSelect-select': {
                  color: isDarkMode ? 'white !important' : '#666666 !important',
                  '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                }
              },
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
              }
            },
            '& .MuiInputLabel-root': {
              color: isDarkMode ? 'white' : '#666666'
            },
            '& .MuiInputLabel-root.Mui-focused': {
              color: isDarkMode ? 'white' : '#3b536f'
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              color: isDarkMode ? 'white !important' : '#666666 !important'
            },
            '& .MuiSelect-icon': {
              color: isDarkMode ? '#808080' : '#666666'
            },
            '& .Mui-disabled .MuiSelect-icon': {
              color: 'white !important'
            }
          }}
          disabled={disabled}>
          <InputLabel sx={{
            color: isDarkMode ? 'white' : '#666666',
            '&.Mui-disabled': {
              color: 'white !important'
            }
          }}>Gender</InputLabel>
          <Select
            name="gender"
            value={entry.gender || ''}
            onChange={handleInputChange}
            label="Gender"
            error={!!error}
            helperText={helperText}
            sx={{
              '&.Mui-disabled': {
                color: 'white',
                '& .MuiSelect-select': {
                  color: 'white !important',
                  '-webkit-text-fill-color': 'white !important'
                }
              },
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
              }
            }}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
            <MenuItem value="Not Specified">Not Specified</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TextField
        label="Description"
        name="description"
        value={entry.description}
        onChange={handleInputChange}
        disabled={disabled}
        fullWidth
        multiline
        rows={2}
        error={!!error}
        helperText={helperText}
        sx={{
          '& .MuiOutlinedInput-root': {
            bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
            color: isDarkMode ? 'white' : '#666666',
            '& fieldset': {
              borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
            },
            '&:hover fieldset': {
              borderColor: isDarkMode ? 'transparent' : '#3b536f'
            },
            '&.Mui-focused fieldset': {
              borderColor: isDarkMode ? 'transparent' : '#3b536f'
            },
            '&.Mui-disabled': {
              '& input, & textarea': {
                color: isDarkMode ? 'white !important' : '#666666 !important',
                '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
              }
            },
            '& input:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 30px #ffffff inset',
              WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
            }
          },
          '& .MuiInputLabel-root': {
            color: isDarkMode ? 'white' : '#666666'
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: isDarkMode ? 'white' : '#3b536f'
          },
          '& .MuiInputLabel-root.Mui-disabled': {
            color: isDarkMode ? 'white !important' : '#666666 !important'
          }
        }}
      />
    </EntryContainer>
  );
};

/**
 * FirstParty component for managing first party incident details
 * Allows users to input company vehicle damage, staff and passenger injuries
 * 
 * @param {number} incidentId - ID of the incident
 * @param {boolean} isLocked - Whether the form should be locked/disabled
 */
const FirstParty = ({ incidentId, isLocked, reassigned }) => {
  const { isDarkMode } = useContext(ThemeContext);

  // Add state for storing the first party id
  const [firstPartyId, setFirstPartyId] = useState(null);

  // State for form fields
  const [damageLevel, setDamageLevel] = useState('');
  const [isVehicleDamaged, setIsVehicleDamaged] = useState(false);
  const [isCollision, setIsCollision] = useState(false);
  const [collisionType, setCollisionType] = useState(false);
  const [isStaffInjured, setIsStaffInjured] = useState(false);
  const [staffEntries, setStaffEntries] = useState([
    { id: 1, severityLevel: 'A', name: '', description: '' }
  ]);
  const [isPassengerInjured, setIsPassengerInjured] = useState(false);
  const [passengerEntries, setPassengerEntries] = useState([
    { id: 1, severityLevel: 'A', name: '', identityproof: '', age: '', gender: '', description: '' }
  ]);
  const [ActualLevel, setActualLevel] = useState(false);
  const [damageLevel1, setDamageLevel1] = useState(false);
  const [vehicleDamageDescription, setVehicleDamageDescription] = useState(false);

  // UI state
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // State for error messages
  const [errors, setErrors] = useState({
    damageLevel: '',
    vehicleDamageDescription: '',
    collisionType: '',
    staffName: '',
    passengerName: '',
  });

  /**
   * Fetches existing first party details when component mounts
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const responseData = await getFirstPartyIncidentDetails(incidentId);

        // Check if the data array is not empty and take the first element
        if (responseData.data && responseData.data.length > 0) {
          const data = responseData.data[0]; // Take the first element of the array

          // Store the id from the API response
          setFirstPartyId(data.id);

          setDamageLevel(data.dmglevel || '');
          setIsVehicleDamaged(data.companyvehicledmg || false);
          setIsCollision(data.collisiontype != null || false);
          setCollisionType(data.collisiontype || "");
          setIsStaffInjured(data.staffinjury || false);

          // Adapt staff details to include detailId for updates
          const adaptedStaffDetails = data.staffinjury_details?.map(item => ({
            id: item.id, // Local ID for array tracking
            detailId: item.id, // Backend ID for updates
            severityLevel: item.severitylevel || 'A',
            name: item.name || '',
            description: item.description || '',
          })) || [];

          // Set staff entries, or use default if none exist
          setStaffEntries(adaptedStaffDetails.length > 0
            ? adaptedStaffDetails
            : [{ id: 1, severityLevel: 'A', name: '', description: '' }]
          );

          setIsPassengerInjured(data.passengerinjury || false);

          // Adapt passenger details to include detailId for updates
          const adaptedPassengerDetails = data.passengerinjury_details?.map(item => ({
            id: item.id, // Local ID for array tracking
            detailId: item.id, // Backend ID for updates
            severityLevel: item.severitylevel || 'A',
            name: item.name || '',
            identityproof: item.identityproof || '',
            age: item.age || '',
            gender: item.gender || '',
            description: item.description || '',
          })) || [];

          // Set passenger entries, or use default if none exist
          setPassengerEntries(adaptedPassengerDetails.length > 0
            ? adaptedPassengerDetails
            : [{ id: 1, severityLevel: 'A', name: '', identityproof: '', age: '', gender: '', description: '' }]
          );

          setActualLevel(data.actuallevel || '');
          setDamageLevel1(data.probabilitylevel || '');
          setVehicleDamageDescription(data.dmgdescription || '');
          
          // Set submitted state based on reassigned flag
          setIsSubmitted(!reassigned);
        } else {
          resetForm();
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error("Error loading first party details");
        if (error?.response?.status === 404) {
          setIsSubmitted(false);
        }
      } finally {
        setIsLoading(false);
        
      }
    };

    if (incidentId) {
      fetchData();
    }
  }, [incidentId, reassigned]);

  /**
   * Resets form to default values
   */
  const resetForm = () => {
    setIsSubmitted(false);
    setDamageLevel('');
    setIsVehicleDamaged(false);
    setIsCollision(false);
    setCollisionType("");
    setIsStaffInjured(false);
    setStaffEntries([{ id: 1, severityLevel: 'A', name: '', description: '' }]);
    setIsPassengerInjured(false);
    setPassengerEntries([{ id: 1, severityLevel: 'A', name: '', identityCardNo: '', age: '', gender: '', description: '' }]);
    setActualLevel('');
    setDamageLevel1('');
    setVehicleDamageDescription('');
  };

  /**
   * Handles form submission
   */
  const handleSubmit = async () => {
    // Reset errors
    setErrors({
      damageLevel: '',
      vehicleDamageDescription: '',
      collisionType: '',
      staffName: '',
      passengerName: '',
    });

    let hasError = false;

    // Validation - only validate enabled sections
    if (isVehicleDamaged) {
      if (!damageLevel) {
        setErrors(prev => ({ ...prev, damageLevel: 'Damage Level is required.' }));
        hasError = true;
      }
      if (!vehicleDamageDescription) {
        setErrors(prev => ({ ...prev, vehicleDamageDescription: 'Vehicle Damage Description is required.' }));
        hasError = true;
      }
    }

    if (isCollision && !collisionType) {
      setErrors(prev => ({ ...prev, collisionType: 'Collision Type is required.' }));
      hasError = true;
    }

    if (isStaffInjured) {
      staffEntries.forEach((entry) => {
        if (!entry.name) {
          setErrors(prev => ({ ...prev, staffName: 'This field is required.' }));
          hasError = true;
        }
      });
    }

    if (isPassengerInjured) {
      passengerEntries.forEach((entry) => {
        if (!entry.name) {
          setErrors(prev => ({ ...prev, passengerName: 'This field is required.' }));
          hasError = true;
        }
      });
    }

    if (hasError) {
      toast.error('Please fill in all required fields.');
      return;
    }

    try {
      setIsLoading(true);

      const formData = {
        // Include id only for update operations
        ...(reassigned === true && firstPartyId && { id: firstPartyId }),
        incidentreportingid: incidentId,
        companyvehicledmg: isVehicleDamaged,
        dmglevel: damageLevel,
        dmgdescription: vehicleDamageDescription,
        collisiontype: isCollision ? collisionType : null,
        staffinjury: isStaffInjured,

        // Only include staff injury details if that section is enabled
        ...(isStaffInjured && {
          staffinjurydetails: staffEntries.map((entry) => ({
            ...(entry.detailId && { id: entry.detailId }), // Include ID for existing entries
            severitylevel: entry.severityLevel,
            name: entry.name,
            description: entry.description,
          }))
        }),

        passengerinjury: isPassengerInjured,

        // Only include passenger injury details if that section is enabled
        ...(isPassengerInjured && {
          passengerinjurydetails: passengerEntries.map((entry) => ({
            ...(entry.detailId && { id: entry.detailId }), // Include ID for existing entries
            severitylevel: entry.severityLevel,
            name: entry.name,
            identityproof: entry.identityproof,
            age: entry.age || "Not Provided",
            gender: entry.gender || "Not Specified",
            description: entry.description,
          }))
        }),

        actuallevel: ActualLevel,
        probabilitylevel: damageLevel1,
      };

      // console.log('Submitting form data:', formData);

      // Get the token from sessionStorage
      const authToken = sessionStorage.getItem("AuthToken");
      const parsedToken = JSON.parse(authToken);
      const token = parsedToken.token;

      let response;
      if (reassigned === true) {
        // Use PATCH request for updates
        response = await fetch(
          `${baseURL}/IncidentReporting/update-first-party-details/`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          }
        );
      } else {
        // Use POST request for new entries
        response = await fetch(
          `${baseURL}/IncidentReporting/create-first-party-details/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
          }
        );
      }

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Failed to ${reassigned === true ? 'update' : 'submit'} data: ${response.status} - ${errorText}`
        );
      }

      await response.json();
      // setIsSubmitted(true);
      toast.success(reassigned === true
        ? "First party details updated successfully!"
        : "First party details submitted successfully!");

      // Lock the form after successful submission
      setIsSubmitted(true);

    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error(`Error ${reassigned === true ? 'updating' : 'submitting'} form: ` + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Adds a new staff entry
   */
  const handleAddStaff = () => {
    setStaffEntries([...staffEntries, {
      id: Date.now(), // Use timestamp for new entries
      detailId: null, // No backend ID for new entries
      severityLevel: 'A',
      name: '',
      description: ''
    }]);
  };

  /**
   * Removes a staff entry by ID
   * @param {number} staffId - ID of staff entry to remove
   */
  const handleRemoveStaff = (staffId) => {
    setStaffEntries(staffEntries.filter(entry => entry.id !== staffId));
  };

  /**
   * Updates a staff entry with new data
   * @param {Object} updatedEntry - Updated staff entry data
   */
  const handleStaffChange = (updatedEntry) => {
    setStaffEntries(staffEntries.map(entry =>
      entry.id === updatedEntry.id ? updatedEntry : entry
    ));
  };

  /**
   * Adds a new passenger entry
   */
  const handleAddPassenger = () => {
    setPassengerEntries([...passengerEntries, {
      id: Date.now(), // Use timestamp for new entries
      detailId: null, // No backend ID for new entries
      severityLevel: 'A',
      name: '',
      identityproof: '',
      age: '',
      gender: '',
      description: ''
    }]);
  };

  /**
   * Removes a passenger entry by ID
   * @param {number} passengerId - ID of passenger entry to remove
   */
  const handleRemovePassenger = (passengerId) => {
    setPassengerEntries(passengerEntries.filter(entry => entry.id !== passengerId));
  };

  /**
   * Updates a passenger entry with new data
   * @param {Object} updatedEntry - Updated passenger entry data
   */
  const handlePassengerChange = (updatedEntry) => {
    setPassengerEntries(passengerEntries.map(entry =>
      entry.id === updatedEntry.id ? updatedEntry : entry
    ));
  };

  // Define options for Actual Level and Probability Level
  const levelOptions = ['C', 'B', 'A'];

  return (
    <div style={{
      backgroundColor: isDarkMode ? '#1E1E1E' : '#f5f7fa',
      padding: '20px'
    }}>
      {/* Section Header */}
      <Box sx={{
        bgcolor: isDarkMode ? '#000' : '#3b536f',
        color: 'white',
        p: 1,
        borderRadius: 1,
        mb: 2,
        textAlign: 'center'
      }}>
        <Typography>Supervisor (Level 2)</Typography>
      </Box>

      {/* Form Title */}
      <Box sx={{
        p: 0.5,
        mb: 2,
        borderRadius: 1,
      }}>
        <Typography variant="subtitle1" gutterBottom sx={{
          color: isDarkMode ? '#808080' : '#333333',
          fontWeight: 500
        }}>
          First Party Details
        </Typography>
      </Box>

      {/* Company Vehicle Damage Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <StyledSwitch
              checked={isVehicleDamaged}
              onChange={(e) => !isSubmitted && setIsVehicleDamaged(e.target.checked)}
              disabled={isSubmitted || isLocked}
            />
            <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
              Company Vehicle Damage
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
              Damage Level
            </Typography>
            <StyledToggleButtonGroup
              value={damageLevel}
              exclusive
              onChange={(e, value) => value && !isSubmitted && setDamageLevel(value)}
              disabled={isSubmitted || isLocked}
              size="medium"
            >
              {['1', '2', '3', '4', 'Extreme'].map((level) => (
                <ToggleButton
                  key={level}
                  value={level}
                  sx={{
                    minWidth: '48px',
                    fontSize: '0.9rem',
                    color: isDarkMode ? 'white' : '#333333',
                    py: 1,
                    px: 2
                  }}
                >
                  {level}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          </Box>
        </Box>

        <Collapse in={isVehicleDamaged}>
          <Box>
            <Typography sx={{ color: isDarkMode ? 'white' : '#333333', mb: 1 }}>
              Description:
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="Type"
              value={vehicleDamageDescription}
              onChange={(e) => !isSubmitted && setVehicleDamageDescription(e.target.value)}
              disabled={isSubmitted || isLocked}
              error={!!errors.vehicleDamageDescription}
              helperText={errors.vehicleDamageDescription}
              sx={{
                '& .MuiOutlinedInput-root': {
                  bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                  color: isDarkMode ? 'white' : '#666666',
                  '& fieldset': {
                    borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                  },
                  '&:hover fieldset': {
                    borderColor: isDarkMode ? 'transparent' : '#3b536f'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: isDarkMode ? 'transparent' : '#3b536f'
                  },
                  '&.Mui-disabled': {
                    '& input, & textarea': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                    }
                  },
                  '& input:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                    WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
                  }
                },
                '& .MuiInputLabel-root': {
                  color: isDarkMode ? 'white' : '#666666'
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: isDarkMode ? 'white' : '#3b536f'
                },
                '& .MuiInputLabel-root.Mui-disabled': {
                  color: isDarkMode ? 'white !important' : '#666666 !important'
                }
              }}
            />
          </Box>
        </Collapse>
      </Box>

      {/* Collision Type Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <StyledSwitch
              checked={isCollision}
              onChange={(e) => !isSubmitted && setIsCollision(e.target.checked)}
              disabled={isSubmitted || isLocked}
            />
            <Typography sx={{
              color: isDarkMode ? 'white' : '#333333',
            }}>
              Collision Type
            </Typography>
          </Box>

          {isCollision && (
            <FormControl sx={{ minWidth: 200 }}>
              <StyledSelect
                value={collisionType}
                onChange={(e) => !isSubmitted && setCollisionType(e.target.value)}
                displayEmpty
                disabled={isSubmitted || isLocked}
                error={!!errors.collisionType}
                helperText={errors.collisionType}
                sx={{
                  bgcolor: isDarkMode ? '#2C2C2C' : '#f5f5f5',
                  '& .MuiSelect-select': {
                    py: 1,
                    px: 2,
                    color: isDarkMode ? 'white' : '#333333',
                  },
                  '&.Mui-disabled .MuiSelect-select': {
                    color: 'white !important',
                    '-webkit-text-fill-color': 'white !important'
                  },
                  '& input:-webkit-autofill': {
                    WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                    WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
                  }
                }}
              >
                <MenuItem value="" disabled>
                  <Typography sx={{
                    color: isDarkMode ? '#808080' : '#666666',
                    fontSize: '0.875rem',
                    '&.Mui-disabled': {
                      color: 'white'
                    }
                  }}>
                    Select Collision Type
                  </Typography>
                </MenuItem>
                {['Front-End Collision', 'Rear-End Collision', 'LHS Collision', 'RHS Collision', 'Over Head Collision', 'Underneath Collision', 'Other'].map((type) => (
                  <MenuItem
                    key={type}
                    value={type}
                    sx={{
                      color: isDarkMode ? '#808080' : '#333333',
                      '&.Mui-disabled': {
                        color: 'white !important',
                        opacity: 1
                      },
                      '&:hover': {
                        bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                      },
                      '&.Mui-selected': {
                        bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.16)' : 'rgba(13, 110, 253, 0.08)',
                        '&:hover': {
                          bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.24)' : 'rgba(13, 110, 253, 0.12)'
                        }
                      }
                    }}
                  >
                    {type}
                  </MenuItem>
                ))}
              </StyledSelect>
            </FormControl>
          )}
        </Box>
      </Box>

      {/* Staff Injured Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <StyledSwitch
            checked={isStaffInjured}
            onChange={(e) => !isSubmitted && setIsStaffInjured(e.target.checked)}
            disabled={isSubmitted || isLocked}
          />
          <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
            Staff Injured
          </Typography>
        </Box>

        <Collapse in={isStaffInjured}>
          <Box sx={{ mt: 2 }}>
            {staffEntries.map((entry, index) => (
              <Box key={index} sx={{
                mb: 2,
                bgcolor: isDarkMode ? '#363636' : '#ffffff',
                p: 2,
                borderRadius: 1,
                border: isDarkMode ? 'none' : '1px solid #e0e0e0'
              }}>
                <StaffEntry
                  entry={entry}
                  onChange={handleStaffChange}
                  onRemove={() => handleRemoveStaff(entry.id)}
                  disabled={isSubmitted || isLocked}
                  error={!!errors.staffName}
                  helperText={errors.staffName}
                  staffNumber={index + 1}
                />
              </Box>
            ))}

            {/* Add Staff Button */}
            {!isSubmitted && (
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddStaff}
                disabled={isSubmitted || isLocked}
                sx={{
                  color: isDarkMode ? '#808080' : '#3b536f',
                  '&:hover': {
                    backgroundColor: isDarkMode ? 'rgba(128, 128, 128, 0.08)' : 'rgba(59, 83, 111, 0.08)'
                  }
                }}
              >
                Add Staff
              </Button>
            )}
          </Box>
        </Collapse>
      </Box>

      {/* Passenger Injured Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <StyledSwitch
            checked={isPassengerInjured}
            onChange={(e) => !isSubmitted && setIsPassengerInjured(e.target.checked)}
            disabled={isSubmitted || isLocked}
          />
          <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
            Passenger Injured
          </Typography>
        </Box>

        <Collapse in={isPassengerInjured}>
          <Box sx={{ mt: 2 }}>
            {passengerEntries.map((entry, index) => (
              <Box key={index} sx={{
                mb: 2,
                bgcolor: isDarkMode ? '#363636' : '#ffffff',
                p: 2,
                borderRadius: 1,
                border: isDarkMode ? 'none' : '1px solid #e0e0e0'
              }}>
                <PassengerEntry
                  entry={entry}
                  onChange={handlePassengerChange}
                  onRemove={() => handleRemovePassenger(entry.id)}
                  disabled={isSubmitted || isLocked}
                  error={!!errors.passengerName}
                  helperText={errors.passengerName}
                  passengerNumber={index + 1}
                />
              </Box>
            ))}

            {/* Add Passenger Button */}
            {!isSubmitted && (
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddPassenger}
                disabled={isSubmitted || isLocked}
                sx={{
                  color: isDarkMode ? '#808080' : '#3b536f',
                  '&:hover': {
                    backgroundColor: isDarkMode ? 'rgba(128, 128, 128, 0.08)' : 'rgba(59, 83, 111, 0.08)'
                  }
                }}
              >
                Add Passenger
              </Button>
            )}
          </Box>
        </Collapse>
      </Box>

      {/* Incident/Accident Level Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2
        }}>
          <Typography sx={{
            color: isDarkMode ? 'white' : '#333333',
            fontWeight: 'bold',
            minWidth: 'fit-content'
          }}>
            Incident/Accident Level
          </Typography>

          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flexGrow: 1,
            justifyContent: 'flex-end'
          }}>
            {/* Actual Level Selection */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{
                color: isDarkMode ? 'white' : '#333333',
                fontSize: '0.875rem'
              }}>
                Actual Level
              </Typography>
              <StyledToggleButtonGroup
                value={ActualLevel}
                exclusive
                onChange={(e, newLevel) => { !isSubmitted && setActualLevel(newLevel) }}
                disabled={isSubmitted || isLocked}
                size="small"
              >
                {levelOptions.map((level) => (
                  <ToggleButton
                    key={level}
                    value={level}
                    sx={{
                      px: 1,
                      minWidth: '32px',
                      fontSize: '0.75rem',
                      color: isDarkMode ? 'white' : '#333333',
                    }}
                  >
                    {level}
                  </ToggleButton>
                ))}
              </StyledToggleButtonGroup>
            </Box>

            {/* Probability Level Selection */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{
                color: isDarkMode ? 'white' : '#333333',
                fontSize: '0.875rem'
              }}>
                Probability Level
              </Typography>
              <StyledToggleButtonGroup
                value={damageLevel1}
                exclusive
                onChange={(e, newLevel) => { !isSubmitted && setDamageLevel1(newLevel) }}
                disabled={isSubmitted || isLocked}
                size="small"
              >
                {levelOptions.map((level) => (
                  <ToggleButton
                    key={level}
                    value={level}
                    sx={{
                      px: 1,
                      minWidth: '32px',
                      fontSize: '0.75rem',
                      color: isDarkMode ? 'white' : '#333333',
                    }}
                  >
                    {level}
                  </ToggleButton>
                ))}
              </StyledToggleButtonGroup>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Submit Button */}
      <Box sx={{ textAlign: 'right', mt: 2 }}>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitted || isLocked || isLoading}
          sx={{
            backgroundColor: isDarkMode ? '#4CAF50' : '#3b536f',
            '&:hover': {
              backgroundColor: isDarkMode ? '#45a049' : '#2f4259'
            }
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : Boolean(reassigned) ? 'Update' : 'Save'}
        </Button>
      </Box>
    </div>
  );
};

export default FirstParty;