import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Box,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  DirectionsBus,
  Assignment,
  Person,
  Brush,
  Warning,
  Description,
  Security,
  AssignmentTurnedIn,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../store/ThemeContext";
import { lightTheme, darkTheme } from "../../styles/theme";
import { useContext, useMemo, useEffect, useState, createElement } from "react";

const createAppTheme = (isDarkMode) =>
  createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
      background: {
        default: isDarkMode ? darkTheme.primary : lightTheme.primary,
        paper: isDarkMode ? darkTheme.secondary : lightTheme.secondary,
      },
      text: {
        primary: isDarkMode ? darkTheme.textPrimary : lightTheme.textPrimary,
        secondary: isDarkMode ? darkTheme.textSecondary : lightTheme.textSecondary,
      },
      grey: {
        200: isDarkMode ? darkTheme.tertiary : lightTheme.tertiary,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

const StyledCard = styled(Card)(({ theme, isDarkMode }) => ({
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s ease-in-out",
  cursor: "pointer",
  backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
  borderRadius: "12px",
  border: `1px solid ${
    isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"
  }`,
  boxShadow: isDarkMode
    ? "0 4px 6px rgba(0, 0, 0, 0.4)"
    : "0 4px 6px rgba(0, 0, 0, 0.1)",
  position: "relative",
  padding: theme.spacing(2),

  [theme.breakpoints.up("xs")]: {
    minHeight: "160px",
  },
  [theme.breakpoints.up("sm")]: {
    minHeight: "180px",
  },
  [theme.breakpoints.up("md")]: {
    minHeight: "200px",
  },

  "&:hover": {
    transform: "translateY(-8px)",
    boxShadow: isDarkMode
      ? "0 8px 16px rgba(26, 180, 52, 0.2)"
      : "0 8px 16px rgba(13, 110, 253, 0.15)",
    backgroundColor: isDarkMode ? "#262626" : "#ffffff",
    zIndex: 1,
  },
}));

const IconWrapper = styled("div")(({ theme, isDarkMode }) => ({
  borderRadius: "50%",
  backgroundColor: isDarkMode
    ? "rgba(26, 180, 52, 0.15)"
    : "rgba(13, 110, 253, 0.1)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: theme.spacing(1.5),
  color: isDarkMode ? "#1ab434" : "#0d6efd",
  margin: "0 auto",
  marginBottom: theme.spacing(2),
  transition: "all 0.3s ease-in-out",

  [theme.breakpoints.up("xs")]: {
    width: 50,
    height: 50,
    "& > svg": { fontSize: 24 },
  },
  [theme.breakpoints.up("sm")]: {
    width: 56,
    height: 56,
    "& > svg": { fontSize: 28 },
  },
  [theme.breakpoints.up("md")]: {
    width: 60,
    height: 60,
    "& > svg": { fontSize: 30 },
  },
}));

const Operations = () => {
  const { isDarkMode } = useContext(ThemeContext);
  const theme = useMemo(() => createAppTheme(isDarkMode), [isDarkMode]);
  const navigate = useNavigate();
  const [userGroup, setUserGroup] = useState(null);

  useEffect(() => {
    // Get the user group from sessionStorage when the component mounts
    const storedUserGroup = sessionStorage.getItem("UserGroup");
    setUserGroup(storedUserGroup);
  }, []);

  const getOperationCards = () => {
    switch (userGroup) {
      case "Supervisor":
        return [
          {
            icon: DirectionsBus,
            title: "In-out shedding",
            description: "Record vehicle movements",
            link: "#",
          },
          {
            icon: Assignment,
            title: "Charging Record",
            description: "Track charging activities",
            link: "#",
          },
          {
            icon: Person,
            title: "Pilot Monitoring",
            description: "Track driver performance",
            link: "#",
          },
          {
            icon: Brush,
            title: "Vehicle Cleaning",
            description: "Maintenance records",
            link: "#",
          },
          {
            icon: Description,
            title: "Incident / Accident",
            // description: "Daily driver records",
            link: "/operations/supervisorIncident",
          },
          {
            icon: AssignmentTurnedIn,
            title: "Inspection Check List",
            description: "Vehicle inspection forms",
            link: "#",
          },
        ];
      case "Safety Supervisor":
        return [
          {
            icon: DirectionsBus,
            title: "In-out shedding",
            description: "Record vehicle movements",
            link: "#",
          },
          {
            icon: Assignment,
            title: "Charging Record",
            description: "Track charging activities",
            link: "#",
          },
          {
            icon: Person,
            title: "Pilot Monitoring",
            description: "Track driver performance",
            link: "#",
          },
          {
            icon: Brush,
            title: "Vehicle Cleaning",
            description: "Maintenance records",
            link: "#",
          },
          {
            icon: Warning,
            title: "Incident / Accident",
            // description: "Log safety issues",
            link: "/operations/safetysupervisor/Incident",
          },
          {
            icon: AssignmentTurnedIn,
            title: "Inspection Check List",
            description: "Vehicle inspection forms",
            link: "#",
          },
        ];
      case "Safety Incharge":
        return [
          {
            icon: DirectionsBus,
            title: "In-out shedding",
            description: "Record vehicle movements",
            link: "#",
          },
          {
            icon: Assignment,
            title: "Charging Record",
            description: "Track charging activities",
            link: "#",
          },
          {
            icon: Person,
            title: "Pilot Monitoring",
            description: "Track driver performance",
            link: "#",
          },
          {
            icon: Brush,
            title: "Vehicle Cleaning",
            description: "Maintenance records",
            link: "#",
          },
          {
            icon: Security,
            title: "Incident / Accident",
            // description: "Track safety metrics",
            link: "/operations/safetyincharge",
          },
          {
            icon: AssignmentTurnedIn,
            title: "Inspection Check List",
            description: "Vehicle inspection forms",
            link: "#",
          },
        ];
        case "Safety Head":
        return [
          {
            icon: DirectionsBus,
            title: "In-out shedding",
            description: "Record vehicle movements",
            link: "#",
          },
          {
            icon: Assignment,
            title: "Charging Record",
            description: "Track charging activities",
            link: "#",
          },
          {
            icon: Person,
            title: "Pilot Monitoring",
            description: "Track driver performance",
            link: "#",
          },
          {
            icon: Brush,
            title: "Vehicle Cleaning",
            description: "Maintenance records",
            link: "#",
          },
          {
            icon: Security,
            title: "Incident / Accident",
            // description: "Track safety metrics",
            link: "/operations/safetyhead/incident",
          },
          {
            icon: AssignmentTurnedIn,
            title: "Inspection Check List",
            description: "Vehicle inspection forms",
            link: "#",
          },
        ];
      default:
        return []; // Return an empty array if the user group is not recognized
    }
  };

  const operationCards = useMemo(getOperationCards, [userGroup]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          backgroundColor: isDarkMode ? "#1a1a1a" : "#f5f5f5",
          minHeight: "100vh",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: { xs: 2, sm: 3, md: 4 },
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "stretch",
            }}
          >
            {operationCards.map((card, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                key={index}
                sx={{
                  display: "flex",
                }}
              >
                <StyledCard
                  onClick={() => navigate(card.link)}
                  isDarkMode={isDarkMode}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      height: "100%",
                      padding: "16px !important",
                    }}
                  >
                    <IconWrapper isDarkMode={isDarkMode}>
                      {createElement(card.icon)}
                    </IconWrapper>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{
                        fontWeight: 600,
                        fontSize: {
                          xs: "1rem",
                          sm: "1.1rem",
                          md: "1.2rem",
                        },
                        mb: 1,
                        color: isDarkMode ? "#ffffff" : "#2c3e50",
                      }}
                    >
                      {card.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: {
                          xs: "0.875rem",
                          sm: "0.9rem",
                          md: "0.95rem",
                        },
                        color: isDarkMode
                          ? "rgba(255, 255, 255, 0.7)"
                          : "rgba(0, 0, 0, 0.6)",
                        lineHeight: 1.5,
                      }}
                    >
                      {card.description}
                    </Typography>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Operations;
