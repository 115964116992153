import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  IconButton,
  TextField,
  Button,
  Typography,
  styled,
  Switch as MuiSwitch,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
  Collapse,
  Grid
} from '@mui/material';
import {
  Close as CloseIcon,
  Add as AddIcon
} from '@mui/icons-material';
import axios from 'axios';
import { ThemeContext } from '../../../store/ThemeContext';
import { toast } from 'react-toastify';
import {
  getThirdPartyIncidentDetails,
  updateThirdPartyIncidentDetails,
  createThirdPartyIncidentDetails
} from '../../../API/IncidentAPI';

const SectionHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#333333',
  color: '#00ffff',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  textAlign: 'left',
  marginTop: theme.spacing(2)
}));

const ThirdParty = ({ incidentId, isLocked, reassigned }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [isVehicleDamaged, setIsVehicleDamaged] = useState(false);
  const [isIndividualDamaged, setIsIndividualDamaged] = useState(false);
  const [isPropertyDamaged, setIsPropertyDamaged] = useState(false);


  const [vehicleDamages, setVehicleDamages] = useState([]);
  const [individualDamages, setIndividualDamages] = useState([]);
  const [propertyDamages, setPropertyDamages] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [thirdPartyId, setThirdPartyId] = useState(null);
  const defaultVehicleEntry = { id: 0, vehicleType: '', vehicleNo: '', insurance: '', severityLevel: 'A', description: '', isDefault: true };
  const defaultIndividualEntry = { id: 0, name: '', description: '', gender: '', age: '', identityCardNo: '', severityLevel: 'A', isDefault: true };
  const defaultPropertyEntry = { id: 0, propertyType: '', name: '', description: '', severityLevel: 'A', isDefault: true };
  const [errors, setErrors] = useState({
    vehicleType: '',
    vehicleNo: '',
    insurance: '',
    individualName: '',
    individualAge: '',
    individualGender: '',
    individualIDNO: '',
    individualDesc: '',
    propertyDesc: '',
    propertyType: '',
  });

  useEffect(() => {
    const fetchThirdPartyData = async () => {
      try {
        setIsLoading(true);
        const responseData = await getThirdPartyIncidentDetails(incidentId);

        if (responseData.data && responseData.data.length > 0) {
          const thirdPartyData = responseData.data[0];
          setThirdPartyId(thirdPartyData.id);

         // console.log("Fetched third party data:", thirdPartyData);

          // First set the switches based on data existence, not just boolean flags
          const hasVehicleDamage = thirdPartyData.vehicledamage ||
            (thirdPartyData.vehicle_damage_details &&
              thirdPartyData.vehicle_damage_details.length > 0);

          const hasIndividualInjured = thirdPartyData.individualinjured ||
            (thirdPartyData.individual_injury_details &&
              thirdPartyData.individual_injury_details.length > 0);

          const hasPropertyDamage = thirdPartyData.propertydamage ||
            (thirdPartyData.property_damage_details &&
              thirdPartyData.property_damage_details.length > 0);

          // Set toggle switches based on data existence
          setIsVehicleDamaged(hasVehicleDamage);
          setIsIndividualDamaged(hasIndividualInjured);
          setIsPropertyDamaged(hasPropertyDamage);
          
        //  console.log("Setting toggles:", hasVehicleDamage, hasIndividualInjured, hasPropertyDamage);

          // Clear any existing data before populating
          setVehicleDamages([]);
          setIndividualDamages([]);
          setPropertyDamages([]);

          // Adapt and set the damage details - preserve backend IDs
          const adaptVehicleDamages = (vehicleDamageDetails) => {
            return vehicleDamageDetails?.map((item) => ({
              id: item.id, // Use the backend ID
              detailId: item.id, // Store backend ID separately
              vehicleType: item.vehicletype || '',
              vehicleNo: item.vehiclenumber || '',
              insurance: item.insuarance || '',
              severityLevel: item.damagelevel || 'A',
              description: item.description || '',
            })) || [];
          };

          const adaptIndividualDamages = (individualInjuryDetails) => {
            return individualInjuryDetails?.map((item) => ({
              id: item.id, // Use the backend ID
              detailId: item.id, // Store backend ID separately
              name: item.name || '',
              description: item.description || '',
              gender: item.gender || '',
              age: item.age || '',
              identityCardNo: item.identityproof || '',
              severityLevel: item.severitylevel || 'A',
            })) || [];
          };

          const adaptPropertyDamages = (propertyDamageDetails) => {
            return propertyDamageDetails?.map((item) => ({
              id: item.id, // Use the backend ID
              detailId: item.id, // Store backend ID separately
              propertyType: item.propertydmg || '',
              description: item.description || '',
              severityLevel: item.damagelevel || 'A',
              name: '',
            })) || [];
          };

          // Always set damage arrays if data exists, regardless of toggle state
          if (thirdPartyData.vehicle_damage_details && thirdPartyData.vehicle_damage_details.length > 0) {
            const vehicleDamageData = adaptVehicleDamages(thirdPartyData.vehicle_damage_details);
           // console.log("Setting vehicle damages:", vehicleDamageData);
            setVehicleDamages(vehicleDamageData.length > 0 ? vehicleDamageData : [defaultVehicleEntry]);
          } else if (hasVehicleDamage) {
            // If toggle is on but no data, add default entry
            setVehicleDamages([defaultVehicleEntry]);
          }

          if (thirdPartyData.individual_injury_details && thirdPartyData.individual_injury_details.length > 0) {
            const individualDamageData = adaptIndividualDamages(thirdPartyData.individual_injury_details);
            //console.log("Setting individual damages:", individualDamageData);
            setIndividualDamages(individualDamageData.length > 0 ? individualDamageData : [defaultIndividualEntry]);
          } else if (hasIndividualInjured) {
            // If toggle is on but no data, add default entry
            setIndividualDamages([defaultIndividualEntry]);
          }

          if (thirdPartyData.property_damage_details && thirdPartyData.property_damage_details.length > 0) {
            const propertyDamageData = adaptPropertyDamages(thirdPartyData.property_damage_details);
            //console.log("Setting property damages:", propertyDamageData);
            setPropertyDamages(propertyDamageData.length > 0 ? propertyDamageData : [defaultPropertyEntry]);
          } else if (hasPropertyDamage) {
            // If toggle is on but no data, add default entry
            setPropertyDamages([defaultPropertyEntry]);
          }

          // Set submitted state based on reassigned flag
          setIsSubmitted(!reassigned);
        } else {
          // If no data, ensure all toggles are off and arrays are empty
          setIsVehicleDamaged(false);
          setIsIndividualDamaged(false);
          setIsPropertyDamaged(false);
          setVehicleDamages([]);
          setIndividualDamages([]);
          setPropertyDamages([]);
          setIsSubmitted(false);
        }
      } catch (error) {
        console.error('Error fetching third-party data:', error);
        toast.error("Error loading third party details");
      } finally {
        setIsLoading(false);
      }
    };

    if (incidentId) {
      fetchThirdPartyData();
    }
  }, [incidentId, reassigned]);

  const handleSubmit = async () => {
    // Reset errors
    setErrors({
      vehicleType: '',
      vehicleNo: '',
      insurance: '',
      individualName: '',
      individualAge: '',
      individualGender: '',
      individualIDNO: '',
      individualDesc: '',
      propertyType: '',
    });

    let hasError = false;

    // Validation - only validate enabled sections
    if (isVehicleDamaged) {
      vehicleDamages.forEach((entry, index) => {
        if (!entry.vehicleType) {
          setErrors(prev => ({ ...prev, vehicleType: 'Vehicle Type is required.' }));
          hasError = true;
        }
        if (!entry.vehicleNo) {
          setErrors(prev => ({ ...prev, vehicleNo: 'Vehicle No. is required.' }));
          hasError = true;
        }
        if (!entry.insurance) {
          setErrors(prev => ({ ...prev, insurance: 'Insurance is required.' }));
          hasError = true;
        }
        if (!entry.description) {
          setErrors(prev => ({ ...prev, description: 'Description is required.' }));
          hasError = true;
        }
      });
    }

    if (isIndividualDamaged) {
      individualDamages.forEach((entry) => {
        if (!entry.name) {
          setErrors(prev => ({ ...prev, individualName: 'Name is required.' }));
          hasError = true;
        }
        if (!entry.age) {
          setErrors(prev => ({ ...prev, individualAge: 'Age is required.' }));
          hasError = true;
        }
        if (!entry.gender) {
          setErrors(prev => ({ ...prev, individualGender: 'Gender is required.' }));
          hasError = true;
        }
        if (!entry.identityCardNo) {
          setErrors(prev => ({ ...prev, individualIDNO: 'ID No is required.' }));
          hasError = true;
        }
        if (!entry.description) {
          setErrors(prev => ({ ...prev, individualDesc: 'Description is required.' }));
          hasError = true;
        }

      });
    }

    if (isPropertyDamaged) {
      propertyDamages.forEach((entry) => {
        if (!entry.propertyType) {
          setErrors(prev => ({ ...prev, propertyType: 'Property Type is required.' }));
          hasError = true;
        }
        if (!entry.description) {
          setErrors(prev => ({ ...prev, propertyDesc: 'Description is required.' }));
          hasError = true;
        }
      });
    }

    if (hasError) {
      toast.error('Please fill in all required fields.');
      return;
    }

    try {
      setIsLoading(true);

      // IMPORTANT: Only include details for enabled sections
      const formData = {
        ...(reassigned === true && thirdPartyId && { id: thirdPartyId }),
        incidentreportingid: incidentId,
        vehicledamage: isVehicleDamaged,
        individualinjured: isIndividualDamaged,
        propertydamage: isPropertyDamaged,

        // Only include vehicle damages if that section is enabled
        ...(isVehicleDamaged && {
          vehicledamagedetails: vehicleDamages.map(item => ({
            ...(item.detailId && { id: item.detailId }),
            vehicletype: item.vehicleType || '',
            vehiclenumber: item.vehicleNo || '',
            insuarance: item.insurance || '',
            damagelevel: item.severityLevel || 'A',
            description: item.description || '',
          }))
        }),

        // Only include individual damages if that section is enabled
        ...(isIndividualDamaged && {
          individualinjureddetails: individualDamages.map(item => ({
            ...(item.detailId && { id: item.detailId }),
            name: item.name || '',
            description: item.description || '',
            gender: item.gender || '',
            age: item.age || '',
            identityproof: item.identityCardNo || '',
            severitylevel: item.severityLevel || 'A',
          }))
        }),

        // Only include property damages if that section is enabled
        ...(isPropertyDamaged && {
          propertydamagedetails: propertyDamages.map(item => ({
            ...(item.detailId && { id: item.detailId }),
            propertydmg: item.propertyType || '',
            description: item.description || '',
            damagelevel: item.severityLevel || 'A',
          }))
        }),
      };

     // console.log('Submitting form data:', formData);

      if (reassigned === true) {
        await updateThirdPartyIncidentDetails(formData);
        toast.success("Third party details updated successfully!");
      } else {
        await createThirdPartyIncidentDetails(formData);
        toast.success("Third party details saved successfully!");
      }

      // Lock the form after successful submission
      setIsSubmitted(true);

    } catch (error) {
      console.error('Error saving third-party details:', error);
      toast.error(`Error ${reassigned === true ? 'updating' : 'saving'} third party details.`);
      setIsSubmitted(false);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle input changes
  const handleInputChange = (type, index, field, value) => {
    const newValue = String(value); // Ensure value is a string

    switch (type) {
      case 'vehicle':
        setVehicleDamages(prev => {
          console.log("Previous state:", prev);
          const newVehicleDamages = [...prev];
          newVehicleDamages[index] = { ...newVehicleDamages[index], [field]: newValue };
          console.log("New state:", newVehicleDamages);
          return newVehicleDamages;
        });
        break;
      case 'individual':
        setIndividualDamages(prev => {
          const newIndividualDamages = [...prev];
          newIndividualDamages[index] = { ...newIndividualDamages[index], [field]: newValue };
          return newIndividualDamages;
        });
        break;
      case 'property':
        setPropertyDamages(prev => {
          const newPropertyDamages = [...prev];
          newPropertyDamages[index] = { ...newPropertyDamages[index], [field]: newValue };
          return newPropertyDamages;
        });
        break;
      default:
        console.warn('Invalid damage type');
    }
  };

  // Modified add damage function
  const handleAddDamage = (type) => {
    switch (type) {
      case 'vehicle':
        setVehicleDamages(prev => [...prev, {
          id: Date.now(), // Use timestamp for new entries
          detailId: null, // No backend ID for new entries
          vehicleType: '',
          vehicleNo: '',
          insurance: '',
          severityLevel: 'A',
          description: '',
          isDefault: false
        }]);
        break;
      case 'individual':
        setIndividualDamages(prev => [...prev, {
          id: Date.now(), // Use timestamp for new entries
          detailId: null, // No backend ID for new entries
          name: '',
          description: '',
          gender: '',
          age: '',
          identityCardNo: '',
          severityLevel: 'A',
          isDefault: false
        }]);
        break;
      case 'property':
        setPropertyDamages(prev => [...prev, {
          id: Date.now(), // Use timestamp for new entries
          detailId: null, // No backend ID for new entries
          propertyType: '',
          name: '',
          description: '',
          severityLevel: 'A',
          isDefault: false
        }]);
        break;
      default:
        console.warn('Invalid damage type');
    }
  };

  // Modified remove damage function to prevent removing default entries
  const handleRemoveDamage = (type, index) => {
    switch (type) {
      case 'vehicle':
        if (!vehicleDamages[index].isDefault) {
          setVehicleDamages(prev => prev.filter((_, i) => i !== index));
        }
        break;
      case 'individual':
        if (!individualDamages[index].isDefault) {
          setIndividualDamages(prev => prev.filter((_, i) => i !== index));
        }
        break;
      case 'property':
        if (!propertyDamages[index].isDefault) {
          setPropertyDamages(prev => prev.filter((_, i) => i !== index));
        }
        break;
      default:
        console.warn('Invalid damage type');
    }
  };

  // Function to handle toggle changes
  const handleToggleChange = (type) => {
    switch (type) {
      case 'vehicle':
        setIsVehicleDamaged((prev) => {
          const newValue = !prev;
          if (newValue) {
            // If toggled on, ensure vehicle damages are shown
            setVehicleDamages((prevDamages) => prevDamages.length > 0 ? prevDamages : [defaultVehicleEntry]);
          }
          return newValue;
        });
        break;
      case 'individual':
        setIsIndividualDamaged((prev) => {
          const newValue = !prev;
          if (newValue) {
            // If toggled on, ensure individual damages are shown
            setIndividualDamages((prevDamages) => prevDamages.length > 0 ? prevDamages : [defaultIndividualEntry]);
          }
          return newValue;
        });
        break;
      case 'property':
        setIsPropertyDamaged((prev) => {
          const newValue = !prev;
          if (newValue) {
            // If toggled on, ensure property damages are shown
            setPropertyDamages((prevDamages) => prevDamages.length > 0 ? prevDamages : [defaultPropertyEntry]);
          }
          return newValue;
        });
        break;
      default:
        break;
    }
  };

  // Add these styled components at the top of your file
  const StyledTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#333333',
      '& fieldset': {
        borderColor: '#555555',
      },
      '&:hover fieldset': {
        borderColor: '#666666',
      },
      '& input': {
        color: 'white',
      },
      '& textarea': {
        color: 'white',
      },
    },
    '& .MuiInputLabel-root': {
      color: 'white',
    },
  });

  const StyledSelect = styled(Select)({
    backgroundColor: '#333333',
    color: 'white',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#555555',
    },
  });

  const StyledFormControl = styled(FormControl)({
    '& .MuiInputLabel-root': {
      color: 'white',
    },
  });

  const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: isDarkMode => isDarkMode ? '#363636' : '#ffffff',
    marginRight: '30px',
    borderRadius: theme.shape.borderRadius,
    border: isDarkMode => isDarkMode ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
    '& .MuiToggleButton-root': {
      color: isDarkMode => isDarkMode ? '#808080' : '#666666',
      border: '1px solid #e0e0e0',
      padding: '6px 16px',
      minWidth: '40px',
      height: '32px',
      fontSize: '0.875rem',
      '&.Mui-selected': {
        backgroundColor: '#0d6efd',
        color: 'white',
        '&:hover': {
          backgroundColor: '#0d6efd',
          opacity: 0.9
        }
      },
      '&:hover': {
        backgroundColor: isDarkMode =>
          isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(59, 83, 111, 0.08)'
      },
      '&:not(:first-of-type)': {
        borderLeft: isDarkMode => isDarkMode ? 'none' : '1px solid #e0e0e0'
      }
    }
  }));

  const EntryContainer = styled(Box)(({ theme }) => ({
    backgroundColor: '#1E1E1E',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }));

  const sharedSx = {
    bgcolor: '#2C2C2C',
    '& .MuiOutlinedInput-root': {
      color: '#808080',
      '& fieldset': { borderColor: 'transparent' },
      '&:hover fieldset': { borderColor: 'transparent' },
      '&.Mui-focused fieldset': { borderColor: 'transparent' },
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: '#808080',
      WebkitTextFillColor: '#808080'
    },
    '& .MuiInputLabel-root': {
      color: '#808080'
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: '#808080'
    },
    '& .MuiInputLabel-root.Mui-disabled': {
      color: '#808080'
    }
  };

  const severityLevels = ['A', 'B', 'C', 'D', 'Fatal'];

  return (
    <div style={{
      backgroundColor: isDarkMode ? '#1E1E1E' : '#f5f7fa',
      padding: '20px'
    }}>
      <Box sx={{
        p: 0.5,
        mb: 2,
        borderRadius: 1,
      }}>
        <Typography variant="subtitle1" gutterBottom sx={{
          color: isDarkMode ? '#808080' : '#333333',
          fontWeight: 500
        }}>
          Third Party Details
        </Typography>
      </Box>

      {/* Vehicle Damaged Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <MuiSwitch
            checked={isVehicleDamaged}
            onChange={() => handleToggleChange('vehicle')}
            disabled={isSubmitted || isLocked}
          />
          <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
            Vehicle Damage
          </Typography>
        </Box>

        <Collapse in={isVehicleDamaged || (isSubmitted && vehicleDamages.length > 0)}>
          {vehicleDamages.map((entry, index) => (
            <Box key={index} sx={{
              mb: 2,
              bgcolor: isDarkMode ? '#363636' : '#ffffff',
              p: 2,
              borderRadius: 1,
              border: isDarkMode ? 'none' : '1px solid #e0e0e0'
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 4,
                position: 'relative'
              }}>
                <Typography sx={{ color: isDarkMode ? '#00ffff' : '#3b536f' }}>
                  Vehicle {index + 1}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                    Damage Level
                  </Typography>
                  <StyledToggleButtonGroup
                    value={entry.severityLevel}
                    exclusive
                    size="small"
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        handleInputChange('vehicle', index, 'severityLevel', newValue);
                      }
                    }}
                    disabled={isSubmitted || isLocked}
                  >
                    {severityLevels.map((level) => (
                      <ToggleButton key={level} value={level} sx={{ color: isDarkMode ? 'white' : '#333333', }}>
                        {level}
                      </ToggleButton>
                    ))}
                  </StyledToggleButtonGroup>
                </Box>
                {!isSubmitted && !entry.isDefault && (
                  <IconButton
                    onClick={() => handleRemoveDamage('vehicle', index)}
                    sx={{
                      position: 'absolute',
                      right: -12,
                      top: -12,
                      backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                      color: isDarkMode ? '#808080' : '#666666',
                      border: isDarkMode ? 'none' : '1px solid #e0e0e0',
                      '&:hover': {
                        backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                        color: 'red'
                      },
                      padding: '4px'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Vehicle Type"
                    value={entry.vehicleType}
                    onChange={(e) => handleInputChange('vehicle', index, 'vehicleType', e.target.value)}
                    variant="outlined"
                    disabled={isSubmitted || isLocked}
                    error={!!errors.vehicleType}
                    helperText={errors.vehicleType}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                        color: isDarkMode ? 'white' : '#666666',
                        '& fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                        },
                        '&:hover fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-disabled': {
                          '& input, & textarea': {
                            color: isDarkMode ? 'white !important' : '#666666 !important',
                            '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                          }
                        },
                        '& input:-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                          WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                        }
                      },
                      '& .MuiInputLabel-root': {
                        color: isDarkMode ? 'white' : '#666666'
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: isDarkMode ? 'white' : '#3b536f'
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Vehicle No."
                    value={entry.vehicleNo}
                    onChange={(e) => handleInputChange('vehicle', index, 'vehicleNo', e.target.value)}
                    variant="outlined"
                    disabled={isSubmitted || isLocked}
                    error={!!errors.vehicleNo}
                    helperText={errors.vehicleNo}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                        color: isDarkMode ? 'white' : '#666666',
                        '& fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                        },
                        '&:hover fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-disabled': {
                          '& input, & textarea': {
                            color: isDarkMode ? 'white !important' : '#666666 !important',
                            '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                          }
                        },
                        '& input:-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                          WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                        }
                      },
                      '& .MuiInputLabel-root': {
                        color: isDarkMode ? 'white' : '#666666'
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: isDarkMode ? 'white' : '#3b536f'
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Insurance"
                    value={entry.insurance}
                    onChange={(e) => handleInputChange('vehicle', index, 'insurance', e.target.value)}
                    variant="outlined"
                    disabled={isSubmitted || isLocked}
                    error={!!errors.insurance}
                    helperText={errors.insurance}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                        color: isDarkMode ? 'white' : '#666666',
                        '& fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                        },
                        '&:hover fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-disabled': {
                          '& input, & textarea': {
                            color: isDarkMode ? 'white !important' : '#666666 !important',
                            '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                          }
                        },
                        '& input:-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                          WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                        }
                      },
                      '& .MuiInputLabel-root': {
                        color: isDarkMode ? 'white' : '#666666'
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: isDarkMode ? 'white' : '#3b536f'
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    multiline
                    rows={2}
                    value={entry.description}
                    onChange={(e) => handleInputChange('vehicle', index, 'description', e.target.value)}
                    variant="outlined"
                    disabled={isSubmitted || isLocked}
                    error={!!errors.description}
                    helperText={errors.description}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                        color: isDarkMode ? 'white' : '#666666',
                        '& fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                        },
                        '&:hover fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: isDarkMode ? 'transparent' : '#3b536f'
                        },
                        '&.Mui-disabled': {
                          '& input, & textarea': {
                            color: isDarkMode ? 'white !important' : '#666666 !important',
                            '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                          }
                        },
                        '& input:-webkit-autofill': {
                          WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                          WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                        }
                      },
                      '& .MuiInputLabel-root': {
                        color: isDarkMode ? 'white' : '#666666'
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: isDarkMode ? 'white' : '#3b536f'
                      },
                      '& .MuiInputLabel-root.Mui-disabled': {
                        color: isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}

          {!isSubmitted && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddDamage('vehicle')}
              disabled={isSubmitted || isLocked}
              sx={{
                color: isDarkMode ? '#808080' : '#3b536f',
                '&:hover': {
                  backgroundColor: isDarkMode ? 'rgba(128, 128, 128, 0.08)' : 'rgba(59, 83, 111, 0.08)'
                }
              }}
            >
              Add Vehicle
            </Button>
          )}
        </Collapse>
      </Box>

      {/* Individual Injured Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, }}>
          <MuiSwitch
            checked={isIndividualDamaged}
            onChange={() => handleToggleChange('individual')}
            disabled={isSubmitted || isLocked}
          />
          <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>Individual Injured</Typography>
        </Box>

        <Collapse in={isIndividualDamaged || (isSubmitted && individualDamages.length > 0)}>
          {individualDamages.map((entry, index) => (
            <Box key={index} sx={{
              mb: 2,
              bgcolor: isDarkMode ? '#363636' : '#ffffff',
              p: 2,
              borderRadius: 1,
              border: isDarkMode ? 'none' : '1px solid #e0e0e0'
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                position: 'relative'
              }}>
                <Typography sx={{ color: isDarkMode ? '#00ffff' : '#3b536f' }}>Individual {index + 1}</Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333', mr: 1 }}>Severity Level</Typography>
                  <StyledToggleButtonGroup
                    value={entry.severityLevel}
                    exclusive
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        handleInputChange('individual', index, 'severityLevel', newValue);
                      }
                    }}
                    disabled={isSubmitted || isLocked}
                    size="small"
                  >
                    {['A', 'B', 'C', 'D', 'Fatal'].map((level) => (
                      <ToggleButton
                        key={level}
                        value={level}
                        sx={{
                          minWidth: '40px',
                          fontSize: '0.875rem',
                          color: isDarkMode ? 'white' : '#333333',
                        }}
                      >
                        {level}
                      </ToggleButton>
                    ))}
                  </StyledToggleButtonGroup>
                </Box>
                {!isSubmitted && !entry.isDefault && (
                  <IconButton
                    onClick={() => handleRemoveDamage('individual', index)}
                    sx={{
                      position: 'absolute',
                      right: -12,
                      top: -12,
                      backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                      color: isDarkMode ? '#808080' : '#666666',
                      border: isDarkMode ? 'none' : '1px solid #e0e0e0',
                      '&:hover': {
                        backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                        color: 'red'
                      },
                      padding: '4px',
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 2, mb: 2 }}>
                <TextField
                  fullWidth
                  label="Name"
                  value={entry.name}
                  onChange={(e) => handleInputChange('individual', index, 'name', e.target.value)}
                  disabled={isSubmitted || isLocked}
                  error={!!errors.individualName}
                  helperText={errors.individualName}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                      color: isDarkMode ? 'white' : '#666666',
                      '& fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                      },
                      '&:hover fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-disabled': {
                        '& input, & textarea': {
                          color: isDarkMode ? 'white !important' : '#666666 !important',
                          '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                        }
                      },
                      '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                        WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                      }
                    },
                    '& .MuiInputLabel-root': {
                      color: isDarkMode ? 'white' : '#666666'
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: isDarkMode ? 'white' : '#3b536f'
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                      color: isDarkMode ? 'white !important' : '#666666 !important'
                    }
                  }}
                />
                <TextField
                  fullWidth
                  label="Age"
                  value={entry.age}
                  error={!!errors.individualAge}
                  helperText={errors.individualAge}
                  disabled={isSubmitted || isLocked}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Only allow positive numbers between 1 and 100
                    if (/^\d*$/.test(value) && (value === '' || (value >= 1 && value <= 100))) {
                      handleInputChange('individual', index, 'age', value);
                     
                    } 
                  }}
                  onKeyDown={(e) => {
                    // Prevent 'e', '-', '+' and other non-numeric keys
                    if (['e', 'E', '-', '+'].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  type="number"
                  inputProps={{
                    min: 1, // Prevent negative values in browsers that support it
                    max: 100, // Set maximum value
                    inputMode: 'numeric', // Show numeric keyboard on mobile
                    pattern: '[0-9]*' // HTML5 validation pattern (digits only)
                  }}
                  
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                      color: isDarkMode ? 'white' : '#333333',
                      '& fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                      },
                      '&:hover fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-disabled': {
                        '& input, & textarea': {
                          color: isDarkMode ? 'white !important' : '#666666 !important',
                          '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                        }
                      },
                      '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                        WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                      }
                    },
                    '& .MuiInputLabel-root': {
                      color: isDarkMode ? 'white' : '#666666'
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: isDarkMode ? 'white' : '#3b536f'
                    },
                    '&.Mui-disabled': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '& .MuiSelect-select': {
                        color: isDarkMode ? 'white !important' : '#666666 !important',
                        '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }
                  }}
                />
                <FormControl fullWidth
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                      color: isDarkMode ? 'white' : '#333333',
                      '& fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                      },
                      '&:hover fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-disabled': {
                        color: isDarkMode ? 'white !important' : '#666666 !important',
                        '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                      },
                      '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                        WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                      }
                    },
                    '& .MuiSelect-select': {
                      color: isDarkMode ? 'white' : '#333333',
                    },
                    '& .MuiSelect-select.Mui-disabled': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                    },
                    '& .MuiInputLabel-root': {
                      color: isDarkMode ? 'white' : '#666666'
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: isDarkMode ? 'white' : '#3b536f'
                    },
                    '& .MuiInputLabel-root.Mui-disabled': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                    }
                  }}
                  disabled={isSubmitted || isLocked}>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    value={entry.gender}
                    error={!!errors.individualGender}
                    helperText={errors.individualGender}
                    onChange={(e) => handleInputChange('individual', index, 'gender', e.target.value)}
                    label="Gender"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                          '& .MuiMenuItem-root': {
                            color: isDarkMode ? 'white' : '#333333',
                            '&:hover': {
                              bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)'
                            }
                          }
                        }
                      }
                    }}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                    <MenuItem value="Not Specified">Not Specified</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  label="Identity Card No"
                  value={entry.identityCardNo}
                  error={!!errors.individualIDNO}
                  helperText={errors.individualIDNO}
                  onChange={(e) => handleInputChange('individual', index, 'identityCardNo', e.target.value)}
                  disabled={isSubmitted || isLocked}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                      color: isDarkMode ? 'white' : '#333333',
                      '& fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                      },
                      '&:hover fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-disabled': {
                        '& input, & textarea': {
                          color: isDarkMode ? 'white !important' : '#666666 !important',
                          '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                        }
                      },
                      '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                        WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                      }
                    },
                    '& .MuiInputLabel-root': {
                      color: isDarkMode ? 'white' : '#666666'
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: isDarkMode ? 'white' : '#3b536f'
                    },
                    '&.Mui-disabled': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '& .MuiSelect-select': {
                        color: isDarkMode ? 'white !important' : '#666666 !important',
                        '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }
                  }}
                />
              </Box>
              <TextField
                fullWidth
                label="Description"
                multiline
                rows={2}
                value={entry.description}
                error={!!errors.individualDesc}
                helperText={errors.individualDesc}
                onChange={(e) => handleInputChange('individual', index, 'description', e.target.value)}
                disabled={isSubmitted || isLocked}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                    color: isDarkMode ? 'white' : '#333333',
                    '& fieldset': {
                      borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                    },
                    '&:hover fieldset': {
                      borderColor: isDarkMode ? 'transparent' : '#3b536f'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: isDarkMode ? 'transparent' : '#3b536f'
                    },
                    '&.Mui-disabled': {
                      '& input, & textarea': {
                        color: isDarkMode ? 'white !important' : '#666666 !important',
                        '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    },
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                      WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                    }
                  },
                  '& .MuiInputLabel-root': {
                    color: isDarkMode ? 'white' : '#666666'
                  },
                  '& .MuiInputLabel-root.Mui-focused': {
                    color: isDarkMode ? 'white' : '#3b536f'
                  },
                  '&.Mui-disabled': {
                    color: isDarkMode ? 'white !important' : '#666666 !important',
                    '& .MuiSelect-select': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                    }
                  }
                }}
              />
            </Box>
          ))}
          {!isSubmitted && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddDamage('individual')}
              disabled={isSubmitted || isLocked}
              sx={{
                color: isDarkMode ? '#808080' : '#3b536f',
                '&:hover': {
                  backgroundColor: isDarkMode ? 'rgba(128, 128, 128, 0.08)' : 'rgba(59, 83, 111, 0.08)'
                }
              }}
            >
              Add Individual
            </Button>
          )}
        </Collapse>
      </Box>

      {/* Property Damaged Section */}
      <Box sx={{
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, }}>
          <MuiSwitch
            checked={isPropertyDamaged}
            onChange={(e) => {
              const checked = e.target.checked;
              setIsPropertyDamaged(checked);
              if (checked) {
                // If toggled on, ensure property damages are shown
                setPropertyDamages((prevDamages) => prevDamages.length > 0 ? prevDamages : [defaultPropertyEntry]);
              }
            }}
            disabled={isSubmitted || isLocked}
          />
          <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>Property Damaged</Typography>
        </Box>

        <Collapse in={isPropertyDamaged || (isSubmitted && propertyDamages.length > 0)}>
          {propertyDamages.map((entry, index) => (
            <Box key={index} sx={{
              mb: 2,
              bgcolor: isDarkMode ? '#363636' : '#ffffff',
              p: 2,
              borderRadius: 1,
              border: isDarkMode ? 'none' : '1px solid #e0e0e0'
            }}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 2,
                position: 'relative'
              }}>
                <Typography sx={{ color: isDarkMode ? '#00ffff' : '#3b536f' }}>Property {index + 1}</Typography>

                {!isSubmitted && !entry.isDefault && (
                  <IconButton
                    onClick={() => handleRemoveDamage('property', index)}
                    sx={{
                      position: 'absolute',
                      right: -12,
                      top: -12,
                      backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                      color: isDarkMode ? '#808080' : '#666666',
                      border: isDarkMode ? 'none' : '1px solid #e0e0e0',
                      '&:hover': {
                        backgroundColor: isDarkMode ? '#363636' : '#ffffff',
                        color: 'red'
                      },
                      padding: '4px'
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>

              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, mb: 2, mt: 2 }}>
                <TextField
                  fullWidth
                  label="Property Damaged"
                  value={entry.propertyType}
                  onChange={(e) => handleInputChange('property', index, 'propertyType', e.target.value)}
                  disabled={isSubmitted || isLocked}
                  error={!!errors.propertyType}
                  helperText={errors.propertyType}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                      color: isDarkMode ? 'white' : '#333333',
                      '& fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                      },
                      '&:hover fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-disabled': {
                        '& input, & textarea': {
                          color: isDarkMode ? 'white !important' : '#666666 !important',
                          '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                        }
                      },
                      '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                        WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                      }
                    },
                    '& .MuiInputLabel-root': {
                      color: isDarkMode ? 'white' : '#666666'
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: isDarkMode ? 'white' : '#3b536f'
                    },
                    '&.Mui-disabled': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '& .MuiSelect-select': {
                        color: isDarkMode ? 'white !important' : '#666666 !important',
                        '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }
                  }}
                />
                <TextField
                  fullWidth
                  label="Description"
                  value={entry.description}
                  error={!!errors.propertyDesc}
                  helperText={errors.propertyDesc}
                  onChange={(e) => handleInputChange('property', index, 'description', e.target.value)}
                  disabled={isSubmitted || isLocked}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
                      color: isDarkMode ? 'white' : '#333333',
                      '& fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
                      },
                      '&:hover fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: isDarkMode ? 'transparent' : '#3b536f'
                      },
                      '&.Mui-disabled': {
                        '& input, & textarea': {
                          color: isDarkMode ? 'white !important' : '#666666 !important',
                          '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                        }
                      },
                      '& input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px #ffffff inset', // Background color
                        WebkitTextFillColor: isDarkMode ? 'white' : '#666666', // Set text color based on mode
                      }
                    },
                    '& .MuiInputLabel-root': {
                      color: isDarkMode ? 'white' : '#666666'
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: isDarkMode ? 'white' : '#3b536f'
                    },
                    '&.Mui-disabled': {
                      color: isDarkMode ? 'white !important' : '#666666 !important',
                      '& .MuiSelect-select': {
                        color: isDarkMode ? 'white !important' : '#666666 !important',
                        '-webkit-text-fill-color': isDarkMode ? 'white !important' : '#666666 !important'
                      }
                    }
                  }}
                />
              </Box>

            </Box>
          ))}
          {!isSubmitted && (
            <Button
              startIcon={<AddIcon />}
              onClick={() => handleAddDamage('property')}
              disabled={isSubmitted || isLocked}
              sx={{
                color: isDarkMode ? '#808080' : '#3b536f',
                '&:hover': {
                  backgroundColor: isDarkMode ? 'rgba(128, 128, 128, 0.08)' : 'rgba(59, 83, 111, 0.08)'
                }
              }}
            >
              Add Property
            </Button>
          )}
        </Collapse>
      </Box>

      <Box sx={{ textAlign: 'right', mt: 2 }}>
        {!isSubmitted && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading || isLocked}
            sx={{
              backgroundColor: isDarkMode ? '#4CAF50' : '#3b536f',
              '&:hover': {
                backgroundColor: isDarkMode ? '#45a049' : '#2f4259'
              }
            }}
          >
            {isLoading ? (
              <CircularProgress size={24} />
            ) : reassigned ? (
              'Update'
            ) : (
              'Save'
            )}
          </Button>
        )}
      </Box>
    </div>
  );
};

export default ThirdParty;
