import React, { useState, useEffect, useContext } from 'react';
import {
    Box,
    TextField,
    Button,
    Typography,
    styled,
    Switch as MuiSwitch,
    FormControlLabel,
    Checkbox,
    CircularProgress,
} from '@mui/material';
import axios from 'axios';
import { ThemeContext } from '../../../store/ThemeContext';
import { toast } from 'react-toastify';
import { getRootCauseDetails, updateRootCauseDetails, createRootCauseDetails } from '../../../API/IncidentAPI';

/**
 * Styled Box component for section headers
 */
const SectionHeader = styled(Box)(({ theme }) => ({
    backgroundColor: '#333333',
    color: '#00ffff',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    textAlign: 'left',
    marginTop: theme.spacing(2)
}));

/**
 * RootCause component for analyzing incident root causes
 * Collects data about preventable factors, driver behavior, and police reporting
 * 
 * @param {number} incidentId - ID of the incident being analyzed
 * @param {string} baseUrl - Base URL for API calls
 * @param {Function} onSubmit - Function to call on successful submission
 * @param {Function} onClose - Function to call when closing the form
 * @param {Function} fetchIncidents - Function to refresh incident data
 * @param {boolean} reassigned - Indicates if the root cause is being updated
 */
const RootCause = ({ incidentId, baseUrl, onSubmit, onClose, fetchIncidents, reassigned }) => {
    const { isDarkMode } = useContext(ThemeContext);

    // Group 1: Toggle switch states
    const [preventableNo, setPreventableNo] = useState(false);
    const [pilotBehavior, setPilotBehavior] = useState(false);
    const [drivingBehavior, setDrivingBehavior] = useState(false);
    const [blameworthyYes, setBlameworthyYes] = useState(false);
    const [reportedToPolice, setReportedToPolice] = useState(false);

    // Group 2: Preventable details checkboxes
    const [lackOfPatience, setLackOfPatience] = useState(false);
    const [lackOfSpace, setLackOfSpace] = useState(false);
    const [speed, setSpeed] = useState(false);
    const [roadSharing, setRoadSharing] = useState(false);
    const [laneDiscipline, setLaneDiscipline] = useState(false);
    const [observation, setObservation] = useState(false);

    // Group 3: Text inputs and file upload
    const [reasonForNotReporting, setReasonForNotReporting] = useState('');
    const [firCopy, setFirCopy] = useState(null);
    const [firCopyName, setFirCopyName] = useState('');

    // Group 4: Form state
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({
        reasonForNotReporting: '',
        firCopy: '',
    });

    // Store IDs for update operations
    const [rootCauseId, setRootCauseId] = useState(null);
    const [preventableDetailsId, setPreventableDetailsId] = useState(null);

    /**
     * Common box styles for consistent UI
     */
    const boxStyle = {
        bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
        p: 2,
        borderRadius: 1,
        mb: 2,
        border: isDarkMode ? 'none' : '1px solid #e0e0e0',
        boxShadow: isDarkMode ? 'none' : '0 1px 3px rgba(0,0,0,0.1)'
    };

    /**
     * Common input styles for text fields
     */
    const inputSx = {
        '& .MuiOutlinedInput-root': {
            bgcolor: isDarkMode ? '#2C2C2C' : '#ffffff',
            color: isDarkMode ? '#808080' : '#333333',
            '& fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0'
            },
            '&:hover fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
            },
            '&.Mui-focused fieldset': {
                borderColor: isDarkMode ? 'transparent' : '#3b536f'
            }
        },
        '& .MuiInputLabel-root': {
            color: isDarkMode ? '#808080' : '#666666'
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: isDarkMode ? '#808080' : '#3b536f'
        }
    };

    /**
     * Fetches existing root cause data when component mounts
     */
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await getRootCauseDetails(incidentId);
              //  console.log("Root cause API response:", response);

                if (response?.data?.length > 0) {
                    const data = response.data[0];
               //     console.log("Root cause data:", data);
                    
                    // Store the id for update operations
                    setRootCauseId(data.id);

                    // Update state variables with fetched data
                    setPreventableNo(data.preventable === true);
                    setPilotBehavior(data.driverbehaviour === true);
                    setDrivingBehavior(data.drivingbehaviour === true);
                    setBlameworthyYes(data.blameworthy === true);
                    setReportedToPolice(data.reportedtopolice === true);
                    setReasonForNotReporting(data.notreportedreason || '');

                    // Handle preventable details - store ID for updates
                    if (data.rca_prevention_details && data.rca_prevention_details.length > 0) {
                        const details = data.rca_prevention_details[0];
                       // console.log("Preventable details:", details);
                        
                        // Store the ID for the preventable details
                        setPreventableDetailsId(details.id);
                        
                        // Set checkbox states
                        setLackOfPatience(details.lackofpatience || false);
                        setLackOfSpace(details.lackofspace || false);
                        setSpeed(details.speed || false);
                        setRoadSharing(details.roadsharing || false);
                        setLaneDiscipline(details.lanediscipline || false);
                        setObservation(details.observations || false);
                    }

                    if (data.firlink) {
                        setFirCopyName(data.firlink);
                    }

                    // Set form to submitted state if not reassigned
                    if (!reassigned) {
                        setIsSubmitted(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Failed to load root cause data");
            } finally {
                setIsLoading(false);
            }
        };

        if (incidentId) {
            fetchData();
        }
    }, [incidentId, reassigned]);

    /**
     * Handles file upload for police report
     * @param {Event} event - File input change event
     */
    const handleFileUpload = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            
            // Validate file size (max 5MB)
            if (file.size > 5 * 1024 * 1024) {
                setErrors(prev => ({
                    ...prev,
                    firCopy: 'File size exceeds 5MB limit'
                }));
                return;
            }
            
            // Validate file type
            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                setErrors(prev => ({
                    ...prev,
                    firCopy: 'Only PDF, JPEG, and PNG files are allowed'
                }));
                return;
            }
            
            // Clear any previous errors
            setErrors(prev => ({
                ...prev,
                firCopy: ''
            }));
            
            setFirCopy(file);
            setFirCopyName(file.name);
        }
    };

    /**
     * Validates form before submission
     * @returns {boolean} - True if form is valid, false otherwise
     */
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        
        // Validate reason for not reporting if police wasn't reported
        if (!reportedToPolice && !reasonForNotReporting.trim()) {
            newErrors.reasonForNotReporting = 'Please provide a reason for not reporting to police';
            isValid = false;
        }
        
        // Validate file upload if reported to police
        if (reportedToPolice && !firCopy && !firCopyName) {
            newErrors.firCopy = 'Please upload a police report copy';
            isValid = false;
        }
        
        setErrors(newErrors);
        return isValid;
    };
    
    /**
     * Handles form submission
     * @param {Event} event - Form submit event
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        try {
            const preventableDetails = {
                // Include ID if we have an existing record
                ...(preventableDetailsId && { id: preventableDetailsId }),
                lackofpatience: lackOfPatience,
                lackofspace: lackOfSpace,
                speed: speed,
                roadsharing: roadSharing,
                lanediscipline: laneDiscipline,
                observations: observation
            };

           // console.log("Submitting preventable details:", preventableDetails);

            const formData = new FormData();
            formData.append('incidentreportingid', incidentId.toString());
            
            // Include root cause ID for update operations
            if (reassigned && rootCauseId) {
                formData.append('id', rootCauseId.toString());
            }
            
            formData.append('preventable', preventableNo);
            formData.append('driverbehaviour', pilotBehavior);
            formData.append('preventabledetails', JSON.stringify(preventableDetails));
            formData.append('drivingbehaviour', drivingBehavior);
            formData.append('blameworthy', blameworthyYes);
            formData.append('conclusion', blameworthyYes ? 'at fault' : 'not at fault');
            formData.append('reportedtopolice', reportedToPolice);
            formData.append('notreportedreason', reasonForNotReporting);
           
            if (firCopy instanceof File) {
                formData.append('uploadfile', firCopy);
            }

            // Log form data for debugging
            for (let pair of formData.entries()) {
                console.log(pair[0] + ': ' + pair[1]);
            }

            let response;
            if (reassigned === true) {
                response = await updateRootCauseDetails(formData);
                toast.success('Root cause analysis updated successfully');
            } else {
                response = await createRootCauseDetails(formData);
                toast.success('Root cause analysis submitted successfully');
            }

            setIsSubmitted(true);
            if (fetchIncidents) await fetchIncidents();
            if (onClose) onClose();
        } catch (error) {
            console.error('Error submitting data:', error);
            let errorMessage = `Failed to ${reassigned ? 'update' : 'submit'} root cause analysis`;
            
            if (error.response) {
                console.error('Response data:', error.response.data);
                errorMessage = error.response.data.message || errorMessage;
            }
            
            toast.error(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div style={{
            backgroundColor: isDarkMode ? '#1E1E1E' : '#f5f7fa',
            padding: '20px'
        }}>
            {/* Form Title */}
            <Box sx={{
                p: 0.5,
                mb: 2,
                borderRadius: 1,
            }}>
                <Typography variant="subtitle1" gutterBottom sx={{
                    color: isDarkMode ? '#808080' : '#333333',
                    fontWeight: 500
                }}>
                    Root Cause Analysis
                </Typography>
            </Box>

            {/* Preventable Section */}
            <Box sx={boxStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <MuiSwitch
                        checked={preventableNo}
                        onChange={(e) => setPreventableNo(e.target.checked)}
                    />
                    <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                        Preventable
                    </Typography>
                </Box>
                {preventableNo && (
                    <Box sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                        gap: 1,
                        mt: 2,
                        '& .MuiFormControlLabel-root': {
                            margin: 0,
                            color: isDarkMode ? '#808080' : '#666666'
                        },
                        '& .MuiCheckbox-root': {
                            color: isDarkMode ? '#808080' : '#666666',
                            '&.Mui-checked': {
                                color: isDarkMode ? '#00ffff' : '#3b536f'
                            }
                        }
                    }}>
                        <FormControlLabel
                            control={<Checkbox checked={lackOfPatience} onChange={(e) => setLackOfPatience(e.target.checked)} />}
                            label="Lack Of Patience"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={lackOfSpace} onChange={(e) => setLackOfSpace(e.target.checked)} />}
                            label="Lack Of Space"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={speed} onChange={(e) => setSpeed(e.target.checked)} />}
                            label="Speed"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={roadSharing} onChange={(e) => setRoadSharing(e.target.checked)} />}
                            label="Road Sharing"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={laneDiscipline} onChange={(e) => setLaneDiscipline(e.target.checked)} />}
                            label="Lane Discipline"
                        />
                        <FormControlLabel
                            control={<Checkbox checked={observation} onChange={(e) => setObservation(e.target.checked)} />}
                            label="Observation"
                        />
                    </Box>
                )}
            </Box>

            {/* Pilot Behavior Section */}
            <Box sx={boxStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <MuiSwitch
                        checked={pilotBehavior}
                        onChange={(e) => setPilotBehavior(e.target.checked)}
                    />
                    <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                        Pilot Behavior
                    </Typography>
                </Box>
            </Box>

            {/* Driving Behavior Section */}
            <Box sx={boxStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <MuiSwitch
                        checked={drivingBehavior}
                        onChange={(e) => setDrivingBehavior(e.target.checked)}
                    />
                    <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                        Driving Behavior
                    </Typography>
                </Box>
            </Box>

            {/* Conclusion on Investigation */}
            <Box sx={boxStyle}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <MuiSwitch
                            checked={blameworthyYes}
                            onChange={(e) => setBlameworthyYes(e.target.checked)}
                        />
                        <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                            Blameworthy
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                            Conclusion:
                        </Typography>
                        <Typography sx={{
                            color: blameworthyYes
                                ? (isDarkMode ? '#ff4444' : '#dc3545') // Red for "At Fault"
                                : (isDarkMode ? '#4CAF50' : '#28a745')  // Green for "Not At Fault"
                        }}>
                            {blameworthyYes ? 'At Fault' : 'Not At Fault'}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {/* Reported to Police Section */}
            <Box sx={boxStyle}>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <MuiSwitch
                            checked={reportedToPolice}
                            onChange={(e) => {
                                setReportedToPolice(e.target.checked);
                                // Clear errors when switching to "reported to police"
                                if (e.target.checked) {
                                    setErrors(prev => ({ ...prev, reasonForNotReporting: '' }));
                                } else {
                                    setErrors(prev => ({ ...prev, firCopy: '' }));
                                }
                            }}
                        />
                        <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>
                            Reported to Police
                        </Typography>
                    </Box>
                   
                    {/* Show file upload button when reported to police is checked */}
                    {reportedToPolice && (
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
                            <Button
                                component="label"
                                variant="contained"
                                sx={{
                                    backgroundColor: isDarkMode ? '#0D6EFD' : '#3b536f',
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: isDarkMode ? '#0B5ED7' : '#2f4259'
                                    }
                                }}
                            >
                                Upload Report Copy
                                <input
                                    type="file"
                                    hidden
                                    onChange={handleFileUpload}
                                    accept=".pdf,.jpg,.jpeg,.png"
                                />
                            </Button>
                           
                            {/* Display uploaded file name or link */}
                            {firCopyName && (
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    color: isDarkMode ? '#808080' : '#666666',
                                    fontSize: '0.875rem'
                                }}>
                                    <Typography>Uploaded:</Typography>
                                    {firCopy instanceof File ? (
                                        <Typography>{firCopyName}</Typography>
                                    ) : (
                                        <Button
                                            href={firCopyName}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            sx={{
                                                textTransform: 'none',
                                                color: isDarkMode ? '#00ffff' : '#3b536f',
                                                '&:hover': {
                                                    textDecoration: 'underline',
                                                    backgroundColor: 'transparent'
                                                }
                                            }}
                                        >
                                            View FIR Copy
                                        </Button>
                                    )}
                                </Box>
                            )}
                            {errors.firCopy && (
                                <Typography color="error" fontSize="0.75rem">
                                    {errors.firCopy}
                                </Typography>
                            )}
                        </Box>
                    )}
                </Box>

                {/* Show reason field when not reported to police */}
                {!reportedToPolice && (
                    <TextField
                        fullWidth
                        placeholder="Reason of not reporting"
                        value={reasonForNotReporting}
                        onChange={(e) => {
                            setReasonForNotReporting(e.target.value);
                            // Clear error when user starts typing
                            if (e.target.value.trim()) {
                                setErrors(prev => ({ ...prev, reasonForNotReporting: '' }));
                            }
                        }}
                        onBlur={() => {
                            // Validate on blur
                            if (!reasonForNotReporting.trim()) {
                                setErrors(prev => ({ 
                                    ...prev, 
                                    reasonForNotReporting: 'Please provide a reason for not reporting to police' 
                                }));
                            }
                        }}
                        sx={{
                            ...inputSx,
                            mt: 2,
                            '& input:-webkit-autofill': {
                                WebkitBoxShadow: '0 0 0 30px #ffffff inset',
                                WebkitTextFillColor: isDarkMode ? 'white' : '#666666',
                            }
                        }}
                        required
                        error={!!errors.reasonForNotReporting}
                        helperText={errors.reasonForNotReporting}
                    />
                )}
            </Box>

            {/* Submit Button */}
            <Box sx={{ mt: 2 }}>
                {(!isSubmitted || reassigned) && (
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading || (isSubmitted && !reassigned)}
                        sx={{
                            backgroundColor: isDarkMode ? '#4CAF50' : '#3b536f',
                            '&:hover': {
                                backgroundColor: isDarkMode ? '#45a049' : '#2f4259'
                            },
                            '&:disabled': {
                                backgroundColor: isDarkMode ? '#2C2C2C' : '#e0e0e0'
                            }
                        }}
                    >
                        {isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                        ) : (
                            reassigned ? 'Update' : 'Submit'
                        )}
                    </Button>
                )}
            </Box>
        </div>
    );
};

export default RootCause;