import { useNavigate, useLocation } from "react-router-dom";
import React, { useContext, useState } from "react";
import { styled } from "@mui/system";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import BarChartIcon from "@mui/icons-material/BarChart";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PeopleIcon from "@mui/icons-material/People";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GavelIcon from '@mui/icons-material/Gavel';
import { Menu, MenuItem, Typography } from "@mui/material";

import { LoginContext } from "../../store/LoginContext";
import { ThemeContext } from "../../store/ThemeContext";

const StyledSidebar = styled("div")(({ isDarkMode }) => ({
  width: "72px",
  height: "calc(100vh - 55px)",
  backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
  display: "flex",
  flexDirection: "column",
  position: "fixed",
  left: 0,
  top: "55px",
  zIndex: 1000,
  color: isDarkMode ? "#fff" : "#272727",
  transition: "all 0.3s ease",
  boxShadow: isDarkMode
    ? '1px 0 8px rgba(0, 0, 0, 0.4)'
    : '1px 0 8px rgba(0, 0, 0, 0.1)',
}));

const NavItems = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
  padding: "20px 0",
});

const NavItem = styled("div")(({ active, isDarkMode }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "12px 0",
  cursor: "pointer",
  width: "89%",
  position: "relative",
  color: active
    ? (isDarkMode ? "#fff" : "#1976d2")
    : (isDarkMode ? "#64748b" : "#94a3b8"),
  transition: "all 0.3s ease",
  marginLeft: "10px",

  "&::before": {
    content: '""',
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: "3px",
    backgroundColor: active
      ? (isDarkMode ? "#fff" : "#1976d2")
      : "transparent",
    transition: "all 0.3s ease",
  },

  "&:hover": {
    color: isDarkMode ? "#fff" : "#1976d2",
    backgroundColor: isDarkMode
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(25, 118, 210, 0.08)",

    "&::before": {
      width: active ? "3px" : "3px",
      backgroundColor: isDarkMode ? "#fff" : "#1976d2",
    },
  },
}));

const IconContainer = styled("div")({
  marginBottom: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "transform 0.2s ease",
  "& svg": {
    fontSize: "20px",
  },
  "div:hover &": {
    transform: "scale(1.1)",
  },
});

const Label = styled("span")({
  fontSize: "10px",
  textAlign: "center",
  fontWeight: 500,
  transition: "all 0.2s ease",
  opacity: 0.9,
  "&:hover": {
    opacity: 1,
  },
});

const menuItems = [
  { icon: <DashboardIcon />, label: "Dashboard", href: "/dashboard" },
  { icon: <CalendarTodayIcon />, label: "Schedule", href: "/schedule" },
  { icon: <BarChartIcon />, label: "Operations", href: "/Operations" },
  { icon: <ReceiptIcon />, label: "Billing", href: "/billing" },
];

const PowerButton = styled("div")(({ isDarkMode }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "12px 0",
  cursor: "pointer",
  width: "100%",
  color: isDarkMode ? "#64748b" : "#94a3b8",
  transition: "all 0.3s ease",
  marginTop: "-8px", // Push to bottom
  marginBottom: "20px", // Space from bottom
  "&:hover": {
    color: isDarkMode ? "#ff4d4d" : "#d32f2f",
    backgroundColor: isDarkMode
      ? "rgba(255, 77, 77, 0.1)"
      : "rgba(211, 47, 47, 0.08)",
    "& svg": {
      transform: "rotate(360deg)",
    },
  },
  "& svg": {
    fontSize: "20px",
    transition: "transform 0.5s ease",
  },
}));

const ContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  alignItems: "center",
});

const CenteredNavItems = styled(NavItems)({
  marginTop: "auto",
  marginBottom: "auto",
});

const HelpSection = styled("div")(({ isDarkMode }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  marginBottom: "8px",
  width: "100%",
}));

const HelpButton = styled("div")(({ isDarkMode }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "12px 0",
  cursor: "pointer",
  width: "100%",
  color: isDarkMode ? "#64748b" : "#94a3b8",
  transition: "all 0.3s ease",
  "&:hover": {
    color: isDarkMode ? "#fff" : "#1976d2",
    backgroundColor: isDarkMode
      ? "rgba(255, 255, 255, 0.1)"
      : "rgba(25, 118, 210, 0.08)",
  },
}));

const StyledMenu = styled(Menu)(({ theme, isDarkMode }) => ({
  '& .MuiPaper-root': {
    backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#000000',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginTop: '8px',
    minWidth: '200px',
  },
  '& .MuiMenuItem-root': {
    fontSize: '14px',
    padding: '10px 24px',
    '&:hover': {
      backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { logoutHandle } = useContext(LoginContext);
  const { isDarkMode } = useContext(ThemeContext);
  const userGroup = JSON.parse(sessionStorage.getItem("AuthToken"))?.group;
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const [legalAnchorEl, setLegalAnchorEl] = useState(null);

  const handleHelpClick = (event) => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setHelpAnchorEl(null);
  };

  const handleLegalClick = (event) => {
    setLegalAnchorEl(event.currentTarget);
  };

  const handleLegalClose = () => {
    setLegalAnchorEl(null);
  };

  const isMenuActive = (item) => {
    const currentPath = location.pathname.toLowerCase();
  
    // Check if the current path belongs to the Dashboard and its sub-routes
    if (item.href === "/dashboard") {
      const dashboardRoutes = [
        "/dashboard",
        "/dashboard/Routes",
        "/dashboard/Charging_Stations",
        "/dashboard/DeployedBuses",
      ];
      return dashboardRoutes.some(route => currentPath.startsWith(route));
    }
  
    // Special handling for Operations and its sub-routes
    if (item.href === "/Operations") {
      const operationsRoutes = [
        "/operations",
        "/operations/inout",
        "/operations/charging",
        "/operations/pilotmonitoring",
        "/operations/cleaning",
        "/operations/incident",
        "/operations/inspection",
        "/operations/completedrecords",
      ];
      return operationsRoutes.some(route => currentPath.startsWith(route));
    }
  
    // Default behavior for other menu items
    return item.href.toLowerCase() === currentPath;
  };

  return (
    <StyledSidebar isDarkMode={isDarkMode}>
      <ContentWrapper>
        <CenteredNavItems>
          {menuItems.map((item, index) => (
            <NavItem
              key={index}
              active={isMenuActive(item)}
              isDarkMode={isDarkMode}
              onClick={() => navigate(item.href)}
            >
              <IconContainer>{item.icon}</IconContainer>
              <Label>{item.label}</Label>
            </NavItem>
          ))}
          {(userGroup === "Head Operator" || userGroup === "Safety Incharge" || userGroup === "Safety Head") && (
            <NavItem
              active={isMenuActive({ href: "/usermanagement" })}
              isDarkMode={isDarkMode}
              onClick={() => navigate("/usermanagement")}
            >
              <IconContainer><PeopleIcon /></IconContainer>
              <Label>User Management</Label>
            </NavItem>
          )}
        </CenteredNavItems>

        <HelpSection isDarkMode={isDarkMode}>
          {/* Help Button */}
          <HelpButton isDarkMode={isDarkMode} onClick={handleHelpClick}>
            <IconContainer>
              <HelpOutlineIcon />
            </IconContainer>
            <Label>Help</Label>
          </HelpButton>
          <StyledMenu
            anchorEl={helpAnchorEl}
            open={Boolean(helpAnchorEl)}
            onClose={handleHelpClose}
            isDarkMode={isDarkMode}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <Typography variant="subtitle2" sx={{ px: 3, py: 1, color: isDarkMode ? '#ffffff80' : '#00000080' }}>
              HELP
            </Typography>
            <MenuItem onClick={() => {
              handleHelpClose();
              window.open('https://helpdesk-transvolt.atlassian.net/jira/software/projects/KAN/form/1?atlOrigin=eyJpIjoiNmVlMTI1ZTFhYzUyNDA5MmE2YTM2OGQxY2NlMmFjMmUiLCJwIjoiaiJ9', '_blank');
            }}>Support</MenuItem>
          </StyledMenu>

          {/* Legal Button */}
          {/* <HelpButton isDarkMode={isDarkMode} onClick={handleLegalClick}>
            <IconContainer>
              <GavelIcon />
            </IconContainer>
            <Label>Legal</Label>
          </HelpButton>
          <StyledMenu
            anchorEl={legalAnchorEl}
            open={Boolean(legalAnchorEl)}
            onClose={handleLegalClose}
            isDarkMode={isDarkMode}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
          >
            <Typography variant="subtitle2" sx={{ px: 3, py: 1, color: isDarkMode ? '#ffffff80' : '#00000080' }}>
              LEGAL
            </Typography>
            <MenuItem onClick={handleLegalClose}>Terms of service</MenuItem>
            <MenuItem onClick={handleLegalClose}>Privacy policy</MenuItem>
            <MenuItem onClick={handleLegalClose}>User notice</MenuItem>
          </StyledMenu> */}
        </HelpSection>

        <PowerButton
          isDarkMode={isDarkMode}
          onClick={logoutHandle}
        >
          <IconContainer>
            <PowerSettingsNewIcon />
          </IconContainer>
          <Label>Logout</Label>
        </PowerButton>
      </ContentWrapper>
    </StyledSidebar>
  );
}

export default Sidebar;