import React, { useState, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Switch, Grid, Box, IconButton } from '@mui/material';
import { ThemeContext } from '../../store/ThemeContext';
import { addNewUser, fetchEmployeeTypes, fetchDepartments, fetchDesignations, fetchUserRole } from '../../API/UserManagementAPI';
import {fetchCompany} from '../../API/IncidentAPI'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon

const CreateUser = ({ open, onClose, onUserCreated }) => {
  const { isDarkMode } = useContext(ThemeContext);
  const [formData, setFormData] = useState({
    CompanyId: 4,
    EmployeeCode: '',
    Title: 'Mr.',
    FName: '',
    MName: '',
    LName: '',
    DepartmentId: 7,
    DesignationId: 27,
    EmployeeTypeId: '',
    DateOfBirth: '',
    Gender: 'Male',
    DateOfJoining: '',
    Status: 'Active',
    Description: '',
    ProbationPeriod: '0',
    LoginName: '',
    UserType: 'User',
    UserRoleId: 3,
    Password: 'umt@123',
  });
  const [employeeTypes, setEmployeeTypes] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const types = await fetchEmployeeTypes();
        setEmployeeTypes(types);
        const depts = await fetchDepartments();
        setDepartments(depts);
        const desigs = await fetchDesignations();
        setDesignations(desigs);
        const roles = await fetchUserRole();
        setUserRoles(roles);
        const comps = await fetchCompany();
        setCompanies(comps);
      } catch (error) {
        console.error('Error fetching employee types:', error);
      }
    };

    fetchTypes();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleStatusChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      Status: e.target.checked ? 'Active' : 'Inactive',
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addNewUser(formData);
      toast.success('User created successfully!');
      onUserCreated(response);
      onClose();
    } catch (error) {
      toast.error('Failed to create user!');
      console.error('Error creating user:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth  PaperProps={{style: {borderRadius: '15px', height: 'sm'}}}>
      <DialogTitle style={{ background: isDarkMode ? '#636363' : '#fff', color: isDarkMode ? '#fff' : '#000', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Create User
            <IconButton onClick={onClose} style={{ color: isDarkMode ? '#fff' : '#000' }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
      <DialogContent style={{ background: isDarkMode ? "linear-gradient(to bottom, #636363, #454545)" : '#fff', color: isDarkMode ? '#fff' : '#000' }}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* First 4 rows with 3 fields each */}
            <Grid item xs={12} sm={4}>
  <TextField
    fullWidth
    margin="normal"
    label="Title"
    name="Title"
    value={formData.Title}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    <MenuItem value="Mr.">Mr.</MenuItem>
    <MenuItem value="Ms.">Ms.</MenuItem>
    <MenuItem value="Mrs.">Mrs.</MenuItem>
  </TextField>
</Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="First Name"
                name="FName"
                value={formData.FName}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Middle Name"
                name="MName"
                value={formData.MName}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Last Name"
                name="LName"
                value={formData.LName}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
  <TextField
    fullWidth
    margin="normal"
    label="Gender"
    name="Gender"
    value={formData.Gender}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    <MenuItem value="Male">Male</MenuItem>
    <MenuItem value="Female">Female</MenuItem>
    <MenuItem value="Other">Other</MenuItem>
  </TextField>
</Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Date of Birth"
                name="DateOfBirth"
                type="date"
                value={formData.DateOfBirth}
                onChange={handleChange}
                InputLabelProps={{ shrink: true, style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Date of Joining"
                name="DateOfJoining"
                type="date"
                value={formData.DateOfJoining}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ shrink: true, style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
            <Box display="flex" alignItems="center">
            <TextField
              fullWidth
              margin="normal"
              label="Status"
              name="Status"
              value={formData.Status}
              onChange={handleChange}
              style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
              InputLabelProps={{ shrink: true, style: { color: isDarkMode ? '#fff' : '#000' } }}
              InputProps={{
                style: { color: isDarkMode ? '#fff' : '#000' },
                endAdornment: (
                  <InputAdornment position="end">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formData.Status === 'Active'}
                          onChange={handleStatusChange}
                          name="Status"
                          color="primary"
                        />
                      }
                      label="Status"
                      style={{ color: isDarkMode ? '#fff' : '#000' }}
                    />
                  </InputAdornment>
                )
              }}
            />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Employee Code"
                name="EmployeeCode"
                value={formData.EmployeeCode}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
  <TextField
    fullWidth
    margin="normal"
    label="Employee Type"
    name="EmployeeTypeId"
    value={formData.EmployeeTypeId}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    {employeeTypes.map((type) => (
      <MenuItem key={type.employeetypeid} value={type.employeetypeid}>
        {type.employeetypes}
      </MenuItem>
    ))}
  </TextField>
</Grid>
            
            

<Grid item xs={12} sm={4}>
  <TextField
    fullWidth
    margin="normal"
    label="User Type"
    name="UserType"
    value={formData.UserType}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    <MenuItem value="User">User</MenuItem>
    <MenuItem value="Field1">Field1</MenuItem>
    <MenuItem value="Field2">Field2</MenuItem>
    <MenuItem value="Field3">Field3</MenuItem>
    <MenuItem value="Field4">Field4</MenuItem>
    <MenuItem value="Field5">Field5</MenuItem>
  </TextField>
</Grid>

<Grid item xs={12} sm={4}>
  <TextField
    fullWidth
    margin="normal"
    label="Department"
    name="DepartmentId"
    value={formData.DepartmentId}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    {departments.map((type) => (
      <MenuItem key={type.departmentid} value={type.departmentid}>
        {type.departmentname}
      </MenuItem>
    ))}
  </TextField>
</Grid>

<Grid item xs={12}>
  <TextField
    fullWidth
    margin="normal"
    label="Designation"
    name="DesignationId"
    value={formData.DesignationId}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    {designations.map((type) => (
      <MenuItem key={type.designationid} value={type.designationid}>
        {type.designationname}
      </MenuItem>
    ))}
  </TextField>
</Grid>
          

            {/* 6th row with 3 fields */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Probation Period"
                name="ProbationPeriod"
                value={formData.ProbationPeriod}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
  <TextField
    fullWidth
    margin="normal"
    label="User Role"
    name="UserRoleId"
    value={formData.UserRoleId}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    {userRoles.map((type) => (
      <MenuItem key={type.userroleid} value={type.userroleid}>
        {type.name}
      </MenuItem>
    ))}
  </TextField>
</Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Description"
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>

            {/* 7th row with one field */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                label="Login Name"
                name="LoginName"
                value={formData.LoginName}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>

            {/* 8th row with 3 fields */}
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                name="Password"
                type="password"
                // value={formData.Password}
                onChange={handleChange}
                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                InputProps={{
                  style: { color: isDarkMode ? '#fff' : '#000' }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
  <TextField
    fullWidth
    margin="normal"
    label="Assign Company"
    name="CompanyId"
    value={formData.CompanyId}
    onChange={handleChange}
    select
    style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
    InputLabelProps={{ style: { color: isDarkMode ? '# fff' : '#000' } }}
    InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
  >
    {companies.map((type) => (
      <MenuItem key={type.companyid} value={type.companyid}>
        {type.companyname}
      </MenuItem>
    ))}
  </TextField>
</Grid>
          </Grid>
          <DialogActions style={{ background: isDarkMode ? "#454545" : '#fff', color: isDarkMode ? '#fff' : '#000' }}>
            <Button
              onClick={onClose}
              sx={{
                backgroundColor: isDarkMode ? '#b0b0b0' : '#d3d3d3',
                color: '#000',
                '&:hover': {
                  backgroundColor: isDarkMode ? '#a0a0a0' : '#c0c0c0',
                },
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              sx={{
                backgroundColor: '#4caf50',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#45a049',
                },
              }}
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUser;