import { baseURL } from "./BaseURL";
import axios from "axios";

/**
 * Axios instance with base URL configuration
 */
const API = axios.create({ baseURL: baseURL });

/**
 * Default request configuration
 */
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * Helper to get authentication token from storage
 * @returns {string|null} Authentication token or null if not found
 */
const getAuthToken = () => {
  try {
    const authTokenData = sessionStorage.getItem("AuthToken");
    const parsedToken = authTokenData ? JSON.parse(authTokenData) : null;
    return parsedToken?.token || null;
  } catch (error) {
    console.error("Error parsing auth token:", error);
    return null;
  }
};

/**
 * Handle unauthorized errors consistently across API calls
 * @param {Error} error - The error object from API call
 * @returns {boolean} True if authentication error was handled, false otherwise
 */
const handleAuthError = (error) => {
  if (error.response?.status === 401 || error.response?.status === 403) {
    sessionStorage.removeItem("AuthToken");
    window.location.href = '/login';
    return true;
  }
  return false;
};

/**
 * Fetch user profiles with optional search filtering
 * @param {string} searchTerm - Optional search term to filter users
 * @returns {Promise} List of user profiles
 */
export const getUserProfiles = async (searchTerm = '') => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.get('/auth/get-user-list/', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        LoginName: searchTerm,
      },
    });
    
    return response.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error('Error fetching user profiles:', error);
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Add a new user to the system
 * @param {Object} userData - User data to be added
 * @returns {Promise} Created user data
 */
export const addNewUser = async (userData) => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.post(
      "/auth/add-new-user/",
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    
    return response.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error("Error adding new user:", error.response?.data || error.message);
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Fetch available employee types
 * @returns {Promise} List of employee types
 */
export const fetchEmployeeTypes = async () => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.get('/auth/employee-type-list/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    return response.data.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error("Error fetching employee types:", error.response?.data || error.message);
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Fetch available departments
 * @returns {Promise} List of departments
 */
export const fetchDepartments = async () => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.get('/auth/department-list/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    return response.data.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error("Error fetching departments:", error.response?.data || error.message);
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Fetch available job designations
 * @returns {Promise} List of job designations
 */
export const fetchDesignations = async () => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.get('/auth/designation-list/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    return response.data.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error("Error fetching designations:", error.response?.data || error.message);
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Fetch available user roles
 * @returns {Promise} List of user roles
 */
export const fetchUserRole = async () => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.get('/auth/user-role-list/', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    
    return response.data.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error("Error fetching user roles:", error.response?.data || error.message);
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Fetch details for a specific user
 * @param {string|number} UserMembershipId - ID of the user to retrieve
 * @returns {Promise} User details
 */
export const getUserDetails = async (UserMembershipId) => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.get(`/auth/get-user-details/`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        UserMembershipId
      }
    });
    
    return response.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error('Error fetching user details:', error.response?.data || error.message);
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Update details for an existing user
 * @param {Object} userData - Updated user data
 * @returns {Promise} Updated user data
 */
export const updateUserDetails = async (userData) => {
  try {
    const token = getAuthToken();
    if (!token) {
      throw new Error("Authentication token not found");
    }
    
    const response = await API.patch(
      "/auth/update-user-details/",
      userData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
      }
    );
    
    return response.data;
  } catch (error) {
    if (handleAuthError(error)) return;
    console.error("Error updating user details:", error.response?.data || error.message);
    throw error.response?.data || new Error("Network error");
  }
};