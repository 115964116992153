import { baseURL } from "./BaseURL";
import axios from "axios";

/**
 * Default request configuration
 */
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

/**
 * Axios instance with base URL configuration
 */
const API = axios.create({ baseURL: baseURL });

/**
 * Helper to get authentication token from storage
 * @returns {Object} Token information with formatted bearer token
 */
const getAuthToken = () => {
  try {
    const authTokenData = sessionStorage.getItem("AuthToken");
    const parsedAuth = authTokenData ? JSON.parse(authTokenData) : null;
    const token = parsedAuth?.token || null;
    
    if (!token) {
      throw new Error("No authentication token found");
    }
    
    // Format token with Bearer prefix if needed
    const formattedToken = token.startsWith('Bearer ') ? token : `Bearer ${token}`;
    return { token, formattedToken };
  } catch (error) {
    console.error("Error parsing auth token:", error);
    throw new Error("Invalid authentication token");
  }
};

/**
 * Fetch dashboard overview statistics
 * @returns {Promise} Dashboard data
 */
export const fetchDashboardDetails = async () => {
  try {
    const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
    const token = authToken ? authToken.token : null;
    
    const response = await API.get("/SchedulingManagement/get-dashboard-count/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching dashboard details:",
      error.response?.data || new Error("Network error")
    );
    throw error.response || new Error("Network error");
  }
};

/**
 * Fetch list of all buses
 * @param {string} date - Date for filtering buses
 * @param {string} number - Vehicle number for search
 * @param {number} dataperPage - Items per page
 * @param {number} currentPage - Current page number
 * @returns {Promise} List of buses and pagination info
 */
export const fetchBusList = async (date, number, dataperPage, currentPage) => {
  try {
    const { formattedToken } = getAuthToken();
  
    const response = await API.get(
      "/SchedulingManagement/get-all-bus-list/",
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": formattedToken,
          "Accept": "application/json"
        },
        params: {
          vehiclenumber: number?.trim() || "",
          page: currentPage,
          page_size: dataperPage,
          date: date,
        },
      }
    );
    
    //console.log("API Response:", response);
    return response.data;
  } catch (error) {
    console.error("API Error:", {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });
  
    if (error.response?.status === 403) {
      sessionStorage.removeItem("AuthToken");
      throw new Error("Your session has expired. Please login again.");
    }
  
    throw error;
  }
};

/**
 * Get current date formatted as YYYY-MM-DD
 * @param {string} separator - Character to use as date separator
 * @returns {string} Formatted date string
 */
export const getCurrentDate = (separator = "-") => {
  const newDate = new Date();
  const date = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`;
};

/**
 * Fetch route list with pagination and search
 * @param {string} date - Date for filtering routes
 * @param {string} route_number - Route number for search
 * @param {number} dataperPage - Items per page
 * @param {number} currentPage - Current page number
 * @returns {Promise} List of routes and pagination info
 */
export const fetchRouteList = async (date, route_number, dataperPage, currentPage) => {
  try {
    const { formattedToken } = getAuthToken();
   // console.log("Token found:", !!formattedToken);
    
    // console.log("API Request Config:", {
    //   endpoint: "/SchedulingManagement/get-route-list/",
    //   params: {
    //     route_number: route_number?.trim() || "",
    //     page: currentPage,
    //     page_size: dataperPage,
    //     date: date
    //   }
    // });
  
    const response = await API.get(
      "/SchedulingManagement/get-route-list/",
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": formattedToken,
          "Accept": "application/json"
        },
        params: {
          route_number: route_number?.trim() || "",
          page: currentPage,
          page_size: dataperPage,
          date: date,
        }
      }
    );
  
   // console.log("Raw API Response:", response);
    return response.data;
  } catch (error) {
    console.error("API Error Details:", {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });
  
    if (error.response?.status === 401 || error.response?.status === 403) {
      sessionStorage.removeItem("AuthToken");
      window.location.href = '/login';
      return;
    }
  
    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Fetch chargers list with filtering options
 * @param {string} date - Date for filtering chargers
 * @param {string} choice - Filter choice
 * @param {string} chargerName - Charger name for search
 * @param {number} dataperPage - Items per page
 * @param {number} currentPage - Current page number
 * @returns {Promise} List of chargers and pagination info
 */
export const fetchChargersList = async (
  date,
  choice,
  chargerName,
  dataperPage,
  currentPage
) => {
  try {
    const { formattedToken } = getAuthToken();

    const response = await API.get(
      "/SchedulingManagement/get-chargers-list/",
      {
        headers: {
          "Content-Type": "application/json",
          "Authorization": formattedToken,
          "Accept": "application/json"
        },
        params: {
          charger_name: chargerName?.trim() || "",
          page: currentPage,
          page_size: dataperPage,
          date: date,
          choice: choice,
        }
      }
    );

   // console.log("Chargers List API Response:", response);
    return response.data;
  } catch (error) {
    console.error("API Error:", {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data
    });

    if (error.response?.status === 401 || error.response?.status === 403) {
      sessionStorage.removeItem("AuthToken");
      window.location.href = '/login';
      return;
    }

    throw error.response?.data || new Error("Network error");
  }
};

/**
 * Legacy functions kept for reference or future use
 * These functions are currently not in active use
 */

/**
 * Fetch specific route data
 * @deprecated Using newer route list API instead
 */
export const fetchRoute = async () => {
  try {
    const response = await API.get("/rest-api/vehical/Get-RouteNo15");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching Routes:",
      error.response?.data || new Error("Network error")
    );
    throw error.response || new Error("Network error");
  }
};

/**
 * Fetch bus stops data
 * @deprecated Using newer API endpoints
 */
export const fetchStops = async () => {
  try {
    const response = await API.get("/rest-api/vehical/Get-RouteNo15-BusStops");
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching Routes:",
      error.response?.data || new Error("Network error")
    );
    throw error.response || new Error("Network error");
  }
};

/**
 * Old implementation of route list fetching
 * @deprecated Using newer implementation with better error handling
 */
export const fetchRouteListold = async () => {
  try {
    const authToken = JSON.parse(sessionStorage.getItem("AuthToken"));
    const token = authToken ? authToken.token : null;
    const formdata = new FormData();
    formdata.append("date", getCurrentDate());

    const response = await API.post("schedule/GetRouteListjson/", formdata, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Error fetching routes details:",
      error.response?.data || new Error("Network error")
    );
    throw error.response || new Error("Network error");
  }
};