import axios from "axios"; // Import axios for making HTTP requests
import FormData from "form-data";

// const API_URL = "http://localhost:8000"; // Base URL for the API
// const API_URL = "http://192.168.100.86:9000"; // Base URL for the API
const API_URL = "https://operations.transvolt.in/rest-api"; // Base URL for the API

export { API_URL as baseURL };  // Add this line to export the base URL

const API = axios.create({ baseURL: API_URL }); // Create an axios instance with the base URL

const config = {
  headers: {
    "Content-Type": "application/json", // Default headers for POST requests
  },
};


// // Function to check if a user exists in the database
// export const forgotpassword = async (email) => {
//   try {
//     const response = await API.post(
//       "/vehical/send-reset-password-email/",
//       {
//         email,
//       },
//       config
//     );
//     return response.data.exists; // Return whether the user exists
//   } catch (error) {
//     throw error.response || new Error("Network error"); // Throw an error if the request fails
//   }
// };


// // export const resetPassword = async (token, password, password2) => {
// //   try {
// //     const response = await API.post(
// //       "/vehical/reset-password/",
// //       {
// //         token,
// //         password,
// //         password2,
// //       },
// //       config
// //     );
// //     return response.data; // Return the response data
// //   } catch (error) {
// //     throw error.response || new Error("Network error"); // Throw an error if the request fails
// //   }
// // };


// // export const sendResetPasswordEmail = async (email) => {
// //   try {
// //     const response = await API.post('/vehical/send-reset-password-email/', { email });
// //     return response.data;
// //   } catch (error) {
// //     throw error;
// //   }
// // };




// export const loginUser = async (username, password) => {
//   try {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const response = await API.post("/auth/login/", { username, password }, config);
//     console.log("Full Login Response:", response.data); // Log full response

//     if (!response.data.data.token) {
//       throw new Error("Token not found in response.");
//     }

//     // Verify employee_id exists in response
//     if (!response.data.data.employee_id) {
//       console.warn("Employee ID not found in login response:", response.data);
//     }

//     return response.data;
//   } catch (error) {
//     console.error("Login failed:", error.message);
//     throw error.response?.data || new Error("Network error");
//   }
// };



// // Function to fetch dashboard details with authentication
// export const fetchDashboardDetails = async () => {
//   try {
//     const authToken = JSON.parse(sessionStorage.getItem("AuthToken")); // Get the auth token from localStorage
//     const token = authToken ? authToken.token : null;
//     const response = await API.get("/SchedulingManagement/get-dashboard-count/", {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`, // Set the Authorization header with the token
//       },
//     });
//     return response.data; // Return the dashboard details
//   } catch (error) {


//     console.error(
//       "Error fetching dashboard details:",
//       error.response?.data || new Error("Network error")
//     );
//     throw error.response || new Error("Network error"); // Throw an error if the request fails
//   }
// };



// Function to fetch the list of buses with authentication
// export const fetchBusList = async (date,number,dataperPage,currentPage) => {
//   try {
//     const authToken = JSON.parse(sessionStorage.getItem("AuthToken")); // Get the auth token from localStorage
//     const token = authToken ? authToken.token : null;
//     // const formdata = new FormData();
//     // formdata.append("date", date);
//     // const params = new URLSearchParams(formdata);

//     const response = await API.post(
//       "/schedule/GetallBussesListjson/",
//       null,
//       // formdata,
//       {
//         headers: {
//           // "Content-Type": "multipart/form-data",
//           Authorization: `Token ${token}`, // Set the Authorization header with the token
//           // params,

//         },params:{
//           vehical:number?number:"",
//           page:currentPage,
//           page_size:dataperPage,
//           date:date
//         }

//       }
//     );
//     return response.data; // Return the list of buses
//   } catch (error) {
//     // console.log(error);
//     // console.log(
//     //   "Error fetching bus details:",
//     //   error.response?.data || new Error("Network error")
//     // );
//     throw error.response || new Error("Network error"); // Throw an error if the request fails
//   }
// };















// Other API functions...