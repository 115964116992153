import React, { useState, useContext } from 'react';
import {
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  styled
} from '@mui/material';
import {
  Close as CloseIcon,
  CameraAlt,
  LocationOn,
  GetApp as GetAppIcon
} from '@mui/icons-material';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import FirstParty from './FirstParty';
import ThirdParty from './ThirdParty';
import RootCause from './RootCause';
import { ThemeContext } from '../../../store/ThemeContext';
import { saveAs } from 'file-saver';
import { baseURL } from '../../../API/BaseURL';

/**
 * Styled Dialog Component with consistent theme
 */
const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: '#303030',
    color: 'white',
  }
}));

/**
 * Section Header Component for consistent styling across sections
 */
const SectionHeader = styled(Box)(({ theme }) => ({
  backgroundColor: '#000',
  color: 'white',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  textAlign: 'center'
}));

/**
 * ViewSafetySupervisor component displays incident details and provides 
 * interfaces for supervisors to review and take action
 * 
 * @param {boolean} open - Controls dialog visibility
 * @param {Function} onClose - Handler for closing the dialog
 * @param {Object} caseData - Incident data to display
 * @param {Function} onSubmit - Handler for form submission
 * @param {Function} fetchIncidents - Function to refresh incident list
 */
const ViewSafetySupervisor = ({ open, onClose, caseData, onSubmit, fetchIncidents }) => {
  // State for UI control
  const [isLocked, setIsLocked] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showMap, setShowMap] = useState(false);

  // Extract incident ID from case data
  const id = caseData?.id;
  // const reassigned = caseData?.is_safetysupervisor;


  // Access theme context
  const { isDarkMode } = useContext(ThemeContext);

  // Don't render if no case data available
  if (!caseData) {
    return null;
  }

  /**
   * Handles root cause form submission
   * Locks the form and triggers parent refresh function
   */
  const handleRootCauseSubmit = () => {
    setIsLocked(true);
    onSubmit();
  };

  /**
   * Handles downloading the incident image
   * Uses file-saver library to save the image
   */
  const downloadImage = async () => {
    try {
      const imageUrl = caseData.imagestr;

      // Attempt to fetch and handle CORS
      await fetch(imageUrl, { mode: 'no-cors' });

      // Save the image with case ID in filename
      saveAs(imageUrl, `case-image-${caseData?.caseid || 'download'}.jpg`);

    } catch (error) {
      console.error("Error downloading image:", error);
      // Instead of opening in a new tab, show an error message
      alert("Failed to download the image. Please try again later.");
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
          color: isDarkMode ? 'white' : '#333333',
          overflow: 'hidden',
        }
      }}
    >
      {/* Dialog Header */}
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
      }}>
        <Typography variant="h6" sx={{ color: isDarkMode ? 'white' : '#333333' }}>
          Case ID: {caseData?.caseid}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{ color: isDarkMode ? 'white' : '#333333' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {/* Dialog Content with Custom Scrollbar */}
      <DialogContent sx={{
        maxHeight: '80vh',
        overflowY: 'auto',
        bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
        color: isDarkMode ? 'white' : '#333333',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-track': {
          background: isDarkMode ? '#424242' : '#f0f0f0',
          borderRadius: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: isDarkMode ? '#888' : '#ccc',
          borderRadius: '10px',
          border: '2px solid transparent',
          backgroundClip: 'padding-box',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: isDarkMode ? '#555' : '#aaa',
          transition: 'background 0.3s ease',
        },
      }}>
        <Box sx={{ mt: 2 }}>
          {/* Supervisor Level 1 Section Header */}
          <Box sx={{
            bgcolor: isDarkMode ? '#000' : '#3b536f',
            color: 'white',
            p: 1,
            borderRadius: 1,
            mb: 2,
            textAlign: 'center'
          }}>
            <Typography>Supervisor (Level 1)</Typography>
          </Box>

          {/* Level 1 Content */}
          <Box sx={{ m: 3 }}>
            {/* Route Information Row */}
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>
                Route: {caseData.schedulecode}
                <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>
                  {caseData.route}
                </Typography>
              </Typography>

              {/* Action Buttons for Photo and Map */}
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  startIcon={<CameraAlt />}
                  onClick={() => setShowImage(true)}
                  sx={{
                    bgcolor: isDarkMode ? '#424242' : '#f5f7fa',
                    color: isDarkMode ? 'white' : '#333333',
                    border: '1px solid',
                    borderColor: isDarkMode ? '#808080' : '#3b536f',
                    '&:hover': {
                      bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(59, 83, 111, 0.1)'
                    }
                  }}
                >
                  View Case Photo
                </Button>

                <Button
                  variant="outlined"
                  startIcon={<LocationOn />}
                  sx={{
                    bgcolor: isDarkMode ? '#424242' : '#f5f7fa',
                    color: isDarkMode ? 'white' : '#333333',
                    border: '1px solid',
                    borderColor: isDarkMode ? '#808080' : '#3b536f',
                    '&:hover': {
                      bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(59, 83, 111, 0.1)'
                    }
                  }}
                  onClick={() => setShowMap(true)}
                >
                  View Map
                </Button>
              </Box>
            </Box>

            {/* Case Details Grid */}
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 3 }}>
              {/* Left Column: Basic Information */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>Pilot Name:</Typography>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>{caseData.drivername}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>Bus No.:</Typography>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>{caseData.vehiclenumber}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>Shift:</Typography>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>{caseData.shift}</Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333', fontWeight: 'bold' }}>Date:</Typography>
                  <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>{caseData.incidentdate}</Typography>
                </Box>
              </Box>

              {/* Right Column: Description */}
              <Box sx={{
                border: isDarkMode ? '1px solid #808080' : '1px solid #3b536f',
                borderRadius: 1,
                p: 2
              }}>
                <Typography sx={{
                  color: isDarkMode ? 'white' : '#333333',
                  fontWeight: 'bold',
                  paddingBottom: '10px'
                }}>
                  Description:
                </Typography>
                <Typography sx={{ color: isDarkMode ? 'white' : '#333333' }}>{caseData.description}</Typography>
              </Box>
            </Box>
          </Box>

          {/* Level 2, 3, and 4 Forms */}
          <FirstParty incidentId={id} reassigned={caseData?.is_safetysupervisor === true} isLocked={isLocked} />
          <ThirdParty incidentId={id} reassigned={caseData?.is_safetysupervisor === true} baseUrl={baseURL} isLocked={isLocked} />
          <RootCause
            incidentId={id}
            reassigned={caseData?.is_safetysupervisor === true || caseData?.is_supervisor === true} baseUrl={baseURL}
            onSubmit={handleRootCauseSubmit}
            onClose={onClose}
            fetchIncidents={fetchIncidents}
          />
        </Box>

        {/* Map Dialog */}
        <Dialog
          open={showMap}
          onClose={() => setShowMap(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
              color: isDarkMode ? 'white' : '#333333'
            }
          }}
        >
          <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
          }}>
            <Typography variant="h6" sx={{ color: isDarkMode ? 'white' : '#333333' }}>
              Location Map ({caseData.location})
            </Typography>
            <IconButton
              onClick={() => setShowMap(false)}
              sx={{ color: isDarkMode ? 'white' : '#333333' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{
            bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
            color: isDarkMode ? 'white' : '#333333',
            height: '400px',
            p: 0
          }}>
            <iframe
              src={`https://www.google.com/maps?q=${encodeURIComponent(caseData.location)}&output=embed`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              title="Location Map"
            />
          </DialogContent>
        </Dialog>

        {/* Image Dialog */}
        <Dialog
          open={showImage}
          onClose={() => setShowImage(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
              color: isDarkMode ? 'white' : '#333333'
            }
          }}
        >
          <DialogTitle sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
          }}>
            <Typography variant="h6" sx={{ color: isDarkMode ? 'white' : '#333333' }}>
              Case Image
            </Typography>
            <Box>
              <IconButton
                onClick={downloadImage}
                sx={{
                  color: isDarkMode ? 'white' : '#333333',
                  '&:hover': {
                    bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(59, 83, 111, 0.1)'
                  }
                }}
                title="Download image"
              >
                <GetAppIcon />
              </IconButton>
              <IconButton
                onClick={() => setShowImage(false)}
                sx={{ color: isDarkMode ? 'white' : '#333333' }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{
            bgcolor: isDarkMode ? '#303030' : '#f5f7fa',
            color: isDarkMode ? 'white' : '#333333'
          }}>
            <Zoom>
              <img
                src={caseData.imagestr}
                alt="Case"
                style={{ width: '100%', height: 'auto' }}
              />
            </Zoom>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default ViewSafetySupervisor;