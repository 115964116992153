import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeContext } from "../../../store/ThemeContext";
import { getApprovedRecords } from "../../../API/IncidentAPI";
import Level3PendingDialog from "../SafetySupervisor/Level3PendingDialog";
//import { format, subDays, subMonths } from 'date-fns'; // Import date functions
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack
} from "@mui/material";

import {
  Search, 
  NavigateBefore, 
  NavigateNext,
  ArrowBack as ArrowBackIcon,
  CalendarMonth as CalendarIcon,
  Close as CloseIcon
} from "@mui/icons-material";

// For date selection
//import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

export default function CompletedRecords({ searchQuery: externalSearchQuery, setSearchQuery: externalSetSearchQuery, hideSearchBar }) {
  const { isDarkMode } = useContext(ThemeContext);
  const navigate = useNavigate();
  
  // Search state handling
  const [internalSearchQuery, setInternalSearchQuery] = useState("");
  const searchQuery = externalSearchQuery !== undefined ? externalSearchQuery : internalSearchQuery;
  const setSearchQuery = externalSetSearchQuery || setInternalSearchQuery;

  // Data state
  const [incidents, setIncidents] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  
  // Date range state
  const today = new Date();
  const defaultStartDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD'); // Default to 30 days ago
  const defaultEndDate = dayjs().format('YYYY-MM-DD'); // Today (instead of format(today, 'yyyy-MM-dd'))

  const [dateRangeType, setDateRangeType] = useState('1month');
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [customDateDialogOpen, setCustomDateDialogOpen] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  
  // UI state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [recordType, setRecordType] = useState("completed");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Modal state
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedIncident, setSelectedIncident] = useState(null);
  
  // Derived values
  const totalPages = Math.ceil(totalCount / pageSize);

  /**
   * Custom scrollbar styles for better UI experience
   */
  const customScrollbarStyles = {
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: isDarkMode ? '#2c2c2c' : '#f5f5f5',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: isDarkMode ? '#555' : '#ccc',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: isDarkMode ? '#777' : '#aaa',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: isDarkMode ? '#2c2c2c' : '#f5f5f5',
    },
  };

  /**
   * Update date range when dateRangeType changes
   */
  useEffect(() => {
    const today = dayjs();
    let newStartDate;
    
    switch(dateRangeType) {
      case '1week':
        newStartDate = today.subtract(7, 'day').format('YYYY-MM-DD');
        break;
      case '15days':
        newStartDate = today.subtract(15, 'day').format('YYYY-MM-DD');
        break;
      case '1month':
        newStartDate = today.subtract(30, 'day').format('YYYY-MM-DD');
        break;
      case 'custom':
        // Don't update dates for custom selection
        return;
      default:
        newStartDate = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
    }
    
    setStartDate(newStartDate);
setEndDate(today.format('YYYY-MM-DD'));
    // Reset to page 1 when changing date range
    setCurrentPage(1);
  }, [dateRangeType]);

  /**
   * Fetch incidents data from API
   */
  useEffect(() => {
    // Set a timeout to update the search value after typing stops
    const handler = setTimeout(() => {
      setSearchQuery(internalSearchQuery);
    }, 2000);
    
    async function fetchIncidents() {
      setIsLoading(true);
      setError(null);
      try {
        const response = await getApprovedRecords(currentPage, pageSize, searchQuery, startDate, endDate);
        if (response && response.results && Array.isArray(response.results.data)) {
          setIncidents(response.results.data);
          setTotalCount(response.total_count || 0);
        } else {
          setIncidents([]);
          setTotalCount(0);
          setError("Unexpected data structure received from the server.");
        }
      } catch (error) {
        console.error("Error fetching approved records:", error);
        setIncidents([]);
        setTotalCount(0);
        setError("Failed to fetch records. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    }
    
    fetchIncidents();
    
    // Clean up the timeout if component unmounts or query changes
    return () => {
      clearTimeout(handler);
    };
  }, [currentPage, pageSize, searchQuery, startDate, endDate, setSearchQuery]);

   // Add this to resolve the import issue in IncidentAPI.js
   useEffect(() => {
    // Fix for the unused config variable warning in IncidentAPI.js
    const script = document.createElement('script');
    script.textContent = `
      if (window.API && !window.baseURL) {
        window.baseURL = "${window.location.origin}";
      }
    `;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  /**
   * Handle date range type change
   */
  // Handle date range type change
  const handleDateRangeChange = (event) => {
    const selectedRange = event.target.value;
    setDateRangeType(selectedRange);
    
    if (selectedRange === 'custom') {
      // Convert string dates to dayjs objects when opening the dialog
      setTempStartDate(dayjs(startDate));
      setTempEndDate(dayjs(endDate));
      setCustomDateDialogOpen(true);
    }
  };

  /**
   * Apply custom date range and close dialog
   */
  // Apply custom date range
  const handleApplyCustomDates = () => {
    if (tempStartDate && tempEndDate) {
      // Format dates to strings for API calls
      setStartDate(tempStartDate.format('YYYY-MM-DD'));
      setEndDate(tempEndDate.format('YYYY-MM-DD'));
      setCurrentPage(1);
    }
    setCustomDateDialogOpen(false);
  };

  /**
   * Cancel custom date selection
   */
  const handleCancelCustomDates = () => {
    setCustomDateDialogOpen(false);
    // If no valid dates were previously set, revert to default option
    if (dateRangeType === 'custom' && (!startDate || !endDate)) {
      setDateRangeType('1month');
    }
  };

  /**
   * Format date range for display
   */
  const getDateRangeDisplay = () => {
  if (!startDate || !endDate) return 'Select date range';
    
    // Format dates for display (DD/MM/YYYY)
    const formattedStart = dayjs(startDate).format('DD/MM/YYYY');
    const formattedEnd = dayjs(endDate).format('DD/MM/YYYY');
    return `${formattedStart} - ${formattedEnd}`;
  };

  /**
   * Handle incident row click to open detail view
   * @param {Object} incident - The incident data object
   */
  const handleRowClick = (incident) => {
    setSelectedIncident({
      ...incident,
      submittedbylevel: 3 // Set this to show all levels
    });
    setViewModalOpen(true);
  };

  /**
   * Handle page change in pagination
   * @param {number} page - The target page number
   */
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  /**
   * Handle page size change
   * @param {Object} event - The select change event
   */
  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  /**
   * Handle record type change (between pending/completed)
   * @param {Object} e - The select change event
   */
  const handleRecordTypeChange = (e) => {
    const newValue = e.target.value;
    setRecordType(newValue);

    if (newValue === "pending") {
      window.history.go(-1);
    }
  };

  /**
   * Handle search input change with debounce
   * @param {Object} e - The input change event
   */
  const handleSearchChange = (e) => {
    const value = e.target.value;
    
    // Update the search input field immediately for user feedback
    if (externalSetSearchQuery) {
      externalSetSearchQuery(value);
    } 
    
    // Update internal state that triggers the debounce
    setInternalSearchQuery(value);
  };

 /**
 * Render pagination buttons for navigation
 * @returns {Array} Array of button components
 */
const renderPaginationButtons = () => {
  const buttons = [];
  const maxButtons = 5;

  let startPage = Math.max(1, currentPage - 2);
  let endPage = Math.min(totalPages, startPage + maxButtons - 1);

  if (endPage - startPage < maxButtons - 1) {
    startPage = Math.max(1, endPage - maxButtons + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    buttons.push(
      <Button
        key={i}
        variant={currentPage === i ? "contained" : "outlined"}
        sx={{
          minWidth: '32px',
          height: '32px',
          p: 0,
          mx: 0.5,
          bgcolor: currentPage === i ? "#3b536f" : (isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)"),
          color: currentPage === i ? "#fff" : (isDarkMode ? "#fff" : "#666"),
          '&:hover': {
            bgcolor: currentPage === i ? "#3b536f" : (isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)"),
          },
          fontWeight: 500,
          fontSize: "0.875rem",
          textTransform: 'none',
          borderRadius: '4px',
          boxShadow: currentPage === i ? 2 : 'none',
        }}
        onClick={() => handlePageChange(i)}
      >
        {i}
      </Button>
    );
  }

  return buttons;
};

  // Loading indicator
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "fixed",
          top: 1,
          left: 12,
          right: 0,
          backgroundColor: isDarkMode ? "rgba(26, 26, 26, 0.8)" : "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "80px" }}>
          <CircularProgress color={isDarkMode ? "primary" : "light"} size={30} />
          <Typography sx={{ color: isDarkMode ? "white" : "black", marginLeft: 2 }}>
            Loading...
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div className={`min-h-screen ${isDarkMode ? "bg-[#1a1a1a]" : "bg-gray-100"}`} style={{ paddingTop: '20px', paddingLeft: '60px' }}>
      {/* Header row container */}
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: 4,
        pt: 8,
        mb: 1
      }}>
        {/* Left side - Back button and heading */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              color: isDarkMode ? 'white' : 'black',
              bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
              '&:hover': {
                bgcolor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
              },
              borderRadius: '18px',
              padding: '8px',
              marginRight: '16px',
              width: '60px',
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <h1 className={`text-xl font-semibold relative ${isDarkMode ? 'text-white' : 'text-gray-800'}`}>
            Incident Reporting
          </h1>
        </Box>


        {/* Middle - Search Bar */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1, mx: 3 }}>
          <TextField
            sx={{
              width: '100%',
              maxWidth: '350px',
              marginRight: '26px',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
                WebkitTextFillColor: isDarkMode ? 'white' : 'black',
              },
            }}
            placeholder="Search..."
            value={externalSearchQuery !== undefined ? externalSearchQuery : internalSearchQuery}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <>
                  <Search sx={{ mr: 1, color: isDarkMode ? 'gray' : '#666' }} />
                  {internalSearchQuery !== searchQuery && (
                    <CircularProgress 
                      size={16} 
                      sx={{ 
                        color: isDarkMode ? 'lightgray' : '#666',
                        position: 'absolute',
                        right: '12px'
                      }} 
                    />
                  )}
                </>
              ),
              sx: {
                bgcolor: isDarkMode ? '#424242' : '#fff',
                borderRadius: '25px',
                height: '40px',
                '& input': {
                  color: isDarkMode ? 'white' : 'black',
                  borderRadius: '25px',
                  height: '40px',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: isDarkMode ? 'transparent' : '#e0e0e0',
                }
              }
            }}
          />
        </Box>

        
        {/* Date Range Selector */}
        <FormControl sx={{ 
          minWidth: 200, 
          mr: 2,
          bgcolor: isDarkMode ? '#424242' : '#fff', 
          borderRadius: 3, 
          height: '40px',
          '& .MuiOutlinedInput-root': {
            borderRadius: 3,
          }
        }}>
          <Select
            value={dateRangeType}
            onChange={handleDateRangeChange}
            displayEmpty
            renderValue={(selected) => {
              if (selected === 'custom') {
                return getDateRangeDisplay();
              } else {
                switch(selected) {
                  case '1week': return 'Last 7 Days';
                  case '15days': return 'Last 15 Days';
                  case '1month': return 'Last 30 Days';
                  default: return 'Select Date Range';
                }
              }
            }}
            sx={{
              height: '40px',
              color: isDarkMode ? 'white' : 'black',
              '& .MuiSelect-icon': { color: isDarkMode ? 'white' : 'black' },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#c0c0c0',
              },
            }}
            startAdornment={<CalendarIcon sx={{ mr: 1, color: isDarkMode ? '#aaa' : '#666' }} />}
          >
            <MenuItem value="1week">Last 7 Days</MenuItem>
            <MenuItem value="15days">Last 15 Days</MenuItem>
            <MenuItem value="1month">Last 30 Days</MenuItem>
            <MenuItem value="custom">Custom Range</MenuItem>
          </Select>
        </FormControl>

        {/* Right side - Dropdown */}
        <FormControl sx={{ minWidth: 250, bgcolor: isDarkMode ? '#424242' : '#fff', borderRadius: 3, height: '40px' }}>
          <Select
            value={recordType}
            onChange={handleRecordTypeChange}
            sx={{
              color: isDarkMode ? 'white' : 'black',
              height: '40px',
              '& .MuiSelect-icon': { color: isDarkMode ? 'white' : 'black' },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: isDarkMode ? 'transparent' : '#e0e0e0',
              },
            }}
          >
            <MenuItem value="pending">Pendings</MenuItem>
            <MenuItem value="completed">Completed Records</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {error && (
        <Box sx={{ 
          textAlign: 'center', 
          color: isDarkMode ? '#ff6b6b' : '#d32f2f',
          p: 2, 
          mt: 2
        }}>
          {error}
        </Box>
      )}

      {/* Table Container */}
      <TableContainer sx={{
        flex: 1,
        overflow: 'auto',
        paddingLeft: '52px',
        paddingRight: '39px',
        backgroundColor: isDarkMode ? "#1E1E1E" : "#f5f5f5",
        maxHeight: 'calc(100vh - 215px)',
        borderRadius: "10px",
        marginTop: "20px",
        '& .MuiTable-root': {
          width: '100%',
          border: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
        },
        ...customScrollbarStyles,
      }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {["Sr No", "Case ID", "Bus Number", "Pilot Name / ID", "Schedule", "Record Date"].map((header) => (
                <TableCell
                  key={header}
                  align="center"
                  sx={{
                    backgroundColor: isDarkMode ? "#37445A" : "#3b536f",
                    color: "#ffffff",
                    border: isDarkMode ? "1px solid #333333" : "1px solid #e0e0e0",
                    fontWeight: 600,
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {incidents.length > 0 ? (
              incidents.map((incident, index) => {
                const serialNumber = (currentPage - 1) * pageSize + index + 1;
                return (
                  <TableRow
                    key={incident.caseid}
                    onClick={() => handleRowClick(incident)}
                    sx={{
                      backgroundColor: isDarkMode ? "#4A4A4A" : "white",
                      "&:hover": {
                        backgroundColor: isDarkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.02)",
                        cursor: "pointer",
                      },
                      "& .MuiTableCell-root": {
                        borderBottom: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                        borderRight: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                        color: isDarkMode ? "#ffffff" : "#333333",
                        fontSize: "0.875rem",
                        padding: "12px 16px",
                        textAlign: "center",
                      },
                    }}
                  >
                    <TableCell>{serialNumber}</TableCell>
                    <TableCell>{incident.caseid}</TableCell>
                    <TableCell>{incident.vehiclenumber}</TableCell>
                    <TableCell>{incident.drivername}</TableCell>
                    <TableCell>{incident.schedulecode}</TableCell>
                    <TableCell>{incident.incidentdate}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center" sx={{ color: isDarkMode ? 'white' : 'black' }}>
                  No completed records found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{
        position: "fixed",
        bottom: 10,
        left: "112px",
        right: "45px",
        p: 1,
        py: -2,
        display: "flex",
        borderBottomRightRadius: "10px",
        borderBottomLeftRadius: "10px",
        justifyContent: "center",
        alignItems: "center",
        borderTop: isDarkMode ? "1px solid rgba(255,255,255,0.1)" : "1px solid rgba(0,0,0,0.1)",
        backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
        zIndex: 1000,
      }}>
        <Grid container alignItems="center" sx={{ maxWidth: 815, mx: 'auto' }}>
          {/* Pagination Controls */}
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <IconButton
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              sx={{
                color: isDarkMode ? "#fff" : "#666",
                bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" }
              }}
            >
              <NavigateBefore />
            </IconButton>

            <Box sx={{ display: 'flex', gap: 1 }}>
              {renderPaginationButtons()}
            </Box>

            <IconButton
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
              sx={{
                color: isDarkMode ? "#fff" : "#666",
                bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" }
              }}
            >
              <NavigateNext />
            </IconButton>
          </Grid>

          {/* Page Number Input */}
          <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
            <Typography sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: 'nowrap' }}>
              Page No
            </Typography>
            <TextField
              type="number"
              value={currentPage}
              onChange={(e) => {
                const newPage = Math.max(1, Math.min(totalPages, parseInt(e.target.value, 10) || 1));
                setCurrentPage(newPage);
              }}
              sx={{
                width: '60px',
                bgcolor: isDarkMode ? '#424242' : '#fff',
                '& input': {
                  color: isDarkMode ? 'white' : 'black',
                  padding: '6.8px',
                  textAlign: 'center',
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: isDarkMode ? 'transparent' : '#e0e0e0',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.5)' : '#e0e0e0',
                },
              }}
              inputProps={{
                min: 1,
                max: totalPages,
                style: { textAlign: 'center' }
              }}
            />
          </Grid>

          {/* Page Size Selection */}
          <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
            <Typography sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: 'nowrap' }}>
              Page Size
            </Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              size="small"
              sx={{
                width: "80px",
                bgcolor: isDarkMode ? '#424242' : 'transparent',
                color: isDarkMode ? 'white' : '#666',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: isDarkMode ? 'transparent' : 'rgba(0, 0, 0, 0.23)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.23)',
                },
                '& .MuiSelect-icon': {
                  color: isDarkMode ? 'white' : '#666',
                },
              }}
            >
              {[5, 10, 20, 30, 50].map((size) => (
                <MenuItem key={size} value={size}>{size}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>

      {/* Custom Date Range Dialog */}
      <Dialog 
        open={customDateDialogOpen} 
        onClose={handleCancelCustomDates}
        PaperProps={{
          style: {
            backgroundColor: isDarkMode ? "#1a1a1a" : "#f8f9fa",
            color: isDarkMode ? "#ffffff" : "#212529",
            borderRadius: "8px",
            padding: "8px",
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          alignItems: 'center',
          color: isDarkMode ? 'white' : 'black'
        }}>
          Select Custom Date Range
          <IconButton onClick={handleCancelCustomDates} edge="end" aria-label="close" sx={{ color: isDarkMode ? 'white' : 'black' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={3} sx={{ mt: 1, minWidth: '300px' }}>
              <DatePicker
                label="Start Date"
                value={tempStartDate}
                onChange={(newValue) => setTempStartDate(newValue)}
                maxDate={tempEndDate || dayjs()}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: isDarkMode ? '#333' : '#fff',
                    color: isDarkMode ? 'white' : 'black',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                  },
                  '& .MuiIconButton-root': {
                    color: isDarkMode ? 'white' : 'black',
                  }
                }}
              />
              <DatePicker
                label="End Date"
                value={tempEndDate}
                onChange={(newValue) => setTempEndDate(newValue)}
                minDate={tempStartDate}
                maxDate={dayjs()}
                sx={{
                  '& .MuiInputBase-root': {
                    bgcolor: isDarkMode ? '#333' : '#fff',
                    color: isDarkMode ? 'white' : 'black',
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: isDarkMode ? 'rgba(255, 255, 255, 0.2)' : '#e0e0e0',
                  },
                  '& .MuiIconButton-root': {
                    color: isDarkMode ? 'white' : 'black',
                  }
                }}
              />
            </Stack>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCancelCustomDates} 
            variant="outlined"
            sx={{ 
              color: isDarkMode ? 'white' : '#666',
              borderColor: isDarkMode ? 'white' : '#666',
              '&:hover': {
                borderColor: isDarkMode ? '#aaa' : '#333',
                backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleApplyCustomDates} 
            variant="contained"
            disabled={!tempStartDate || !tempEndDate}
            sx={{ 
              bgcolor: isDarkMode ? "#4287f5" : "#3b536f",
              color: 'white',
              '&:hover': {
                bgcolor: isDarkMode ? "#357ae8" : "#2a3f5a",
              }
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>

      {/* View Records Modal */}
      <Level3PendingDialog
        open={viewModalOpen}
        onClose={() => {
          setViewModalOpen(false);
          setSelectedIncident(null);
        }}
        caseData={selectedIncident}
        incidentDetails={selectedIncident}
        incidentId={selectedIncident?.id}
        isApproveButtonVisible={false}
        isFileViewVisible={true}
        showActionTaken={false}
        showExtendButton={false}
      />
    </div>
  );
}