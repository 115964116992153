import { useState, useEffect, useContext } from "react";
import { Dialog, DialogTitle, DialogContent, Button, IconButton } from "@mui/material";
import { Close, PhotoCamera, LocationOn } from "@mui/icons-material";
import { getSupervisorIncident } from "../../../API/IncidentAPI";
import { ThemeContext } from "../../../store/ThemeContext";
import MapPicker from "./MapPicker";

/**
 * ViewCaseModal component for displaying incident details
 * 
 * @param {boolean} open - Controls dialog visibility
 * @param {Function} onClose - Handler for closing the dialog
 * @param {Object} incident - The incident data to display
 */
export default function ViewCaseModal({ open, onClose, incident }) {
  const { isDarkMode } = useContext(ThemeContext);
  
  // Component state
  const [incidentDetail, setIncidentDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [openMap, setOpenMap] = useState(false);

  /**
   * Fetch incident details when modal is opened
   */
  useEffect(() => {
    if (open && incident) {
      setIncidentDetail(null);
      setLoading(true);
      setError(null);
      
      getSupervisorIncident(1, 1, 1, incident.schedulecode)
        .then((response) => {
          if (
            response &&
            response.data.results &&
            Array.isArray(response.data.results) &&
            response.data.results.length > 0
          ) {
            // Find the matching incident or use the first one
            const matched = response.data.results.find((item) => item.id === incident.id);
            setIncidentDetail(matched || response.data.results[0]);
          } else {
            setIncidentDetail(null);
          }
        })
        .catch((error) => {
          console.error("Error fetching incident details:", error);
          setError("Failed to load incident details. Please try again.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, incident]);

  /**
   * Handle opening the map view
   */
  const handleMapOpen = () => {
    setOpenMap(true);
  };

  /**
   * Handle closing the map view
   */
  const handleMapClose = () => {
    setOpenMap(false);
  };

  return (
    <>
      {/* Main Dialog */}
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: isDarkMode ? "#1a1a1a" : "#f8f9fa",
            color: isDarkMode ? "#ffffff" : "#212529",
            borderRadius: "24px",
          },
        }}
      >
        {/* Dialog Header */}
        <DialogTitle
          sx={{
            backgroundColor: isDarkMode ? "#1a1a1a" : "#f8f9fa",
            color: isDarkMode ? "#ffffff" : "#212529",
            borderTopLeftRadius: "24px",
            borderTopRightRadius: "24px",
          }}
        >
          <div className="flex justify-between items-start">
            <div className="space-y-1">
              <h2 className="text-2xl font-semibold">
                Case ID: {incidentDetail?.caseid || "Loading..."}
              </h2>
              <p
                className={`${
                  isDarkMode ? "text-gray-400" : "text-gray-600"
                } text-sm`}
              >
                Date: {incidentDetail?.incidentdate ? 
                  new Date(incidentDetail.incidentdate).toLocaleDateString('en-GB') : 'N/A'}
              </p>
              <p
                className={`${
                  isDarkMode ? "text-gray-400" : "text-gray-600"
                } text-sm`}
              >
                Time: {incidentDetail?.incidenttime ? 
                  new Date(`1970-01-01T${incidentDetail.incidenttime}Z`)
                    .toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true }) 
                  : 'N/A'}
              </p>
            </div>
            <IconButton
              onClick={onClose}
              size="small"
              sx={{
                color: isDarkMode ? "gray" : "gray-700",
                "&:hover": {
                  backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.04)" : "rgba(0, 0, 0, 0.04)"
                }
              }}
              aria-label="Close"
            >
              <Close />
            </IconButton>
          </div>
        </DialogTitle>

        {/* Dialog Content */}
        <DialogContent className="p-6">
          {loading ? (
            <div className="flex items-center justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-current"></div>
            </div>
          ) : error ? (
            <div className="text-center py-4 text-red-400">{error}</div>
          ) : incidentDetail ? (
            <div className="grid grid-cols-2 gap-8">
              {/* Left Column - Basic Information */}
              <div className={isDarkMode ? "bg-[#262626] rounded-lg shadow-sm" : "bg-white rounded-lg shadow-sm"}>
                <div className="p-5 space-y-4">
                  <DetailRow 
                    label="Schedule" 
                    value={`${incidentDetail.schedulecode} - ${incidentDetail.route}`} 
                    isDarkMode={isDarkMode} 
                  />
                  <DetailRow 
                    label="Pilot Name" 
                    value={incidentDetail.drivername} 
                    isDarkMode={isDarkMode} 
                  />
                  <DetailRow 
                    label="Bus No." 
                    value={incidentDetail.vehiclenumber} 
                    isDarkMode={isDarkMode} 
                  />
                  <DetailRow 
                    label="Shift" 
                    value={incidentDetail.shift} 
                    isDarkMode={isDarkMode} 
                  />
                  <DetailRow 
                    label="Type" 
                    value={incidentDetail.type} 
                    isDarkMode={isDarkMode} 
                  />
                </div>
              </div>

              {/* Right Column - Photo, Location & Description */}
              <div className="space-y-6">
                {/* Photo & Location Row */}
                <div className="flex items-center gap-4">
                  <Button
                    variant="contained"
                    startIcon={<PhotoCamera />}
                    className={`${
                      isDarkMode ? "bg-blue-600 hover:bg-blue-700" : "bg-blue-500 hover:bg-blue-600"
                    } text-white transition-colors normal-case py-3 text-base font-medium`}
                    onClick={() => setOpenImagePreview(true)}
                  >
                    VIEW CASE PHOTO
                  </Button>
                  {incidentDetail.location && (
                    <div className="flex items-center">
                      <p className={`${isDarkMode ? "text-gray-400" : "text-gray-600"} text-sm`}>
                        {incidentDetail.location}
                      </p>
                      <IconButton
                        onClick={handleMapOpen}
                        size="small"
                        aria-label="View on map"
                        sx={{
                          color: isDarkMode ? "gray" : "gray-700",
                          "&:hover": {
                            backgroundColor: isDarkMode ? "rgba(255, 255, 255, 0.04)" : "rgba(0, 0, 0, 0.04)"
                          }
                        }}
                      >
                        <LocationOn />
                      </IconButton>
                    </div>
                  )}
                </div>
                
                {/* Description Section */}
                {incidentDetail.description && (
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold">Description</h3>
                    <div className={`${
                      isDarkMode ? "bg-[#262626] border border-gray-700" : "bg-white border border-gray-200"
                    } p-4 rounded-lg shadow-sm`}>
                      <p className={`${isDarkMode ? "text-gray-400" : "text-gray-600"} text-sm`}>
                        {incidentDetail.description}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="text-center py-4 text-gray-400">
              No incident details available.
            </div>
          )}
        </DialogContent>

        {/* Dialog Footer */}
        <div className={`flex justify-end px-6 py-4 border-t ${isDarkMode ? "border-gray-700" : "border-gray-200"}`}>
          <Button
            variant="contained"
            onClick={onClose}
            className={`${
              isDarkMode ? "bg-blue-600 hover:bg-blue-700" : "bg-blue-500 hover:bg-blue-600"
            } text-white transition-colors px-8 py-2 text-base font-medium`}
          >
            CLOSE
          </Button>
        </div>
      </Dialog>

      {/* Image Preview Dialog */}
      {openImagePreview && incidentDetail?.imagestr && (
        <Dialog
          open={openImagePreview}
          onClose={() => setOpenImagePreview(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: {
              backgroundColor: isDarkMode ? "#1a1a1a" : "#f8f9fa",
              color: isDarkMode ? "#ffffff" : "#212529",
              borderRadius: "8px",
            },
          }}
        >
          <DialogTitle>Case Photo</DialogTitle>
          <DialogContent>
            <img
              src={incidentDetail.imagestr}
              alt="Case view incident"
              style={{ width: "100%", borderRadius: "8px" }}
            />
          </DialogContent>
          <div className={`flex justify-end px-6 py-4 border-t ${isDarkMode ? "border-gray-700" : "border-gray-200"}`}>
            <Button
              variant="contained"
              onClick={() => setOpenImagePreview(false)}
              className={`${
                isDarkMode ? "bg-blue-600 hover:bg-blue-700" : "bg-blue-500 hover:bg-blue-600"
              } text-white transition-colors px-8 py-2 text-base font-medium`}
            >
              CLOSE
            </Button>
          </div>
        </Dialog>
      )}

      {/* Map Dialog */}
      {openMap && incidentDetail?.location && (
        <MapPicker
          open={openMap}
          onClose={handleMapClose}
          initialPosition={incidentDetail.location.split(",").map(coord => parseFloat(coord.trim()))}
          onSelect={() => {}}
        />
      )}
    </>
  );
}

/**
 * DetailRow component for displaying a label-value pair
 * 
 * @param {string} label - The label text
 * @param {string} value - The value to display
 * @param {boolean} isDarkMode - Whether dark mode is enabled
 */
const DetailRow = ({ label, value, isDarkMode }) => (
  <div className="flex items-center justify-between">
    <span className={`${isDarkMode ? "text-gray-400" : "text-gray-600"} text-sm`}>
      {label}:
    </span>
    <span className={`${isDarkMode ? "text-gray-200" : "text-gray-800"} text-sm font-medium text-right`}>
      {value || "N/A"}
    </span>
  </div>
);