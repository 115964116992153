"use client"

import { useState, useEffect, useContext } from "react"
import { ThemeContext } from "../../../store/ThemeContext"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
} from "@mui/material"
import { Search, NavigateBefore, NavigateNext, Add, ArrowBack as ArrowBackIcon } from "@mui/icons-material"

// Component imports
import UpdateIncident from "./updateincident"
import CreateCaseModal from "./create-case-modal"
import ViewCaseModal from "./view-case-modal"
import TimeOverModal from "./TimeOverModal"

// API and assets
import { getSupervisorIncident } from "../../../API/IncidentAPI"
import timeoverSvg from "../../../assets/timeover.svg"
import BlackTimeOver from "../../../assets/timeoverblack.svg"

/**
 * IncidentReporting component for supervisors to manage incidents
 * Provides interface for viewing, creating and updating incidents
 */
export default function IncidentReporting() {
  const { isDarkMode } = useContext(ThemeContext)
  const navigate = useNavigate()
  
  // Data state
  const [recordType, setRecordType] = useState("pending")
  const [incidents, setIncidents] = useState([])
  const [selectedIncident, setSelectedIncident] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [searchQuery, setSearchQuery] = useState("")
  
  // UI state
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  
  // Modal state
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [viewModalOpen, setViewModalOpen] = useState(false)
  const [updateModalOpen, setUpdateModalOpen] = useState(false)
  const [timeOverModalOpen, setTimeOverModalOpen] = useState(false)

  // Calculate derived values
  const totalPages = Math.ceil(totalCount / pageSize)
  const startIndex = (currentPage - 1) * pageSize

  /**
   * Fetch incidents from API with optional search
   * @param {string} searchTerm - Optional search term
   */
  const fetchIncidents = async (searchTerm = "") => {
    try {
      setLoading(true)
      setError(null)
      const companyId = 1

      // Only pass page and pageSize when not searching
      const response = await getSupervisorIncident(
        companyId,
        searchTerm.trim() === "" ? currentPage : null,
        searchTerm.trim() === "" ? pageSize : null,
        searchTerm,
      )
      
      if (response && response.data.results && Array.isArray(response.data.results)) {
        setIncidents(response.data.results)
        setTotalCount(response.data.total_count || 0)
      } else {
        setIncidents([])
        setTotalCount(0)
        setError("Unexpected data structure received from the server.")
      }
    } catch (error) {
      setIncidents([])
      setTotalCount(0)
      setError("Failed to fetch records. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  /**
   * Calculate time remaining before an incident expires
   * @param {string} endtime - End time in ISO format
   * @returns {string} - Formatted time remaining string
   */
  function getRemainingTime(endtime) {
    const end = new Date(endtime)
    const now = new Date()
    const diff = end - now
    if (diff <= 0) {
      return "0.00 hr"
    }
    const hours = Math.floor(diff / (1000 * 60 * 60))
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    return `${hours}.${minutes.toString().padStart(2, "0")} hr`
  }

  /**
   * Handle clicking on an incident row
   * @param {Object} incident - The incident data
   */
  const handleRowClick = (incident) => {
    const remainingTime = getRemainingTime(incident.endtime)
    if (remainingTime === "0.00 hr") {
      setTimeOverModalOpen(true)
    } else if (incident.submittedbylevel === 0) {
      setSelectedIncident(incident)
      setUpdateModalOpen(true)
    } else {
      setSelectedIncident(incident)
      setViewModalOpen(true)
    }
  }

  /**
   * Handle adding a new incident to the list
   * @param {Object} newIncident - The newly created incident data
   */
  const handleNewIncident = (newIncident) => {
    setIncidents((prev) => [newIncident, ...prev])
    setCurrentPage(1)
    fetchIncidents()
  }

  /**
   * Handle updating an existing incident
   * @param {Object} updatedIncident - The updated incident data
   */
  const handleUpdatedIncident = (updatedIncident) => {
    setIncidents((prev) => 
      prev.map((incident) => (incident.id === updatedIncident.id ? updatedIncident : incident))
    )
    fetchIncidents()
  }

  /**
   * Handle search input key press (Enter key)
   * @param {Object} e - Keyboard event
   */
  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      setCurrentPage(1)
      fetchIncidents(searchQuery)
    }
  }

  /**
   * Handle page change in pagination
   * @param {number} page - The target page number
   */
  const handlePageChange = (page) => {
    if (page < 1) {
      return
    }
    setCurrentPage(page)
    fetchIncidents()
  }

  /**
   * Handle changing the number of rows per page
   * @param {Object} event - Change event
   */
  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setCurrentPage(1)
  }

  /**
   * Fetch incidents when page or size changes
   */
  useEffect(() => {
    if (searchQuery.trim() === "") {
      fetchIncidents()
    }
  }, [currentPage, pageSize])

  /**
   * Navigate to completed records when type changes
   */
  useEffect(() => {
    if (recordType === "completed") {
      navigate("/operations/completedrecords")
    }
  }, [recordType, navigate])

  /**
   * Custom scrollbar styles for better UI experience
   */
  const customScrollbarStyles = {
    "&::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: isDarkMode ? "#2c2c2c" : "#f5f5f5",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: isDarkMode ? "#555" : "#ccc",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: isDarkMode ? "#777" : "#aaa",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: isDarkMode ? "#2c2c2c" : "#f5f5f5",
    },
  }

  /**
   * Render pagination buttons based on current page and total pages
   * @returns {Array} - Array of button components
   */
  const renderPaginationButtons = () => {
    const buttons = []
    const maxButtons = 5

    let startPage = Math.max(1, currentPage - 2)
    const endPage = Math.min(totalPages, startPage + maxButtons - 1)

    if (endPage - startPage < maxButtons - 1) {
      startPage = Math.max(1, endPage - maxButtons + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={currentPage === i ? "contained" : "outlined"}
          sx={{
            minWidth: "32px",
            height: "32px",
            p: 0,
            mx: 0.5,
            bgcolor: currentPage === i ? "#3b536f" : isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
            color: currentPage === i ? "#fff" : isDarkMode ? "#fff" : "#666",
            "&:hover": {
              bgcolor: currentPage === i ? "#3b536f" : isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)",
            },
            fontWeight: 500,
            fontSize: "0.875rem",
            textTransform: "none",
            borderRadius: "4px",
            boxShadow: currentPage === i ? 2 : "none",
          }}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Button>,
      )
    }

    return buttons
  }

  // Show loading indicator while fetching data
  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: isDarkMode ? "rgba(26, 26, 26, 0.8)" : "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "80px" }}>
          <CircularProgress color={isDarkMode ? "primary" : "light"} size={30} />
          <Typography sx={{ color: isDarkMode ? "white" : "black", marginLeft: 2 }}>Loading...</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        bgcolor: isDarkMode ? "#1E1E1E" : "#f5f5f5",
        marginLeft: "60px",
        marginTop: "50px",
      }}
    >
      {/* Header section */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 4, py: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              color: isDarkMode ? "white" : "black",
              bgcolor: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                bgcolor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
              },
              borderRadius: "18px",
              padding: "8px",
              marginRight: "16px",
              width: "60px",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <h1 className={`text-xl font-semibold relative ${isDarkMode ? "text-white" : "text-gray-800"}`}>
            Incident Reporting
          </h1>
        </Box>

        {/* Centered Search Bar */}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
          <TextField
            sx={{
              width: '100%',
              maxWidth: '350px',
              marginRight: '26px',
              '& input:-webkit-autofill': {
                WebkitBoxShadow: '0 0 0 1000px white inset',
                WebkitTextFillColor: isDarkMode ? 'white' : 'black',
                color: isDarkMode ? 'white' : 'black',
              },
            }}
            placeholder="Press Enter to search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyDown={handleSearchKeyPress}
            InputProps={{
              startAdornment: <Search sx={{ mr: 1, color: isDarkMode ? "gray" : "#666" }} />,
              sx: {
                bgcolor: isDarkMode ? "#424242" : "#fff",
                borderRadius: "25px",
                height: "40px",
                '& input': {
                  padding: '0',
                  background: 'none',
                  '&::placeholder': {
                    color: isDarkMode ? 'white' : 'black',
                  },
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "transparent" : "#e0e0e0",
                },
              },
            }}
          />
        </Box>

        {/* Right side buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => setCreateModalOpen(true)}
            sx={{
              backgroundColor: "#4287f5",
              borderRadius: "18px",
              textTransform: "none",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#3278e6" },
              boxShadow: "none",
            }}
          >
            Create
          </Button>
          <FormControl
            sx={{ minWidth: 250, bgcolor: isDarkMode ? "#424242" : "#fff", borderRadius: 3, height: "40px" }}
          >
            <Select
              value={recordType}
              onChange={(e) => setRecordType(e.target.value)}
              sx={{
                color: isDarkMode ? "white" : "black",
                height: "40px",
                "& .MuiSelect-icon": { color: isDarkMode ? "white" : "black" },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "transparent" : "#e0e0e0",
                },
              }}
            >
              <MenuItem value="pending">Pendings</MenuItem>
              <MenuItem value="completed">Completed Records</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      {/* Pending incidents section */}
      {recordType === "pending" ? (
        <>
          {/* Table section */}
          <TableContainer
            sx={{
              flex: 1,   
              overflow: "auto",
              paddingLeft: "52px",
              paddingRight: "39px",
              backgroundColor: isDarkMode ? "#1E1E1E" : "#f5f5f5",
              maxHeight: "calc(100vh - 215px)",
              borderRadius: "10px",
              "& .MuiTable-root": {
                width: "100%",
                border: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
              },
              ...customScrollbarStyles,
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {["Sr No", "Case ID", "Bus Number", "Pilot Name", "Schedule", "Record Date", "Status"].map(
                    (header) => (
                      <TableCell
                        key={header}
                        align="center"
                        sx={{
                          backgroundColor: isDarkMode ? "#37445A" : "#3b536f",
                          color: "#ffffff",
                          border: isDarkMode ? "1px solid #333333" : "1px solid #e0e0e0",
                          fontWeight: 600,
                        }}
                      >
                        {header}
                      </TableCell>
                    ),
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {incidents.length > 0 ? (
                  incidents.map((incident, index) => {
                    const remainingTime = getRemainingTime(incident.endtime)
                    const isOver = remainingTime === "0.00 hr"
                    const serialNumber = startIndex + index + 1

                    return (
                      <TableRow
                        key={incident.id || index}
                        onClick={() => handleRowClick(incident)}
                        sx={{
                          backgroundColor: isDarkMode ? "#4A4A4A" : "white",
                          "&:hover": {
                            backgroundColor: isDarkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.02)",
                            cursor: "pointer",
                          },
                          "& .MuiTableCell-root": {
                            borderBottom: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                            borderRight: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                            color: isDarkMode ? "#ffffff" : "#333333",
                            fontSize: "0.875rem",
                            padding: "12px 16px",
                            textAlign: "center",
                          },
                        }}
                      >
                        <TableCell>{serialNumber}</TableCell>
                        <TableCell>{incident.caseid}</TableCell>
                        <TableCell>{incident.vehiclenumber}</TableCell>
                        <TableCell>{incident.drivername}</TableCell>
                        <TableCell>{incident.schedulecode}</TableCell>
                        <TableCell>{incident.incidentdate}</TableCell>
                        <TableCell
                          sx={{
                            color: isOver ? "#f56565 !important" : isDarkMode ? "white" : "black",
                            textAlign: "center",
                            borderRight: "1px solid #e0e0e0",
                          }}
                        >
                          {remainingTime === "0.00 hr"
                            ? `Time Over | Level-${incident.submittedbylevel + 1} Pending`
                            : `Time Left ${remainingTime} | Level-${incident.submittedbylevel + 1} Pending`}
                        </TableCell>
                      </TableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <TableCell 
                      colSpan={7} 
                      align="center"
                      sx={{
                        backgroundColor: isDarkMode ? "#4A4A4A" : "white",
                        color: isDarkMode ? "#ffffff" : "#333333",
                        fontSize: "1rem",
                        padding: "20px",
                        border: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                      }}
                    >
                      <Box 
                        sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          alignItems: 'center',
                          gap: 2,
                          py: 4
                        }}
                      >
                        {/* You can add an icon here if you want */}
                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                          No pending records found
                        </Typography>
                        <Typography variant="body2" sx={{ color: isDarkMode ? '#999' : '#666' }}>
                          Create a new incident
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination section */}
          <Box
            sx={{
              position: "fixed",
              bottom: 10,
              left: "112px",
              right: "45px",
              p: 1,
              py: 1.3,
              display: "flex",
              borderBottomRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              justifyContent: "center",
              alignItems: "center",
              borderTop: isDarkMode ? "1px solid rgba(255,255,255,0.1)" : "1px solid rgba(0,0,0,0.1)",
              backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
              zIndex: 1000,
            }}
          >
            <Grid container alignItems="center" sx={{ maxWidth: 815, mx: "auto" }}>
              {/* Previous/Next Buttons */}
              <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                <IconButton
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                  sx={{
                    color: isDarkMode ? "#fff" : "#666",
                    bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                    "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                    "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" },
                  }}
                >
                  <NavigateBefore />
                </IconButton>

                <Box sx={{ display: "flex", gap: 1 }}>{renderPaginationButtons()}</Box>

                <IconButton
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                  sx={{
                    color: isDarkMode ? "#fff" : "#666",
                    bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                    "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                    "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" },
                  }}
                >
                  <NavigateNext />
                </IconButton>
              </Grid>

              {/* Page Number Input */}
              <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
                <Typography
                  sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: "nowrap" }}
                >
                  Page No
                </Typography>
                <TextField
                  type="number"
                  value={currentPage}
                  onChange={(e) => {
                    const newPage = Math.max(1, Math.min(totalPages, Number.parseInt(e.target.value, 10) || 1))
                    setCurrentPage(newPage)
                    handlePageChange(newPage)
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      const newPage = Math.max(1, Math.min(totalPages, Number.parseInt(e.target.value, 10) || 1))
                      handlePageChange(newPage)
                    }
                  }}
                  sx={{
                    width: "60px",
                    bgcolor: isDarkMode ? "#424242" : "#fff",
                    "& input": {
                      color: isDarkMode ? "white" : "black",
                      padding: "6.8px",
                      textAlign: "center",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: isDarkMode ? "transparent" : "rgba(0, 0, 0, 0.23)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.23)",
                    },
                  }}
                  inputProps={{
                    min: 1,
                    max: totalPages,
                    style: { textAlign: "center" },
                  }}
                />
              </Grid>

              {/* Page Size Selection */}
              <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
                <Typography
                  sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: "nowrap" }}
                >
                  Page Size
                </Typography>
                <Select
                  value={pageSize}
                  onChange={handleChangeRowsPerPage}
                  size="small"
                  sx={{
                    width: "80px",
                    bgcolor: isDarkMode ? "#424242" : "transparent",
                    color: isDarkMode ? "white" : "#666",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: isDarkMode ? "transparent" : "rgba(0, 0, 0, 0.23)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.23)",
                    },
                    "& .MuiSelect-icon": {
                      color: isDarkMode ? "white" : "#666",
                    },
                  }}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : null}

      {/* Modals */}
      <CreateCaseModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onIncidentCreated={handleNewIncident}
      />
      <ViewCaseModal
        open={viewModalOpen}
        onClose={() => {
          setViewModalOpen(false)
          setSelectedIncident(null)
        }}
        incident={selectedIncident}
      />
      <UpdateIncident
        open={updateModalOpen}
        onClose={() => {
          setUpdateModalOpen(false)
          setSelectedIncident(null)
        }}
        incident={selectedIncident}
        onIncidentUpdated={handleUpdatedIncident}
      />
      <TimeOverModal
        open={timeOverModalOpen}
        onClose={() => setTimeOverModalOpen(false)}
        timeOverImage={timeoverSvg}
        blackTimeOverImage={BlackTimeOver}
      />
    </Box>
  )
}