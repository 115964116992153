"use client"

import { useEffect, useState, useContext } from "react"
import UpdateUser from "./UpdateUser"
import { getUserProfiles, getUserDetails } from "../../API/UserManagementAPI"
import { ThemeContext } from "../../store/ThemeContext"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
  TextField,
  IconButton,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { Search, Add, NavigateBefore, NavigateNext } from "@mui/icons-material"
import CreateUser from "./createUser"
import { Grid } from "@mui/material"
import { useNavigate } from "react-router-dom"
import EditIcon from "@mui/icons-material/Edit" // Import the Edit icon

const UserManagement = () => {
  const { isDarkMode } = useContext(ThemeContext)
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [searchTerm, setSearchTerm] = useState("")
  const [isCreateUserOpen, setIsCreateUserOpen] = useState(false) // State to manage dialog visibility
  const navigate = useNavigate()
  const [isUpdateUserOpen, setIsUpdateUserOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    fetchData()
  }, [])

  // Update the fetchData function
  const fetchData = async (searchValue = "") => {
    setLoading(true)
    try {
      const data = await getUserProfiles(searchValue)
      setUsers(data.data || [])
    } catch (error) {
      console.error("Error fetching user profiles:", error)
      setUsers([])
    } finally {
      setLoading(false)
    }
  }

  const handleSearchKeyPress = (e) => {
    if (e.key === "Enter") {
      fetchData(searchTerm)
    }
  }

  const handleEditClick = async (userId) => {
    try {
      const data = await getUserDetails(userId)
      setSelectedUser(data.data[0])
      setIsUpdateUserOpen(true)
    } catch (error) {
      console.error("Error fetching user details:", error)
    }
  }
  const handlePageChange = (event, newPage) => {
    setPage(newPage)
  }

  const handlePageSizeChange = (event) => {
    setPageSize(Number.parseInt(event.target.value, 10))
    setPage(0)
  }

  const renderPaginationButtons = () => {
    const buttons = []
    const maxButtons = 5
    const totalPages = Math.ceil(users.length / pageSize)

    let startPage = Math.max(1, page - 2)
    const endPage = Math.min(totalPages, startPage + maxButtons - 1)

    if (endPage - startPage < maxButtons - 1) {
      startPage = Math.max(1, endPage - maxButtons + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <Button
          key={i}
          variant={page === i - 1 ? "contained" : "outlined"}
          sx={{
            minWidth: "32px",
            height: "32px",
            p: 0,
            mx: 0.5,
            bgcolor: page === i - 1 ? "#3b536f" : isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
            color: page === i - 1 ? "#fff" : isDarkMode ? "#fff" : "#666",
            "&:hover": {
              bgcolor: page === i - 1 ? "#3b536f" : isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)",
            },
            fontWeight: 500,
            fontSize: "0.875rem",
            textTransform: "none",
            borderRadius: "4px",
            boxShadow: page === i - 1 ? 2 : "none",
          }}
          onClick={() => handlePageChange(null, i - 1)}
        >
          {i}
        </Button>,
      )
    }

    return buttons
  }

  const handleCreateUserOpen = () => {
    setIsCreateUserOpen(true)
  }

  const handleCreateUserClose = () => {
    setIsCreateUserOpen(false)
  }

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value)
  }

  const handleUserCreated = (newUser) => {
    setUsers((prevUsers) => [...prevUsers, newUser])
  }

  const handleUserClick = async (userId) => {
    try {
      const data = await getUserDetails(userId)
      setSelectedUser(data.data[0])
      setIsUpdateUserOpen(true)
    } catch (error) {
      console.error("Error fetching user details:", error)
    }
  }

  const handleUpdateUserClose = () => {
    setIsUpdateUserOpen(false)
    setSelectedUser(null)
  }

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: isDarkMode ? "rgba(26, 26, 26, 0.8)" : "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(5px)",
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginLeft: "80px" }}>
          <CircularProgress color={isDarkMode ? "primary" : "light"} size={30} />
          <Typography sx={{ color: isDarkMode ? "white" : "black", marginLeft: 2 }}>Loading...</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        bgcolor: isDarkMode ? "#1E1E1E" : "#f5f5f5",
        marginLeft: "60px",
        marginTop: "50px",
      }}
    >
      {/* Header section */}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", px: 4, py: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              color: isDarkMode ? "white" : "black",
              bgcolor: isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
              "&:hover": {
                bgcolor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)",
              },
              borderRadius: "18px",
              padding: "8px",
              marginRight: "16px",
              width: "60px",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          <h1 className={`text-xl font-semibold relative ${isDarkMode ? "text-white" : "text-gray-800"}`}>
            User Management
          </h1>
        </Box>

        {/* Centered Search Bar */}
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", flexGrow: 1 }}>
          <TextField
            sx={{
              width: "100%",
              maxWidth: "350px",
              marginRight: "8px",
            }}
            placeholder="Enter search term"
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyPress={handleSearchKeyPress}
            InputProps={{
              startAdornment: <Search sx={{ mr: 1, color: isDarkMode ? "gray" : "#666" }} />,
              sx: {
                bgcolor: isDarkMode ? "#424242" : "#fff",
                borderRadius: "25px",
                height: "40px",
                "& input": {
                  color: isDarkMode ? "white" : "black",
                  borderRadius: "25px",
                  height: "40px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "transparent" : "#e0e0e0",
                },
              },
            }}
          />
          <Button
            variant="contained"
            onClick={() => fetchData(searchTerm)}
            sx={{
              backgroundColor: "#3b536f",
              borderRadius: "18px",
              textTransform: "none",
              fontWeight: 50,
              padding: "8px 16px",
              height: "40px",
              "&:hover": { backgroundColor: "#3b535f" },
              boxShadow: "none",
            }}
          >
            Search
          </Button>
        </Box>

        {/* Right side buttons */}
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={handleCreateUserOpen} // Open the CreateUser dialog
            sx={{
              backgroundColor: "#3b536f",
              borderRadius: "18px",
              textTransform: "none",
              fontWeight: 500,
              padding: "8px 16px",
              "&:hover": { backgroundColor: "#3b535f" },
              boxShadow: "none",
            }}
          >
            Create User
          </Button>
        </Box>
      </Box>

      {/* Table section */}
      <TableContainer
        sx={{
          flex: 1,
          overflow: "auto",
          paddingLeft: "52px",
          paddingRight: "39px",
          backgroundColor: isDarkMode ? "#1E1E1E" : "#f5f5f5",
          maxHeight: "calc(100vh - 215px)",
          borderRadius: "10px",
          "& .MuiTable-root": {
            width: "100%",
            border: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
          },
          "&::-webkit-scrollbar": {
            width: "8px",
            backgroundColor: isDarkMode ? "#2c2c2c" : "#f5f5f5",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: isDarkMode ? "#555" : "#ccc",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: isDarkMode ? "#777" : "#aaa",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: isDarkMode ? "#2c2c2c" : "#f5f5f5",
          },
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {["Sr.No", "LoginName", "Designation", "Company", "Department", "UserRole"].map((header) => (
                <TableCell
                  key={header}
                  align="center"
                  sx={{
                    backgroundColor: isDarkMode ? "#37445A" : "#3b536f",
                    color: "#ffffff",
                    border: isDarkMode ? "1px solid #333333" : "1px solid #e0e0e0",
                    fontWeight: 600,
                  }}
                >
                  {header}
                </TableCell>
              ))}
              <TableCell
                align="center"
                sx={{
                  backgroundColor: isDarkMode ? "#37445A" : "#3b536f",
                  color: "#ffffff",
                  border: isDarkMode ? "1px solid #333333" : "1px solid #e0e0e0",
                  fontWeight: 600,
                }}
              >
                Edit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.slice(page * pageSize, page * pageSize + pageSize).map((user, index) => (
              <TableRow
                key={user.employeeid}
                sx={{
                  backgroundColor: isDarkMode ? "#4A4A4A" : "white",
                  "&:hover": {
                    backgroundColor: isDarkMode ? "rgba(255,255,255,0.05)" : "rgba(0,0,0,0.02)",
                    cursor: "pointer",
                  },
                  "& .MuiTableCell-root": {
                    borderBottom: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                    borderRight: isDarkMode ? "1px solid #333" : "1px solid #e0e0e0",
                    color: isDarkMode ? "#ffffff" : "#333333",
                    fontSize: "0.875rem",
                    padding: "12px 16px",
                    textAlign: "center",
                  },
                }}
              >
                <TableCell>{page * pageSize + index + 1}</TableCell>
                <TableCell>{user.LoginName}</TableCell>
                <TableCell>{user.DesignationName}</TableCell>
                <TableCell>{user.CompanyName}</TableCell>
                <TableCell>{user.DepartmentName}</TableCell>
                <TableCell>{user.Name}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(user.UserMemberShipId)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Render the CreateUser component */}

      <CreateUser open={isCreateUserOpen} onClose={handleCreateUserClose} onUserCreated={handleUserCreated} />

      {selectedUser && <UpdateUser open={isUpdateUserOpen} onClose={handleUpdateUserClose} user={selectedUser} />}

      {/* Pagination section */}
      <Box
        sx={{
          position: "fixed",
          bottom: 10,
          left: "112px",
          right: "45px",
          p: 1,
          py: 1.3,
          display: "flex",
          borderBottomRightRadius: "10px",
          borderBottomLeftRadius: "10px",
          justifyContent: "center",
          alignItems: "center",
          borderTop: isDarkMode ? "1px solid rgba(255,255,255,0.1)" : "1px solid rgba(0,0,0,0.1)",
          backgroundColor: isDarkMode ? "#1a1a1a" : "#ffffff",
          zIndex: 1000,
        }}
      >
        <Grid container alignItems="center" sx={{ maxWidth: 815, mx: "auto" }}>
          <Grid item xs={4} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <IconButton
              disabled={page === 0}
              onClick={() => handlePageChange(null, page - 1)}
              sx={{
                color: isDarkMode ? "#fff" : "#666",
                bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" },
              }}
            >
              <NavigateBefore />
            </IconButton>

            <Box sx={{ display: "flex", gap: 1 }}>{renderPaginationButtons()}</Box>

            <IconButton
              disabled={page === Math.ceil(users.length / pageSize) - 1}
              onClick={() => handlePageChange(null, page + 1)}
              sx={{
                color: isDarkMode ? "#fff" : "#666",
                bgcolor: isDarkMode ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.05)",
                "&:hover": { bgcolor: isDarkMode ? "rgba(255,255,255,0.2)" : "rgba(0,0,0,0.1)" },
                "&.Mui-disabled": { color: isDarkMode ? "rgba(255,255,255,0.3)" : "rgba(0,0,0,0.3)" },
              }}
            >
              <NavigateNext />
            </IconButton>
          </Grid>

          {/* Page Number Input */}
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 1 }}>
            <Typography sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: "nowrap" }}>
              Page No
            </Typography>
            <TextField
              type="number"
              value={page + 1}
              onChange={(e) => {
                const newPage =
                  Math.max(1, Math.min(Math.ceil(users.length / pageSize), Number.parseInt(e.target.value, 10) || 1)) -
                  1
                setPage(newPage)
                handlePageChange(null, newPage)
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const newPage =
                    Math.max(
                      1,
                      Math.min(Math.ceil(users.length / pageSize), Number.parseInt(e.target.value, 10) || 1),
                    ) - 1
                  handlePageChange(null, newPage)
                }
              }}
              sx={{
                width: "60px",
                bgcolor: isDarkMode ? "#424242" : "#fff",
                "& input": {
                  color: isDarkMode ? "white" : "black",
                  padding: "6.8px",
                  textAlign: "center",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "transparent" : "rgba(0, 0, 0, 0.23)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.23)",
                },
              }}
              inputProps={{
                min: 1,
                max: Math.ceil(users.length / pageSize),
                style: { textAlign: "center" },
              }}
            />
          </Grid>

          {/* Page Size Selection */}
          <Grid item xs={1} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: isDarkMode ? "rgba(255,255,255,0.7)" : "rgba(0,0,0,0.7)", whiteSpace: "nowrap" }}>
              Page Size
            </Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              size="small"
              sx={{
                width: "80px",
                bgcolor: isDarkMode ? "#424242" : "transparent",
                color: isDarkMode ? "white" : "#666",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "transparent" : "rgba(0, 0, 0, 0.23)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: isDarkMode ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.23)",
                },
                "& .MuiSelect-icon": {
                  color: isDarkMode ? "white" : "#666",
                },
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default UserManagement

