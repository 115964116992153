export const lightTheme = {
  // Background colors
  primary: '#ffffff',
  secondary: '#f8f9fa',
  tertiary: '#e9ecef',
  
  // Text colors
  textPrimary: '#212529',
  textSecondary: '#495057',
  textTertiary: '#6c757d',
  
  // Border colors
  border: '#dee2e6',
  borderLight: '#e9ecef',
  
  // Component specific
  sidebar: {
    background: '#f8f9fa',
    hoverBg: '#e9ecef',
    activeBg: '#dee2e6',
    text: '#212529',
    activeText: '#0d6efd'
  },
  
  header: {
    background: '#ffffff',
    text: '#212529'
  },
  
  card: {
    background: '#ffffff',
    text: '#000000',
    border: 'rgba(0, 0, 0, 0.1)',
  },
  
  table: {
    headerBg: '#f8f9fa',
    rowBg: '#ffffff',
    alternateBg: '#f8f9fa',
    border: '#dee2e6'
  }
};

export const darkTheme = {
  // Background colors
  primary: '#212529',
  secondary: '#343a40',
  tertiary: '#495057',
  
  // Text colors
  textPrimary: '#f8f9fa',
  textSecondary: '#e9ecef',
  textTertiary: '#dee2e6',
  
  // Border colors
  border: '#495057',
  borderLight: '#343a40',
  
  // Component specific
  sidebar: {
    background: '#343a40',
    hoverBg: '#495057',
    activeBg: '#6c757d',
    text: '#f8f9fa',
    activeText: '#90caf9'
  },
  
  header: {
    background: '#212529',
    text: '#f8f9fa'
  },
  
  card: {
    background: '#262626',
    text: '#ffffff',
    border: 'rgba(255, 255, 255, 0.1)',
  },
  
  table: {
    headerBg: '#343a40',
    rowBg: '#212529',
    alternateBg: '#2b3035',
    border: '#495057'
  }
}; 