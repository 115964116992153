import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/system";
import logoDark from "../../assets/Transvolt Logo - Black.svg";
import logoLight from "../../assets/Transvolt Logo - White.svg";
import mbmtlogo from "../../assets/MBMT - Logo - PNG - Small 1.png";
import { Link } from "react-router-dom";
import profile from "../../assets/profile.svg";
import { Box, IconButton, Menu, MenuItem, Button, Typography } from "@mui/material";
import { LoginContext } from "../../store/LoginContext";
import { ThemeContext } from "../../store/ThemeContext";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { lightTheme, darkTheme } from '../../styles/theme';

const StyledAppBar = styled(AppBar)(({ theme, isDarkMode }) => ({
  backgroundColor: isDarkMode ? '#1a1a1a' : lightTheme.header.background,
  color: isDarkMode ? darkTheme.header.text : lightTheme.header.text,
  transition: 'all 0.3s ease',
  height: "55px",
  width: "100%",
  padding: "0",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 1100,
  boxShadow: isDarkMode 
    ? '0 2px 8px rgba(0, 0, 0, 0.4)'
    : '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const Logo = styled('img')({
  height: '35px',
  width: '40%',
});

const StyledMenu = styled(Menu)(({ theme, isDarkMode }) => ({
  '& .MuiPaper-root': {
    backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
    color: isDarkMode ? '#ffffff' : '#000000',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginTop: '8px',
    minWidth: '200px',
  },
  '& .MuiMenuItem-root': {
    fontSize: '14px',
    padding: '10px 24px',
    '&:hover': {
      backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

const Header = ({ username }) => {
  const { logoutHandle } = useContext(LoginContext);
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const [helpAnchorEl, setHelpAnchorEl] = useState(null);
  const [legalAnchorEl, setLegalAnchorEl] = useState(null);

  const handleHelpClick = (event) => {
    setHelpAnchorEl(event.currentTarget);
  };

  const handleHelpClose = () => {
    setHelpAnchorEl(null);
  };

  const handleLegalClick = (event) => {
    setLegalAnchorEl(event.currentTarget);
  };

  const handleLegalClose = () => {
    setLegalAnchorEl(null);
  };

  return (
    <StyledAppBar position="fixed" isDarkMode={isDarkMode}>
      {/* Logo Section */}
      <Box alignSelf={"center"} marginLeft={2}>
        <Link to="/dashboard">
          <Logo 
            src={isDarkMode ? logoLight : logoDark} 
            alt="Transvolt Logo"
          />
        </Link>
      </Box>

      {/* Center Section */}
      <Box
        alignSelf={"center"}
        fontSize={"15px"}
        fontFamily={`"Roboto", "Helvetica", "Arial", sans-serif`}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      />

      {/* Right Section */}
      <Box
        display={"flex"}
        alignItems={"center"}
        gap={1}
        paddingRight={2}
      >
        {/* Help Menu */}
        {/* <Button
          onClick={handleHelpClick}
          sx={{
            color: isDarkMode ? '#ffffff' : '#000000',
            textTransform: 'none',
            fontSize: '14px',
            minWidth: 'auto',
            padding: '6px 8px',
            '&:hover': {
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
            },
          }}
          startIcon={<HelpOutlineIcon />}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Help
        </Button>
        <StyledMenu
          anchorEl={helpAnchorEl}
          open={Boolean(helpAnchorEl)}
          onClose={handleHelpClose}
          isDarkMode={isDarkMode}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography variant="subtitle2" sx={{ px: 3, py: 1, color: isDarkMode ? '#ffffff80' : '#00000080' }}>
            HELP
          </Typography>
          <MenuItem onClick={handleHelpClose}>Support</MenuItem>
        </StyledMenu> */}

        {/* Legal Menu
        <Button
          onClick={handleLegalClick}
          sx={{
            color: isDarkMode ? '#ffffff' : '#000000',
            textTransform: 'none',
            fontSize: '14px',
            minWidth: 'auto',
            padding: '6px 8px',
            '&:hover': {
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
            },
          }}
          endIcon={<KeyboardArrowDownIcon />}
        >
          Legal
        </Button>
        <StyledMenu
          anchorEl={legalAnchorEl}
          open={Boolean(legalAnchorEl)}
          onClose={handleLegalClose}
          isDarkMode={isDarkMode}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Typography variant="subtitle2" sx={{ px: 3, py: 1, color: isDarkMode ? '#ffffff80' : '#00000080' }}>
            LEGAL
          </Typography>
          <MenuItem onClick={handleLegalClose}>Terms of service</MenuItem>
          <MenuItem onClick={handleLegalClose}>Privacy policy</MenuItem>
          <MenuItem onClick={handleLegalClose}>User notice</MenuItem>
        </StyledMenu> */}

        {/* Theme Toggle */}
        <IconButton
          onClick={toggleTheme}
          sx={{
            color: isDarkMode ? '#ffffff' : '#000000',
            '&:hover': {
              backgroundColor: isDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)',
            },
          }}
        >
          {isDarkMode ? <LightModeIcon /> : <DarkModeIcon />}
        </IconButton>
      </Box>
    </StyledAppBar>
  );
};

export default Header;
