import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'; // Import js-cookie
import { toast } from "react-toastify";
export const LoginContext = createContext();

const LoginContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const authToken = sessionStorage.getItem("AuthToken");
    if (authToken) {
      setIsLoggedIn(true);
    }
  }, []);  // Empty dependency array to run once on mount

  const loginHandle = (data, authToken, userGroup) => {
    if (data === "success") {
      sessionStorage.setItem("AuthToken", JSON.stringify({ token: authToken, group: userGroup })); // Store the token and group on successful login
      setIsLoggedIn(true);
    }
  };
  const logoutHandle = () => {
    sessionStorage.removeItem("AuthToken"); // Clear the token on logout
    localStorage.removeItem('authToken'); // Remove user credentials from localStorage
    Cookies.remove('authToken'); // Remove the cookie on logout
    sessionStorage.removeItem("UserGroup"); // Clear the token on logout


    // const cookieValue = Cookies.get('cookiesAccepted');
    // // console.log("Auth Token before removal:", cookieValue);
    // if (cookieValue) {
      
   
    // } // Remove the cookie on logout
    toast.success("You have successfully logged out!");
    
    setIsLoggedIn(false);
    navigate("/dashboard"); // Ensure navigation to login page
  };

  return (
    <LoginContext.Provider value={{ isLoggedIn, loginHandle, logoutHandle }}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContextProvider;


