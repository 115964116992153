"use client"

import { Dialog, DialogContent, IconButton, Box, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useContext } from 'react'
import { ThemeContext } from '../../../store/ThemeContext'


export default function TimeOverModal({ open, onClose, timeOverImage, blackTimeOverImage }) {
  const { isDarkMode } = useContext(ThemeContext)

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        style: {
          backgroundColor: isDarkMode ? '#1a1a1a' : '#ffffff',
          borderRadius: '8px',
        },
      }}
    >
      <DialogContent sx={{
        textAlign: 'center',
        py: 6,
        px: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <IconButton
          onClick={onClose}
          sx={{ 
            position: "absolute", 
            top: 8, 
            right: 8, 
            color: isDarkMode ? "#fff" : "#000",
            '&:hover': {
              backgroundColor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'
            }
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          mb: 3
        }}>
          <img
            src={isDarkMode ? blackTimeOverImage : timeOverImage}
            alt="Time Over"
            style={{
              width: '350px',
              height: 'auto',
            }}
          />
        </Box>
        <Typography
          sx={{
            color: isDarkMode ? '#fff' : '#000',
            fontSize: '28px',
            fontWeight: '500',
            mb: 2
          }}
        >
          Time is Over
        </Typography>
        <Typography
          sx={{
            color: isDarkMode ? '#fff' : '#000',
            fontSize: '18px',
            opacity: 0.8,
            mb: 3
          }}
        >
          Oops!.. this case is no longer available to update!
        </Typography>
        <Typography
          sx={{
            color: isDarkMode ? '#fff' : '#000',
            fontSize: '16px',
            opacity: 0.7
          }}
        >
          Kindly contact your Respective Manager to extend time for 24 hr.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}  