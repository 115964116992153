import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Grid, Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close'; // Import the Close icon
import { updateUserDetails, fetchEmployeeTypes, fetchDepartments, fetchDesignations, fetchUserRole } from '../../API/UserManagementAPI';
import {fetchCompany} from '../../API/IncidentAPI'
import { toast } from 'react-toastify';
import { ThemeContext } from '../../store/ThemeContext';

const UpdateUser = ({ open, onClose, user }) => {
    const { isDarkMode } = useContext(ThemeContext);
    const [formData, setFormData] = useState(user);
    const [employeeTypes, setEmployeeTypes] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [designations, setDesignations] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        const fetchDropdownItems = async () => {
            try {
                const [types, depts, desigs, roles, comps] = await Promise.all([
                    fetchEmployeeTypes(),
                    fetchDepartments(),
                    fetchDesignations(),
                    fetchUserRole(),
                    fetchCompany()
                ]);
                setEmployeeTypes(types);
                setDepartments(depts);
                setDesignations(desigs);
                setUserRoles(roles);
                setCompanies(comps);
            } catch (error) {
                console.error('Error fetching dropdown items:', error);
            }
        };

        fetchDropdownItems();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const updateData = {
                employeeid: formData.EmployeeId,
                usermembershipid: formData.UserMemberShipId,
                usertype: formData.UserType,
                userroleid: formData.UserRoleId,
                employeecode: formData.EmployeeCode,
                title: formData.Title,
                fname: formData.FName,
                mname: formData.MName,
                lname: formData.LName,
                departmentid: formData.DepartmentId,
                designationid: formData.DesignationId,
                employeetypeid: formData.EmployeeTypeId,
                dateofbirth: formData.DateOfBirth,
                gender: formData.Gender,
                dateofjoining: formData.DateOfJoining,
                status: formData.Status,
                description: formData.Description,
                probationperiod: formData.ProbationPeriod,
                loginname: formData.LoginName,
            };
            console.log('Payload:', updateData); // Log the payload
            await updateUserDetails(updateData);
            toast.success('User details updated successfully!');
            onClose();
        } catch (error) {
            toast.error('Failed to update user details!');
            console.error('Error updating user details:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle style={{ background: isDarkMode ? '#636363' : '#fff', color: isDarkMode ? '#fff' : '#000', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Update User
            <IconButton onClick={onClose} style={{ color: isDarkMode ? '#fff' : '#000' }}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
            <DialogContent style={{ background: isDarkMode ? "linear-gradient(to bottom, #636363, #454545)" : '#fff', color: isDarkMode ? '#fff' : '#000' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Title"
                                name="Title"
                                value={formData.Title}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="First Name"
                                name="FName"
                                value={formData.FName}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Middle Name"
                                name="MName"
                                value={formData.MName}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Last Name"
                                name="LName"
                                value={formData.LName}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Gender"
                                name="Gender"
                                value={formData.Gender}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="DateOfBirth"
                                name="DateOfBirth"
                                value={formData.DateOfBirth}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="DateOfJoining"
                                name="DateOfJoining"
                                value={formData.DateOfJoining}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Status"
                                name="Status"
                                value={formData.Status}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Employee Type"
                                name="EmployeeTypeId"
                                value={formData.EmployeeTypeId}
                                onChange={handleChange}
                                select
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                                InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            >
                                {employeeTypes.map((type) => (
                                    <MenuItem key={type.employeetypeid} value={type.employeetypeid}>
                                        {type.employeetypes}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Department"
                                name="DepartmentId"
                                value={formData.DepartmentId}
                                onChange={handleChange}
                                select
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                                InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            >
                                {departments.map((dept) => (
                                    <MenuItem key={dept.departmentid} value={dept.departmentid}>
                                        {dept.departmentname}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Designation"
                                name="DesignationId"
                                value={formData.DesignationId}
                                onChange={handleChange}
                                select
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                                InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            >
                                {designations.map((desig) => (
                                    <MenuItem key={desig.designationid} value={desig.designationid}>
                                        {desig.designationname}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="User Role"
                                name="UserRoleId"
                                value={formData.UserRoleId}
                                onChange={handleChange}
                                select
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                                InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            >
                                {userRoles.map((role) => (
                                    <MenuItem key={role.userroleid} value={role.userroleid}>
                                        {role.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Company"
                                name="CompanyId"
                                value={formData.CompanyId}
                                onChange={handleChange}
                                select
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                                InputProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            >
                                {companies.map((comp) => (
                                    <MenuItem key={comp.companyid} value={comp.companyid}>
                                        {comp.companyname}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Probation Period"
                                name="ProbationPeriod"
                                value={formData.ProbationPeriod}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Login Name"
                                name="LoginName"
                                value={formData.LoginName}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                margin="normal"
                                label="Employee Code"
                                name="EmployeeCode"
                                value={formData.EmployeeCode}
                                onChange={handleChange}
                                style={{ color: isDarkMode ? '#fff' : '#000', backgroundColor: isDarkMode ? "#00000033" : "#fff" }}
                                InputLabelProps={{ style: { color: isDarkMode ? '#fff' : '#000' } }}
                            />
                        </Grid>
                    </Grid>
                </form>
                <DialogActions style={{ background: isDarkMode ? "#454545" : '#fff', color: isDarkMode ? '#fff' : '#000' }}>
                    <Button
                        onClick={onClose}
                        sx={{
                            backgroundColor: isDarkMode ? '#b0b0b0' : '#d3d3d3',
                            color: '#000',
                            '&:hover': {
                                backgroundColor: isDarkMode ? '#a0a0a0' : '#c0c0c0',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        onClick={handleSubmit}
                        sx={{
                            backgroundColor: '#4caf50',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: '#45a049',
                            },
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
};

export default UpdateUser;