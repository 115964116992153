import { baseURL } from "./BaseURL";
import axios from "axios"; // Import axios for making HTTP requests

// Define config outside functions so it can be used throughout the file
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const API = axios.create({ baseURL: baseURL }); // Use imported baseURL instead of undefined API_URL

// Function to check if a user exists in the database
export const forgotpassword = async (email) => {
    try {
      const response = await API.post(
        "/vehical/send-reset-password-email/",
        {
          email,
        },
        config
      );
      return response.data.exists; // Return whether the user exists
    } catch (error) {
      throw error.response || new Error("Network error"); // Throw an error if the request fails
    }
  };
  
  
  // export const resetPassword = async (token, password, password2) => {
  //   try {
  //     const response = await API.post(
  //       "/vehical/reset-password/",
  //       {
  //         token,
  //         password,
  //         password2,
  //       },
  //       config
  //     );
  //     return response.data; // Return the response data
  //   } catch (error) {
  //     throw error.response || new Error("Network error"); // Throw an error if the request fails
  //   }
  // };
  
  
  // export const sendResetPasswordEmail = async (email) => {
  //   try {
  //     const response = await API.post('/vehical/send-reset-password-email/', { email });
  //     return response.data;
  //   } catch (error) {
  //     throw error;
  //   }
  // };
  
  
  
  
  export const loginUser = async (username, password) => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const response = await API.post("/auth/login/", { username, password }, config);
     // console.log("Full Login Response:", response.data); // Log full response
  
      if (!response.data.data.token) {
        throw new Error("Token not found in response.");
      }
  
      // Verify employee_id exists in response
      if (!response.data.data.employee_id) {
        console.warn("Employee ID not found in login response:", response.data);
      }
  
      return response.data;
    } catch (error) {
      console.error("Login failed:", error.message);
      throw error.response?.data || new Error("Network error");
    }
  };
  